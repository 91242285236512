// File: src/components/games/QuizPage.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Grid,
  Chip,
  Tooltip,
  TextField,
} from '@mui/material';

import Header from '../Header';
import Footer from '../Footer';
import MobileMenu from '../MobileMenu';
import FloatingMenu from '../FloatingMenu';
import apiClient from '../../api/apiClient';

// Usa lo stesso import di decodifica token che hai in HomePage
import { jwtDecode } from 'jwt-decode';

/* -------------------------------------------------------------------------- */
/*                            FUNZIONE SHUFFLE                                */
/* -------------------------------------------------------------------------- */
const shuffleArray = (array) => {
  return array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
};

/* -------------------------------------------------------------------------- */
/*                         COMPONENTE QUESTION CARD                           */
/* -------------------------------------------------------------------------- */
const QuestionCard = ({
  questionData,
  userAnswer,              // { optionId } per multiple_choice o { text } per open_ended
  onSelectOption,          // funzione per selezionare l'opzione (multiple_choice)
  onChangeText,            // funzione per aggiornare il testo (open_ended)
  showCorrectness = false, // se vogliamo mostrare le risposte corrette
}) => {
  return (
    <Card
      variant="outlined"
      sx={{
        marginBottom: '1.5rem',
        borderRadius: '1rem',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
        backgroundColor: '#ffffff',
        padding: '1rem',
      }}
    >
      <CardContent>
        <Typography variant="h6" sx={{ marginBottom: '1rem', fontWeight: 600 }}>
          {questionData.question}
        </Typography>

        {questionData.type === 'multiple_choice' ? (
          /* Domanda a Risposta Multipla */
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '0.75rem',
              justifyContent: 'center',
              marginBottom: '1rem',
            }}
          >
            {questionData.options.map((option) => {
              let chipColor = 'default';
              let variant = 'outlined';

              // L'opzione selezionata dall'utente (se esiste)
              const selectedOption = userAnswer?.optionId || null;

              // Se sto mostrando la correzione (dopo "Verifica Risposte")
              if (showCorrectness) {
                if (option.is_correct) {
                  chipColor = 'success';
                  variant = 'filled';
                } else if (selectedOption === option.id) {
                  chipColor = 'error';
                  variant = 'filled';
                }
              } else {
                // Durante il quiz, se l'utente ha selezionato questa opzione
                if (selectedOption === option.id) {
                  chipColor = 'primary';
                  variant = 'filled';
                }
              }

              return (
                <Tooltip key={option.id} title={option.text} arrow>
                  <Chip
                    label={option.text}
                    clickable
                    onClick={() => onSelectOption(questionData.id, option.id)}
                    color={chipColor}
                    variant={variant}
                    sx={{
                      fontWeight: 500,
                      cursor: 'pointer',
                      transition: 'all 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: '0px 4px 8px rgba(0,0,0,0.15)',
                      },
                      // Se l'utente ha selezionato questa opzione e non siamo in "correzione"
                      ...(selectedOption === option.id && !showCorrectness && {
                        backgroundColor: '#DF4634',
                        color: '#FFFFFF',
                        '&:hover': {
                          backgroundColor: '#DF4634',
                        },
                      }),
                      // Se è l'opzione corretta in correzione
                      ...(showCorrectness && option.is_correct && {
                        backgroundColor: '#4CAF50',
                        color: '#FFFFFF',
                      }),
                      // Se è l'opzione sbagliata selezionata in correzione
                      ...(showCorrectness &&
                        !option.is_correct &&
                        selectedOption === option.id && {
                          backgroundColor: '#B71C1C',
                          color: '#FFFFFF',
                        }),
                    }}
                  />
                </Tooltip>
              );
            })}
          </Box>
        ) : questionData.type === 'open_ended' ? (
          /* Domanda a Risposta Aperta */
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Risposta"
            placeholder="Scrivi la tua risposta qui..."
            value={userAnswer?.text || ''}
            onChange={(e) => onChangeText(questionData.id, e.target.value)}
            sx={{ marginTop: '0.5rem' }}
          />
        ) : (
          <Typography color="error">
            Tipo di domanda non riconosciuto.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

/* -------------------------------------------------------------------------- */
/*                       COMPONENTE RIEPILOGO (SUMMARY)                       */
/* -------------------------------------------------------------------------- */
const QuizSummary = ({ quiz, userAnswers, onReplay, onReturn }) => {
  let correctCount = 0;
  const quizWithResults = quiz.map((q) => {
    if (q.type === 'multiple_choice') {
      const userOptionId = userAnswers[q.id]?.optionId;
      const correctOption = q.options.find((opt) => opt.is_correct);
      const isCorrect = userOptionId === correctOption?.id;
      if (isCorrect) correctCount++;
      return { ...q, userOptionId, correctOption, isCorrect };
    } else if (q.type === 'open_ended') {
      const userText = userAnswers[q.id]?.text || '';
      // Qui potresti implementare una logica di valutazione per le risposte aperte
      // Per ora consideriamo sempre come non valutate
      return { ...q, userText, isCorrect: null };
    } else {
      return { ...q, isCorrect: null };
    }
  });

  return (
    <Box sx={{ marginTop: '2rem' }}>
      <Typography
        variant="h5"
        sx={{ marginBottom: '1rem', textAlign: 'center', fontWeight: 700 }}
      >
        Riepilogo del Quiz
      </Typography>

      <Typography
        variant="h6"
        sx={{ textAlign: 'center', marginBottom: '1.5rem', color: '#333' }}
      >
        Score: {correctCount} / {quiz.length}
      </Typography>

      <Grid container spacing={2}>
        {quizWithResults.map((q, index) => (
          <Grid item xs={12} key={q.id}>
            <Card
              variant="outlined"
              sx={{
                borderRadius: '1rem',
                boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                backgroundColor: '#ffffff',
                padding: '1rem',
                marginBottom: '1rem',
              }}
            >
              <CardContent>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {index + 1}. {q.question}
                </Typography>

                {q.type === 'multiple_choice' ? (
                  <>
                    {/* Risposta data dall'utente */}
                    <Typography
                      variant="body1"
                      sx={{
                        color: q.isCorrect ? '#4CAF50' : '#B71C1C',
                        marginTop: '0.5rem',
                        fontWeight: 500,
                      }}
                    >
                      Risposta:{' '}
                      {q.userOptionId
                        ? q.options.find((opt) => opt.id === q.userOptionId)?.text
                        : 'Non Risposto'}
                    </Typography>

                    {/* Se l'utente ha sbagliato, mostra opzione corretta */}
                    {!q.isCorrect && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#4CAF50',
                          marginTop: '0.25rem',
                        }}
                      >
                        Corretta: {q.correctOption?.text}
                      </Typography>
                    )}
                  </>
                ) : q.type === 'open_ended' ? (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#333',
                        marginTop: '0.5rem',
                        fontWeight: 500,
                      }}
                    >
                      Risposta: {q.userText || 'Non Risposto'}
                    </Typography>
                  </>
                ) : (
                  <Typography color="error">
                    Tipo di domanda non riconosciuto.
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box
        sx={{
          textAlign: 'center',
          marginTop: '2rem',
          display: 'flex',
          justifyContent: 'center',
          gap: '1rem',
        }}
      >
        <Button
          variant="contained"
          color="success"
          sx={{
            borderRadius: '1.875rem',
            textTransform: 'none',
            fontWeight: 500,
            backgroundColor: '#4CAF50',
            '&:hover': {
              backgroundColor: '#45A049',
            },
          }}
          onClick={() => onReplay(true)}
        >
          Rigioca
        </Button>

        <Button
          variant="contained"
          color="primary"
          sx={{
            borderRadius: '1.875rem',
            textTransform: 'none',
            fontWeight: 500,
            backgroundColor: '#1976d2',
            '&:hover': {
              backgroundColor: '#1565C0',
            },
          }}
          onClick={onReturn}
        >
          Torna ai Giochi
        </Button>
      </Box>
    </Box>
  );
};

/* -------------------------------------------------------------------------- */
/*                            PAGINA COMPLETA QUIZ                            */
/* -------------------------------------------------------------------------- */
const QuizPage = () => {
  const { quizId } = useParams();
  const navigate = useNavigate();

  // --------------------------------------------------
  // Stati di login e UI
  // --------------------------------------------------
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('access'));
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);

  // --------------------------------------------------
  // Dati quiz e loading
  // --------------------------------------------------
  const [loading, setLoading] = useState(true);
  const [quizData, setQuizData] = useState(null);

  // Stato per verificare se l'utente è owner
  const [isOwner, setIsOwner] = useState(false);

  // --------------------------------------------------
  // Logica Quiz
  // --------------------------------------------------
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [showSummary, setShowSummary] = useState(false);

  // Nome Studente
  const [studentName, setStudentName] = useState('');

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  // --------------------------------------------------
  // Effetto per FloatingMenu
  // --------------------------------------------------
  useEffect(() => {
    const handleScroll = () => {
      setShowFloatingMenu(window.scrollY > 100);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // --------------------------------------------------
  // Fetch del quiz
  // --------------------------------------------------
  const fetchQuiz = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get(`/api/interactive-games/games/${quizId}/`);
      if (response.data?.data?.quiz) {
        const quizShuffled = response.data.data.quiz.map((question) => {
          if (question.type === 'multiple_choice') {
            return {
              ...question,
              options: shuffleArray(question.options),
            };
          }
          return question;
        });
        response.data.data.quiz = quizShuffled;
      }
      setQuizData(response.data);
    } catch (error) {
      console.error('Errore nel recupero del quiz:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('Impossibile caricare il quiz.');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuiz();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizId]);

  // --------------------------------------------------
  // Decodifica token e verifica owner
  // --------------------------------------------------
  useEffect(() => {
    const token = localStorage.getItem('access');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentUserId = decodedToken.user_id;

        // Confrontiamo con quizData?.user
        if (quizData && quizData.user && currentUserId) {
          if (parseInt(quizData.user) === parseInt(currentUserId)) {
            setIsOwner(true);
            console.log("SONO owner (QuizPage)");
          } else {
            setIsOwner(false);
            console.log("NON SONO owner (QuizPage)");
          }
        }
      } catch (error) {
        console.error('Errore nella decodifica del token:', error);
      }
    }
  }, [quizData]);

  /* ------------------------------------------------------------------------
     HANDLER PER RISPOSTE
  ------------------------------------------------------------------------ */
  const handleSelectAnswer = (questionId, optionId) => {
    setUserAnswers((prev) => ({
      ...prev,
      [questionId]: {
        optionId,
      },
    }));
  };

  const handleChangeText = (questionId, newText) => {
    setUserAnswers((prev) => ({
      ...prev,
      [questionId]: {
        text: newText,
      },
    }));
  };

  const handleNextQuestion = () => {
    if (!quizData?.data?.quiz) return;
    if (currentQuestionIndex < quizData.data.quiz.length - 1) {
      setCurrentQuestionIndex((idx) => idx + 1);
    }
  };

  const handlePrevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((idx) => idx - 1);
    }
  };

  // Fine quiz => check
  const handleCheckAnswers = () => {
    if (!quizData?.data?.quiz) return;
    setShowSummary(true);

    // Calcoliamo un punteggio semplice (solo multiple_choice)
    let correctCount = 0;
    const quizArray = quizData.data.quiz;
    quizArray.forEach((q) => {
      if (q.type === 'multiple_choice') {
        const userOptionId = userAnswers[q.id]?.optionId;
        const correctOption = q.options.find((opt) => opt.is_correct);
        if (userOptionId && correctOption && userOptionId === correctOption.id) {
          correctCount++;
        }
      }
    });

    setSnackbarSeverity('success');
    setSnackbarMessage(
      `Hai risposto correttamente a ${correctCount} domande su ${quizArray.length}!`
    );
    setSnackbarOpen(true);
  };

  // Replay
  const handleReplay = (resetAnswers = true) => {
    if (resetAnswers) {
      setUserAnswers({});
    }
    setShowSummary(false);
    setCurrentQuestionIndex(0);
  };

  // Torna ai giochi
  const handleReturnToGames = () => {
    navigate('/chat/games');
  };

  // Chiudi Snackbar
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  // --------------------------------------------------
  // Calcolo e salvataggio punteggio
  // --------------------------------------------------
  const computeScoreData = () => {
    let correctCount = 0;
    const details = [];
    if (!quizData?.data?.quiz) return { score: 0, details: [] };

    quizData.data.quiz.forEach((q) => {
      if (q.type === 'multiple_choice') {
        const userOptionId = userAnswers[q.id]?.optionId;
        const correctOpt = q.options.find((opt) => opt.is_correct);
        const isCorrect = userOptionId === correctOpt?.id;
        if (isCorrect) correctCount++;
        details.push({
          questionId: q.id,
          userAnswer: userOptionId,
          correctAnswer: correctOpt?.id,
          correct: isCorrect,
        });
      } else if (q.type === 'open_ended') {
        const userText = userAnswers[q.id]?.text || '';
        details.push({
          questionId: q.id,
          userAnswer: userText,
          correctAnswer: null, // Non valutato
          correct: null,       // Non valutato
        });
      }
    });

    return {
      score: correctCount,
      details,
    };
  };

  const handleSaveScore = async () => {
    if (!studentName.trim()) {
      setSnackbarMessage('Inserisci il tuo nome!');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    const { score, details } = computeScoreData();
    const payload = {
      student_name: studentName,
      score,
      details,
    };

    try {
      await apiClient.post(`/api/interactive-games/games/${quizId}/scores/`, payload);
      setSnackbarMessage('Punteggio salvato con successo!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setStudentName('');
    } catch (err) {
      console.error('Errore nel salvataggio dello score:', err);
      setSnackbarMessage('Errore nel salvataggio del punteggio.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // --------------------------------------------------
  // Condividi
  // --------------------------------------------------
  const handleShare = () => {
    const shareUrl = window.location.href;
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        setSnackbarMessage('Link copiato negli appunti!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      })
      .catch(() => {
        setSnackbarMessage('Impossibile copiare il link.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  /*
   * =====================================================================
   *                           RENDERING
   * =====================================================================
   */

  // 1) Schermata di caricamento
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          background: 'linear-gradient(180deg, #DF4634 0px, #c1ff72 10px)',
        }}
      >
        <CircularProgress sx={{ color: '#DF4634' }} />
      </Box>
    );
  }

  // 2) Quiz non disponibile
  if (!quizData?.data?.quiz) {
    return (
      <>
        <Header
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
          backgroundColor="#DF4634"
          textColor="#FFFFFF"
          logoImage="/logo/logobianco.svg"
        />
        {mobileMenuOpen && (
          <MobileMenu
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            setMobileMenuOpen={setMobileMenuOpen}
          />
        )}
        {showFloatingMenu && (
          <FloatingMenu
            isLoggedIn={isLoggedIn}
            handleLogout={() => {
              localStorage.removeItem('access');
              localStorage.removeItem('refresh');
              setIsLoggedIn(false);
            }}
          />
        )}

        {/* Contenitore principale */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: 'linear-gradient(180deg, #DF4634 0px, #c1ff72 10px)',
            padding: '2rem',
            marginBottom: '2rem',
          }}
        >
          {/* Immagine trofeo.png a sinistra (sparisce su xs) */}
          <Box
            component="img"
            src="/quiz_icon/trofeo.png"
            alt="Trofeo Icon"
            sx={{
              width: '100px',
              height: 'auto',
              marginLeft: '10px',
              display: { xs: 'none', sm: 'block' },
            }}
          />

          {/* BOX CENTRALE */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: '#fff',
              borderRadius: '1rem',
              boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
              padding: '2rem',
              maxWidth: '600px',
              width: '100%',
              margin: '0 1rem',
            }}
          >
            <Box
              component="img"
              src="/quiz_icon/quiz.png"
              alt="Quiz Icon"
              sx={{
                width: '100px',
                height: 'auto',
                marginBottom: '1.5rem',
              }}
            />
            <Typography
              variant="h6"
              sx={{ marginBottom: '1rem', color: '#555', textAlign: 'center' }}
            >
              Quiz non disponibile
            </Typography>

            <Button
              variant="contained"
              sx={{
                backgroundColor: '#DF4634',
                color: '#FFF',
                borderRadius: '1.875rem',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#E57373',
                },
              }}
              onClick={() => navigate('/games')}
            >
              Torna ai Giochi
            </Button>
          </Box>

          <Box
            component="img"
            src="/quiz_icon/question.png"
            alt="Question Icon"
            sx={{
              width: '100px',
              height: 'auto',
              marginRight: '10px',
              display: { xs: 'none', sm: 'block' },
            }}
          />
        </Box>

        <Footer />
      </>
    );
  }

  // 3) Quiz disponibile
  const { title } = quizData;
  const quizArray = quizData.data.quiz || [];
  const totalQuestions = quizArray.length;
  const currentQuestionData = quizArray[currentQuestionIndex];

  return (
    <>
      {/* HEADER */}
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        backgroundColor="#DF4634"
        textColor="#FFFFFF"
        logoImage="/logo/logobianco.svg"
      />
      {mobileMenuOpen && (
        <MobileMenu
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          setMobileMenuOpen={setMobileMenuOpen}
        />
      )}
      {showFloatingMenu && (
        <FloatingMenu
          isLoggedIn={isLoggedIn}
          handleLogout={() => {
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            setIsLoggedIn(false);
          }}
        />
      )}

      {/* CONTENITORE PRINCIPALE */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: '#c1ff72',
          padding: '2rem',
          marginBottom: '2rem',
        }}
      >
        <Box
          component="img"
          src="/quiz_icon/trofeo.png"
          alt="Trofeo Icon"
          sx={{
            width: '280px',
            height: 'auto',
            marginLeft: '20px',
            display: { xs: 'none', sm: 'block' },
          }}
        />

        {/* BOX CENTRALE */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#fff',
            borderRadius: '1rem',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
            padding: '2rem',
            maxWidth: '800px',
            width: '100%',
            margin: '0 1rem',
          }}
        >
          <Box
            component="img"
            src="/quiz_icon/quiz.png"
            alt="Quiz Icon"
            sx={{
              width: '200px',
              height: 'auto',
              marginBottom: '1.5rem',
            }}
          />

          {!showSummary ? (
            <>
              {/* TITOLO */}
              <Typography
                variant="h5"
                sx={{
                  marginBottom: '2rem',
                  textAlign: 'center',
                  fontWeight: 700,
                  color: '#333',
                }}
              >
                {title || 'Quiz'}
              </Typography>

              {/* DOMANDA CORRENTE */}
              <QuestionCard
                questionData={currentQuestionData}
                userAnswer={userAnswers[currentQuestionData.id]}
                onSelectOption={
                  currentQuestionData.type === 'multiple_choice'
                    ? handleSelectAnswer
                    : null
                }
                onChangeText={
                  currentQuestionData.type === 'open_ended'
                    ? handleChangeText
                    : null
                }
                showCorrectness={false}
              />

              {/* NAVIGAZIONE QUIZ */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '2rem',
                  width: '100%',
                }}
              >
                {currentQuestionIndex > 0 ? (
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: '1.875rem',
                      borderColor: '#DF4634',
                      color: '#DF4634',
                      textTransform: 'none',
                      fontWeight: 500,
                      padding: '0.6rem 1.2rem',
                      '&:hover': {
                        borderColor: '#DF4634',
                        backgroundColor: 'rgba(223,70,52,0.06)',
                      },
                    }}
                    onClick={handlePrevQuestion}
                  >
                    Indietro
                  </Button>
                ) : (
                  <Box />
                )}

                {currentQuestionIndex < totalQuestions - 1 ? (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#DF4634',
                      borderRadius: '1.875rem',
                      textTransform: 'none',
                      fontWeight: 500,
                      padding: '0.6rem 1.2rem',
                      '&:hover': {
                        backgroundColor: '#E57373',
                      },
                    }}
                    onClick={handleNextQuestion}
                    disabled={
                      currentQuestionData.type === 'multiple_choice'
                        ? !userAnswers[currentQuestionData.id]?.optionId
                        : userAnswers[currentQuestionData.id]?.text?.trim() === ''
                    }
                  >
                    Avanti
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#DF4634',
                      borderRadius: '1.875rem',
                      textTransform: 'none',
                      fontWeight: 500,
                      padding: '0.6rem 1.2rem',
                      '&:hover': {
                        backgroundColor: '#E57373',
                      },
                    }}
                    onClick={handleCheckAnswers}
                    disabled={
                      currentQuestionData.type === 'multiple_choice'
                        ? !userAnswers[currentQuestionData.id]?.optionId
                        : userAnswers[currentQuestionData.id]?.text?.trim() === ''
                    }
                  >
                    Verifica Risposte
                  </Button>
                )}
              </Box>

              {/* PULSANTI EXTRA: Condividi + Classifica se owner */}
              <Box
                sx={{
                  textAlign: 'center',
                  marginTop: '2rem',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  gap: '1rem',
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: '#1976d2',
                    color: '#1976d2',
                    borderRadius: '1.875rem',
                    textTransform: 'none',
                    fontWeight: 500,
                    padding: '0.6rem 1.2rem',
                    '&:hover': {
                      borderColor: '#115293',
                      backgroundColor: 'rgba(25, 118, 210, 0.06)',
                    },
                  }}
                  onClick={handleShare}
                >
                  Condividi
                </Button>

                {isOwner && (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#1976d2',
                      borderRadius: '1.875rem',
                      textTransform: 'none',
                      fontWeight: 500,
                      padding: '0.6rem 1.2rem',
                      '&:hover': {
                        backgroundColor: '#1565C0',
                      },
                    }}
                    onClick={() => navigate(`/games/${quizId}/scores`)}
                  >
                    Vedi Classifica
                  </Button>
                )}
              </Box>
            </>
          ) : (
            <>
              {/* RIEPILOGO */}
              <QuizSummary
                quiz={quizArray}
                userAnswers={userAnswers}
                onReplay={handleReplay}
                onReturn={handleReturnToGames}
              />

              {/* FORM SALVA PUNTEGGIO */}
              <Box sx={{ textAlign: 'center', marginTop: '2rem', width: '100%' }}>
                <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
                  Inserisci il tuo nome per salvare il punteggio:
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem',
                  }}
                >
                  <TextField
                    value={studentName}
                    onChange={(e) => setStudentName(e.target.value)}
                    label="Nome Studente"
                    variant="outlined"
                    sx={{ flexGrow: 1, maxWidth: '300px' }}
                  />
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleSaveScore}
                    sx={{ textTransform: 'none' }}
                  >
                    Salva Score
                  </Button>
                </Box>
              </Box>
            </>
          )}

          {/* SNACKBAR */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={4000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbarSeverity}
              sx={{ width: '100%' }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>

        <Box
          component="img"
          src="/quiz_icon/question.png"
          alt="Question Icon"
          sx={{
            width: '280px',
            height: 'auto',
            marginRight: '20px',
            display: { xs: 'none', sm: 'block' },
          }}
        />
      </Box>

      {/* FOOTER */}
      <Footer />
    </>
  );
};

export default QuizPage;
