// File: src/components/games/MatchPairsPage.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Paper,
  Grid,
  Divider,
  TextField,
  Menu,
  MenuItem,
} from '@mui/material';

import Header from '../Header';
import Footer from '../Footer';
import MobileMenu from '../MobileMenu';
import FloatingMenu from '../FloatingMenu';
import apiClient from '../../api/apiClient';

// Usa lo stesso import di decodifica token che hai in HomePage
import { jwtDecode } from 'jwt-decode';

/* ===========================================================================
   UTILI
   =========================================================================== */
function shuffleArray(array) {
  const arr = [...array];
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}

/* ===========================================================================
   COMPONENTE DRAGGABLE CHIP
   =========================================================================== */
const DraggableChip = ({
  label,
  isDragging,
  draggableData,
  onDragStart,
  onDragEnd,
  onClick,
}) => (
  <Paper
    draggable
    onDragStart={(e) => onDragStart(e, draggableData)}
    onDragEnd={onDragEnd}
    onClick={(e) => onClick(e, draggableData)}
    sx={{
      display: 'inline-block',
      padding: '0.5rem 1rem',
      marginBottom: '0.5rem',
      borderRadius: '1rem',
      backgroundColor: isDragging ? '#E0E0E0' : '#ffffff',
      cursor: 'grab',
      boxShadow: '0px 1px 3px rgba(0,0,0,0.2)',
      transition: 'background-color 0.3s ease-in-out',
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
      userSelect: 'none',
    }}
  >
    <Typography variant="body2" sx={{ fontWeight: 500 }}>
      {label}
    </Typography>
  </Paper>
);

/* ===========================================================================
   COLONNA DI SINISTRA
   =========================================================================== */
const LeftColumn = ({
  items,
  onDragStart,
  onDragEnd,
  draggingItemId,
  onChipClick,
}) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: '1rem',
        padding: '1rem',
        minHeight: '300px',
        backgroundColor: '#fff',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: '0.5rem' }}>
        Concetti di Sinistra
      </Typography>
      <Divider sx={{ mb: 1 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        {items.map((item) => (
          <DraggableChip
            key={item.id}
            label={item.text}
            isDragging={draggingItemId === item.id}
            draggableData={item}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onClick={onChipClick}
          />
        ))}
      </Box>
    </Paper>
  );
};

/* ===========================================================================
   COLONNA DI DESTRA
   =========================================================================== */
const RightColumn = ({
  items,
  assignedItems,
  onDropItem,
  draggingOverId,
  showCorrection,
}) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: '1rem',
        padding: '1rem',
        minHeight: '300px',
        backgroundColor: '#fff',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: '0.5rem' }}>
        Concetti di Destra
      </Typography>
      <Divider sx={{ mb: 1 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        {items.map((item) => {
          const assigned = assignedItems[item.id];
          let borderColor = '#e0e0e0';
          if (draggingOverId === item.id) {
            borderColor = '#DF4634';
          }
          if (showCorrection && assigned) {
            borderColor =
              assigned.correctRightId === item.id ? '#4CAF50' : '#B71C1C';
          }

          return (
            <Box
              key={item.id}
              sx={{
                padding: '0.5rem 0.75rem',
                border: `2px dashed ${borderColor}`,
                borderRadius: '0.5rem',
                minHeight: '48px',
                position: 'relative',
                transition: 'border-color 0.2s',
              }}
              onDragOver={(e) => e.preventDefault()}
              onDrop={() => onDropItem(item)}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, marginBottom: '0.25rem' }}
              >
                {item.text}
              </Typography>

              {/* Se c’è un left assegnato */}
              {assigned && (
                <Paper
                  sx={{
                    padding: '0.25rem 0.5rem',
                    borderRadius: '1rem',
                    backgroundColor: '#f5f5f5',
                    display: 'inline-block',
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {assigned.text}
                  </Typography>
                </Paper>
              )}
            </Box>
          );
        })}
      </Box>
    </Paper>
  );
};

/* ===========================================================================
   PAGINA PRINCIPALE MATCHPAIRS
   =========================================================================== */
const MatchPairsPage = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();

  // --------------------------------------------------
  // Stati per la UI generale
  // --------------------------------------------------
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('access'));
  const [loading, setLoading] = useState(true);

  // --------------------------------------------------
  // Dati gioco e owner
  // --------------------------------------------------
  const [gameData, setGameData] = useState(null);
  const [isOwner, setIsOwner] = useState(false);

  // --------------------------------------------------
  // Array di oggetti left/right
  // --------------------------------------------------
  const [leftItems, setLeftItems] = useState([]);
  const [rightItems, setRightItems] = useState([]);
  const [assignedItems, setAssignedItems] = useState({});

  // DRAG & DROP
  const [draggingItemId, setDraggingItemId] = useState(null);
  const [draggingOverId, setDraggingOverId] = useState(null);

  // Score & correction
  const [showCorrection, setShowCorrection] = useState(false);

  // Nome studente e salvataggio
  const [studentName, setStudentName] = useState('');

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  // --------------------------------------------------
  // Gestione scroll menu (FloatingMenu)
  // --------------------------------------------------
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowFloatingMenu(true);
      } else {
        setShowFloatingMenu(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // --------------------------------------------------
  // Caricamento gioco
  // --------------------------------------------------
  const fetchGame = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get(`/api/interactive-games/games/${gameId}/`);
      setGameData(response.data);
    } catch (err) {
      console.error('Errore nel caricamento del gioco matchpairs:', err);
      setSnackbarMessage('Impossibile caricare il gioco.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);

  // --------------------------------------------------
  // Verifica owner
  // --------------------------------------------------
  useEffect(() => {
    const token = localStorage.getItem('access');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentUserId = decodedToken.user_id;

        if (gameData && gameData.user && currentUserId) {
          if (parseInt(gameData.user) === parseInt(currentUserId)) {
            setIsOwner(true);
            console.log('SONO owner');
          } else {
            setIsOwner(false);
            console.log('NON SONO owner');
          }
        }
      } catch (error) {
        console.error('Errore nella decodifica del token:', error);
      }
    }
  }, [gameData]);

  // --------------------------------------------------
  // Setup LeftItems & RightItems
  // --------------------------------------------------
  useEffect(() => {
    if (!gameData?.data?.matchpairs) return;

    const allLeft = [];
    const allRight = [];
    let indexCounter = 0;

    gameData.data.matchpairs.forEach((block) => {
      block.pairs.forEach((p) => {
        const leftId = `left_${indexCounter}`;
        const rightId = `right_${indexCounter}`;
        allLeft.push({
          id: leftId,
          text: p.left,
          correctRightId: rightId,
        });
        allRight.push({
          id: rightId,
          text: p.right,
        });
        indexCounter++;
      });
    });

    setLeftItems(shuffleArray(allLeft));
    setRightItems(shuffleArray(allRight));
    setAssignedItems({});
  }, [gameData]);

  /* -----------------------------------------------------------------------
     DRAG & DROP
  ------------------------------------------------------------------------*/
  const handleDragStart = (e, item) => {
    setDraggingItemId(item.id);
  };
  const handleDragEnd = () => {
    setDraggingItemId(null);
    setDraggingOverId(null);
  };
  const handleDropRight = (rightItem) => {
    const draggedLeft = leftItems.find((l) => l.id === draggingItemId);
    if (!draggedLeft) return;

    setAssignedItems((prev) => ({
      ...prev,
      [rightItem.id]: draggedLeft,
    }));

    setDraggingItemId(null);
    setDraggingOverId(null);
  };

  /* -----------------------------------------------------------------------
     TAP & SELECT (menu di destra)
  ------------------------------------------------------------------------*/
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLeft, setSelectedLeft] = useState(null);

  const handleLeftChipClick = (event, leftItem) => {
    setAnchorEl(event.currentTarget);
    setSelectedLeft(leftItem);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedLeft(null);
  };

  const handleAssignToRight = (rightItem) => {
    setAssignedItems((prev) => ({
      ...prev,
      [rightItem.id]: selectedLeft,
    }));
    handleCloseMenu();
  };

  /* -----------------------------------------------------------------------
     LOGICA DI FINE QUIZ E PUNTEGGIO
  ------------------------------------------------------------------------*/
  const handleCheck = () => {
    setShowCorrection(true);

    let total = rightItems.length;
    let correctCount = 0;
    Object.entries(assignedItems).forEach(([rId, lObj]) => {
      if (lObj.correctRightId === rId) {
        correctCount++;
      }
    });

    setSnackbarMessage(`Corrette: ${correctCount} / ${total}`);
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };

  const handleReplay = () => {
    if (!gameData?.data?.matchpairs) return;
    setShowCorrection(false);
    setAssignedItems({});
    setLeftItems((prev) => shuffleArray(prev));
    setRightItems((prev) => shuffleArray(prev));
  };

  const handleReturnToGames = () => {
    navigate('/chat/games');
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  // Calcolo score
  const computeScoreData = () => {
    let total = rightItems.length;
    let correctCount = 0;
    const details = [];

    rightItems.forEach((ri) => {
      const assigned = assignedItems[ri.id];
      if (!assigned) {
        details.push({
          rightId: ri.id,
          rightText: ri.text,
          leftId: null,
          leftText: null,
          correct: false,
        });
      } else {
        const isCorrect = assigned.correctRightId === ri.id;
        if (isCorrect) correctCount++;
        details.push({
          rightId: ri.id,
          rightText: ri.text,
          leftId: assigned.id,
          leftText: assigned.text,
          correct: isCorrect,
        });
      }
    });

    return { score: correctCount, total, details };
  };

  const handleSaveScore = async () => {
    if (!studentName.trim()) {
      setSnackbarMessage('Inserisci il tuo nome!');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }
    const { score, details } = computeScoreData();
    const payload = {
      student_name: studentName,
      score,
      details,
    };
    try {
      await apiClient.post(`/api/interactive-games/games/${gameId}/scores/`, payload);
      setSnackbarMessage('Punteggio salvato con successo!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setStudentName('');
    } catch (err) {
      console.error('Errore salvataggio score:', err);
      setSnackbarMessage('Errore nel salvataggio del punteggio.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Condividi
  const handleShare = () => {
    const shareUrl = window.location.href;
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        setSnackbarMessage('Link copiato negli appunti!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      })
      .catch(() => {
        setSnackbarMessage('Impossibile copiare il link.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  /* -----------------------------------------------------------------------
     RENDER
  ------------------------------------------------------------------------*/
  if (loading) {
    return (
      <>
        <Header
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
          backgroundColor="#DF4634"
          textColor="#FFFFFF"
          logoImage="/logo/logobianco.svg"
        />
        {mobileMenuOpen && (
          <MobileMenu
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            setMobileMenuOpen={setMobileMenuOpen}
          />
        )}
        {showFloatingMenu && (
          <FloatingMenu
            isLoggedIn={isLoggedIn}
            handleLogout={() => {
              localStorage.removeItem('access');
              localStorage.removeItem('refresh');
              setIsLoggedIn(false);
            }}
          />
        )}

        {/* CONTENITORE PRINCIPALE */}
        <Box
          sx={{
            position: 'relative',
            minHeight: '100vh',
            backgroundColor: '#F3F6FB',
            padding: '2rem',
          }}
        >
          {/* arrowleft.png a sinistra (sparisce su xs) */}
          <Box
            component="img"
            src="/matchpairs/arrowleft.png"
            alt="Arrow Left"
            sx={{
              position: 'absolute',
              left: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              width: '250px',
              height: 'auto',
              display: { xs: 'none', sm: 'block' },
            }}
          />
          {/* arrowright.png a destra (sparisce su xs) */}
          <Box
            component="img"
            src="/matchpairs/arrowright.png"
            alt="Arrow Right"
            sx={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              width: '250px',
              height: 'auto',
              display: { xs: 'none', sm: 'block' },
            }}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '60vh',
            }}
          >
            <CircularProgress sx={{ color: '#DF4634' }} />
          </Box>
        </Box>

        <Footer />
      </>
    );
  }

  if (!gameData?.data?.matchpairs) {
    return (
      <>
        <Header
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
          backgroundColor="#DF4634"
          textColor="#FFFFFF"
          logoImage="/logo/logobianco.svg"
        />
        {mobileMenuOpen && (
          <MobileMenu
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            setMobileMenuOpen={setMobileMenuOpen}
          />
        )}
        {showFloatingMenu && (
          <FloatingMenu
            isLoggedIn={isLoggedIn}
            handleLogout={() => {
              localStorage.removeItem('access');
              localStorage.removeItem('refresh');
              setIsLoggedIn(false);
            }}
          />
        )}

        {/* CONTENITORE PRINCIPALE */}
        <Box
          sx={{
            position: 'relative',
            minHeight: '100vh',
            backgroundColor: '#F3F6FB',
            padding: '2rem',
          }}
        >
          {/* arrowleft.png a sinistra (sparisce su xs) */}
          <Box
            component="img"
            src="/matchpairs/arrowleft.png"
            alt="Arrow Left"
            sx={{
              position: 'absolute',
              left: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              width: '250px',
              height: 'auto',
              display: { xs: 'none', sm: 'block' },
            }}
          />
          {/* arrowright.png a destra (sparisce su xs) */}
          <Box
            component="img"
            src="/matchpairs/arrowright.png"
            alt="Arrow Right"
            sx={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              width: '250px',
              height: 'auto',
              display: { xs: 'none', sm: 'block' },
            }}
          />

          <Box sx={{ textAlign: 'center', marginTop: '5rem' }}>
            <Typography variant="h6">MatchPairs non disponibile</Typography>
            <Button variant="contained" onClick={() => navigate('/games')}>
              Torna ai Giochi
            </Button>
          </Box>
        </Box>

        <Footer />
      </>
    );
  }

  const { title } = gameData;

  return (
    <>
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        backgroundColor="#DF4634"
        textColor="#FFFFFF"
        logoImage="/logo/logobianco.svg"
      />
      {mobileMenuOpen && (
        <MobileMenu
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          setMobileMenuOpen={setMobileMenuOpen}
        />
      )}
      {showFloatingMenu && (
        <FloatingMenu
          isLoggedIn={isLoggedIn}
          handleLogout={() => {
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            setIsLoggedIn(false);
          }}
        />
      )}

      {/* CONTENITORE PRINCIPALE */}
      <Box
        sx={{
          position: 'relative',
          minHeight: '100vh',
          backgroundColor: '#FAC8C2',
          padding: '2rem',
        }}
      >
        {/* arrowleft.png a sinistra (sparisce su xs) */}
        <Box
          component="img"
          src="/matchpairs/arrowleft.png"
          alt="Arrow Left"
          sx={{
            position: 'absolute',
            left: '-2rem',
            top: '25%',
            transform: 'translateY(-50%)',
            width: '450px',
            height: 'auto',
            display: { xs: 'none', sm: 'block' },
          }}
        />
        <Box
          component="img"
          src="/matchpairs/arrowleft.png"
          alt="Arrow Left"
          sx={{
            position: 'absolute',
            left: '-5rem',
            top: '65%',
            transform: 'translateY(-50%) rotate(180deg)',
            width: '450px',
            height: 'auto',
            display: { xs: 'none', sm: 'block' },
          }}
        />

        {/* arrowright.png a destra (sparisce su xs) */}
        <Box
          component="img"
          src="/matchpairs/arrowright.png"
          alt="Arrow Right"
          sx={{
            position: 'absolute',
            right: '-5rem',
            top: '25.5%',
            transform: 'translateY(-50%)',
            width: '450px',
            height: 'auto',
            display: { xs: 'none', sm: 'block' },
          }}
        />
        <Box
          component="img"
          src="/matchpairs/arrowleft.png"
          alt="Arrow Right"
          sx={{
            position: 'absolute',
            right: '-2.5rem',
            top: '65%',
            transform: 'translateY(-50%) rotate(180deg)',
            width: '450px',
            height: 'auto',
            display: { xs: 'none', sm: 'block' },
          }}
        />

        {/* WRAPPER DEL GIOCO */}
        <Box
          sx={{
            position: 'relative',
            maxWidth: '900px',
            margin: '0 auto',
            backgroundColor: '#fff',
            padding: '2rem',
            borderRadius: '1rem',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
            marginTop: '3rem',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              marginBottom: '2rem',
              textAlign: 'center',
              fontWeight: 700,
              color: '#333',
            }}
          >
            {title || 'Abbina Coppie'}
          </Typography>

          <Grid container spacing={2}>
            {/* Colonna Sinistra */}
            <Grid item xs={12} md={5}>
              <LeftColumn
                items={leftItems}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                draggingItemId={draggingItemId}
                onChipClick={handleLeftChipClick}
              />
            </Grid>
            {/* Colonna Destra */}
            <Grid item xs={12} md={7}>
              <RightColumn
                items={rightItems}
                assignedItems={assignedItems}
                showCorrection={showCorrection}
                draggingOverId={draggingOverId}
                onDropItem={handleDropRight}
              />
            </Grid>
          </Grid>

          {/* Pulsanti azione */}
          <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
            {!showCorrection ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#DF4634',
                    borderRadius: '1.875rem',
                    textTransform: 'none',
                    fontWeight: 500,
                    padding: '0.6rem 1.2rem',
                    '&:hover': {
                      backgroundColor: '#E57373',
                    },
                  }}
                  onClick={handleCheck}
                >
                  Verifica
                </Button>

                {/* Se isOwner === true, mostra "Punteggi Salvati" */}
                {isOwner && (
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: '#1976d2',
                      color: '#1976d2',
                      borderRadius: '1.875rem',
                      textTransform: 'none',
                      fontWeight: 500,
                      padding: '0.6rem 1.2rem',
                      '&:hover': {
                        borderColor: '#115293',
                        backgroundColor: 'rgba(25, 118, 210, 0.06)',
                      },
                    }}
                    onClick={() => navigate(`/games/${gameId}/scores`)}
                  >
                    Punteggi Salvati
                  </Button>
                )}

                <Button
                  variant="outlined"
                  sx={{
                    borderColor: '#1976d2',
                    color: '#1976d2',
                    borderRadius: '1.875rem',
                    textTransform: 'none',
                    fontWeight: 500,
                    padding: '0.6rem 1.2rem',
                    '&:hover': {
                      borderColor: '#115293',
                      backgroundColor: 'rgba(25, 118, 210, 0.06)',
                    },
                  }}
                  onClick={handleShare}
                >
                  Condividi
                </Button>

                <Button
                  variant="outlined"
                  sx={{
                    borderColor: '#DF4634',
                    color: '#DF4634',
                    borderRadius: '1.875rem',
                    textTransform: 'none',
                    fontWeight: 500,
                    padding: '0.6rem 1.2rem',
                    '&:hover': {
                      borderColor: '#C0392B',
                      backgroundColor: 'rgba(223,70,52,0.06)',
                    },
                  }}
                  onClick={handleReturnToGames}
                >
                  Torna ai Giochi
                </Button>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#DF4634',
                    borderRadius: '1.875rem',
                    textTransform: 'none',
                    fontWeight: 500,
                    padding: '0.6rem 1.2rem',
                    '&:hover': {
                      backgroundColor: '#E57373',
                    },
                  }}
                  onClick={handleReplay}
                >
                  Rigioca
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#1976d2',
                    borderRadius: '1.875rem',
                    textTransform: 'none',
                    fontWeight: 500,
                    padding: '0.6rem 1.2rem',
                    '&:hover': {
                      backgroundColor: '#1565C0',
                    },
                  }}
                  onClick={handleReturnToGames}
                >
                  Torna ai Giochi
                </Button>
              </Box>
            )}
          </Box>

          {/* Se showCorrection => mostra form salvataggio punteggio */}
          {showCorrection && (
            <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
              <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
                Inserisci il tuo nome per salvare il punteggio:
              </Typography>
              <TextField
                value={studentName}
                onChange={(e) => setStudentName(e.target.value)}
                label="Nome Studente"
                variant="outlined"
                sx={{ marginRight: '1rem' }}
              />
              <Button variant="contained" color="success" onClick={handleSaveScore}>
                Salva Score
              </Button>
            </Box>
          )}

          {/* Pulsante Condividi */}
          <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
            <Button
              variant="outlined"
              sx={{
                borderColor: '#1976d2',
                color: '#1976d2',
                borderRadius: '1.875rem',
                textTransform: 'none',
                fontWeight: 500,
                padding: '0.6rem 1.2rem',
                '&:hover': {
                  borderColor: '#115293',
                  backgroundColor: 'rgba(25, 118, 210, 0.06)',
                },
              }}
              onClick={handleShare}
            >
              Condividi
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Menu di scelta per “tap & select” */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {rightItems.map((rightItem) => (
          <MenuItem
            key={rightItem.id}
            onClick={() => handleAssignToRight(rightItem)}
          >
            {rightItem.text}
          </MenuItem>
        ))}
      </Menu>

      {/* SNACKBAR */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Footer />
    </>
  );
};

export default MatchPairsPage;
