// File: src/components/Services.js
import React, { useState } from 'react';
import './styles/Services.css';

// Icone per i vari servizi
const quizIcon = '/home_icon/quiz.png';
const activityIcon = '/home_icon/attività.png';
const labIcon = '/home_icon/laboratorio.png';
const writingIcon = '/home_icon/writing.png';
const methodologyIcon = '/home_icon/metodologie.png';

// Definizione dei servizi
const services = [
  {
    title: 'Attività didattiche',
    description: 'Genera attività interattive e divertenti, pronte all’uso.',
    details:
      'Sfrutta il potenziale di un agente specializzato per progettare attività didattiche uniche, calibrate sul grado scolastico e sul livello di difficoltà desiderato. Crea proposte pratiche che incentivano curiosità e coinvolgimento.',
    icon: activityIcon,
    detailsTitle: 'Come Minerva ti supporta nelle Attività?',
    customDetails: (
      <div className="chat-container">
        {/* Messaggio dell'utente */}
        <div className="chat-message user-message">
          <div className="avatar user-avatar">
            <img
              src="/menu_icon/profile.png"
              alt="Profilo"
              className="profile-image"
            />
          </div>
          <p>
            Puoi creare un’attività di un’ora per la festa dei nonni per una
            classe di prima primaria?
          </p>
        </div>
        {/* Messaggio dell'assistente con indicatore di digitazione */}
        <div className="chat-message assistant-message">
          <div className="avatar assistant-avatar">
            <img
              src="/logo/logobianco.svg"
              alt="Profilo"
              className="profile-image"
            />
          </div>
          <p className="typing-indicator">
            <span></span>
            <span></span>
            <span></span>
          </p>
        </div>
      </div>
    ),
  },
  {
    title: 'Ricerca Metodologie',
    description: 'Sperimenta nuove tecniche didattiche con risorse mirate.',
    details:
      'Cerca idee e approfondimenti su metodologie collaudate e orientate all’innovazione. Scopri risorse bibliografiche, spunti di applicazione pratica e strumenti digitali per elevare la tua didattica.',
    icon: methodologyIcon,
    detailsTitle: 'Come usare Minerva per le Metodologie?',
    customDetails: (
      <div className="chat-container">
        {/* Messaggio dell'utente */}
        <div className="chat-message user-message">
          <div className="avatar user-avatar">
            <img
              src="/menu_icon/profile.png"
              alt="Profilo"
              className="profile-image"
            />
          </div>
          <p>Qual è la metodologia migliore per bambini DSA? Come posso applicarla?</p>
        </div>
        {/* Messaggio dell'assistente con indicatore di digitazione */}
        <div className="chat-message assistant-message">
          <div className="avatar assistant-avatar">
            <img
              src="/logo/logobianco.svg"
              alt="Profilo"
              className="profile-image"
            />
          </div>
          <p className="typing-indicator">
            <span></span>
            <span></span>
            <span></span>
          </p>
        </div>
      </div>
    ),
  },
  {
    title: 'Laboratori e progetti',
    description: 'Progetta esperienze pratiche che entusiasmano gli studenti.',
    details:
      'Con l’aiuto di un agente specializzato, avrai indicazioni puntuali per la realizzazione di laboratori didattici e progetti collaborativi. Promuovi l’apprendimento esperienziale per stimolare il pensiero critico e la creatività.',
    icon: labIcon,
    detailsTitle: 'Come creare Laboratori efficaci con Minerva?',
    customDetails: (
      <div className="chat-container">
        {/* Messaggio dell'utente */}
        <div className="chat-message user-message">
          <div className="avatar user-avatar">
            <img
              src="/menu_icon/profile.png"
              alt="Profilo"
              className="profile-image"
            />
          </div>
          <p>
            Creami un laboratorio didattico sull’importanza delle emozioni per
            bambini di terza primaria
          </p>
        </div>
        {/* Messaggio dell'assistente con indicatore di digitazione */}
        <div className="chat-message assistant-message">
          <div className="avatar assistant-avatar">
            <img
              src="/logo/logobianco.svg"
              alt="Profilo"
              className="profile-image"
            />
          </div>
          <p className="typing-indicator">
            <span></span>
            <span></span>
            <span></span>
          </p>
        </div>
      </div>
    ),
  },
  {
    title: 'Scrittura',
    description: 'Migliora documenti e testi a scopo educativo.',
    details:
      'Ottieni suggerimenti per rielaborare progetti di UDA, programmazioni, verbali, materiali di studio e molto altro. Traduce, semplifica e potenzia la chiarezza del testo, mantenendo uno stile coerente con l’obiettivo didattico.',
    icon: writingIcon,
    detailsTitle: 'Come scrivere testi in modo ottimale?',
    customDetails: (
      <div className="chat-container">
        {/* Messaggio dell'utente */}
        <div className="chat-message user-message">
          <div className="avatar user-avatar">
            <img
              src="/menu_icon/profile.png"
              alt="Profilo"
              className="profile-image"
            />
          </div>
          <p>
            Aiutami a scrivere meglio questa programmazione settimanale, includendo i vantaggi delle attività didattiche.
          </p>
        </div>
        {/* Messaggio dell'assistente con indicatore di digitazione */}
        <div className="chat-message assistant-message">
          <div className="avatar assistant-avatar">
            <img
              src="/logo/logobianco.svg"
              alt="Profilo"
              className="profile-image"
            />
          </div>
          <p className="typing-indicator">
            <span></span>
            <span></span>
            <span></span>
          </p>
        </div>
      </div>
    ),
  },
  {
    title: 'Creazione Quiz',
    description: 'Prepara quiz e test interattivi con un click.',
    details:
      'Chiedi a Minerva di sviluppare quiz a risposta multipla, vero/falso o altre tipologie. Le domande saranno bilanciate e orientate agli obiettivi formativi, per rendere l’apprendimento più dinamico e coinvolgente.',
    icon: quizIcon,
    detailsTitle: 'Come creare Quiz in pochi secondi?',
    customDetails: (
      <div className="chat-container">
        {/* Messaggio dell'utente */}
        <div className="chat-message user-message">
          <div className="avatar user-avatar">
            <img
              src="/menu_icon/profile.png"
              alt="Profilo"
              className="profile-image"
            />
          </div>
          <p>
            Genera un quiz stile invalsi per una quinta primaria di matematica.
          </p>
        </div>
        {/* Messaggio dell'assistente con indicatore di digitazione */}
        <div className="chat-message assistant-message">
          <div className="avatar assistant-avatar">
            <img
              src="/logo/logobianco.svg"
              alt="Profilo"
              className="profile-image"
            />
          </div>
          <p className="typing-indicator">
            <span></span>
            <span></span>
            <span></span>
          </p>
        </div>
      </div>
    ),
  },
];

// Componente principale
const ServicesComponent = () => {
  // Imposta il primo servizio come selezionato di default
  const [selectedService, setSelectedService] = useState(services[0]);
  // Per l'animazione fade-in / fade-out
  const [animationClass, setAnimationClass] = useState('fade-in');

  const handleServiceClick = (service) => {
    if (service !== selectedService) {
      setAnimationClass('fade-out');
      setTimeout(() => {
        setSelectedService(service);
        setAnimationClass('fade-in');
      }, 300);
    }
  };

  return (
    <div className="services-section">
      {/* Sezione introduttiva */}
      <div className="services-intro">
        <div className="services-intro-text">
          <h1>
            Tutti gli strumenti per
            <br />
            fare del tuo meglio.
          </h1>
          <p>
            Scopri come Minerva semplifica la didattica, offrendo esperti AI
            specializzati in attività, laboratori, scrittura e molto altro.
          </p>
        </div>
      </div>

      {/* Griglia dei servizi */}
      <div className="services-grid">
        {services.map((service, index) => (
          <div
            key={index}
            className={`service-card ${selectedService === service ? 'active' : ''}`}
            onClick={() => handleServiceClick(service)}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleServiceClick(service);
              }
            }}
            aria-pressed={selectedService === service}
          >
            <h2 className="service-title">{service.title}</h2>
            <img
              src={service.icon}
              alt={`${service.title} icon`}
              className="service-icon"
            />
          </div>
        ))}
      </div>

      <hr className="services-divider" />

      {/* Sezione di dettaglio */}
      <div className="details-section">
        <div className="details-left">
          <h2 className="details-title">
            {selectedService ? selectedService.detailsTitle : 'Titolo Generico'}
          </h2>
          {selectedService ? (
            selectedService.customDetails
          ) : (
            <div className="chat-container">
              {/* Contenuto generico se non c'è nessun servizio selezionato */}
              <div className="chat-message user-message">
                <div className="avatar user-avatar"></div>
                <p>Descrizione Generica</p>
              </div>
              <div className="chat-message assistant-message">
                <div className="avatar assistant-avatar"></div>
                <p className="typing-indicator">
                  <span></span>
                  <span></span>
                  <span></span>
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="details-right">
          {selectedService && (
            <div className={`service-details ${animationClass}`}>
              <h3 className="service-details-title">{selectedService.title}</h3>
              <p className="service-details-text">{selectedService.details}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServicesComponent;
