// File: src/components/games/SelectGroupPage.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Grid,
  Paper,
  Divider,
  Chip,
  Menu,
  MenuItem
} from '@mui/material';

import Header from '../Header';
import Footer from '../Footer';
import MobileMenu from '../MobileMenu';
import FloatingMenu from '../FloatingMenu';
import apiClient from '../../api/apiClient';

// Usa lo stesso import di decodifica token che hai in HomePage
import { jwtDecode } from 'jwt-decode';

/* --------------------------------------------------------------------------
   GROUPCOLUMN COMPONENT
   -------------------------------------------------------------------------- */
const GroupColumn = ({
  group,
  placedWords,
  onRemoveWord,
  showCorrectness = false,
  solutionMap,
  onDragOver,
  onDrop,
  onDragStartChip,
  onDragEndChip
}) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: '1rem',
        padding: '1rem',
        minHeight: '100px',
        backgroundColor: '#fff',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
        marginBottom: '1rem'
      }}
      onDragOver={onDragOver}
      onDrop={(e) => onDrop(e, group.id)}
    >
      <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: '0.5rem' }}>
        {group.topic}
      </Typography>
      <Divider sx={{ mb: 1 }} />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
        {placedWords.map((word, idx) => {
          let chipColor = 'default';
          if (showCorrectness) {
            if (solutionMap[word] === group.id) {
              chipColor = 'success';
            } else {
              chipColor = 'error';
            }
          }
          return (
            <Chip
              key={`${group.id}-${word}-${idx}`}
              label={word}
              color={chipColor}
              onClick={() => onRemoveWord(group.id, word)}
              draggable
              onDragStart={(e) => onDragStartChip(e, group.id, word)}
              onDragEnd={onDragEndChip}
              sx={{
                fontWeight: 500,
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.05)'
                }
              }}
            />
          );
        })}
      </Box>
    </Paper>
  );
};

/* --------------------------------------------------------------------------
   MAIN PAGE COMPONENT: SelectGroupPage
   -------------------------------------------------------------------------- */
const SelectGroupPage = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();

  // --------------------------------------------------
  // UI States
  // --------------------------------------------------
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('access'));
  const [loading, setLoading] = useState(true);

  // --------------------------------------------------
  // Dati gioco & owner
  // --------------------------------------------------
  const [gameData, setGameData] = useState(null);
  const [isOwner, setIsOwner] = useState(false);

  // --------------------------------------------------
  // States per il gameplay
  // --------------------------------------------------
  const [availableWords, setAvailableWords] = useState([]);
  const [groupedWords, setGroupedWords] = useState({});
  const [solutionMap, setSolutionMap] = useState({});
  const [showSummary, setShowSummary] = useState(false);

  // --------------------------------------------------
  // Snackbar
  // --------------------------------------------------
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  // --------------------------------------------------
  // Menu a tendina (tap & select)
  // --------------------------------------------------
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedWord, setSelectedWord] = useState(null);

  // --------------------------------------------------
  // Drag & Drop states
  // --------------------------------------------------
  const [draggedWord, setDraggedWord] = useState(null);
  const [sourceGroupId, setSourceGroupId] = useState(null);

  // --------------------------------------------------
  // Effetto per FloatingMenu
  // --------------------------------------------------
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowFloatingMenu(true);
      } else {
        setShowFloatingMenu(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // --------------------------------------------------
  // Fetch del gioco
  // --------------------------------------------------
  const fetchGame = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get(`/api/interactive-games/games/${gameId}/`);
      setGameData(response.data);
    } catch (err) {
      console.error('Errore nel caricamento del gioco:', err);
      setSnackbarMessage('Impossibile caricare il gioco.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);

  // --------------------------------------------------
  // Verifica se l’utente è owner
  // --------------------------------------------------
  useEffect(() => {
    const token = localStorage.getItem('access');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentUserId = decodedToken.user_id;

        if (gameData && gameData.user && currentUserId) {
          if (parseInt(gameData.user) === parseInt(currentUserId)) {
            setIsOwner(true);
            console.log("SONO owner (SelectGroup)");
          } else {
            setIsOwner(false);
            console.log("NON SONO owner (SelectGroup)");
          }
        }
      } catch (error) {
        console.error('Errore nella decodifica del token:', error);
      }
    }
  }, [gameData]);

  // --------------------------------------------------
  // Quando abbiamo gameData, inizializziamo le parole
  // --------------------------------------------------
  useEffect(() => {
    if (!gameData || !gameData.data?.selectgroup) return;
    const selectgroup = gameData.data.selectgroup;

    const allWords = [];
    const solMap = {};

    selectgroup.forEach((grp) => {
      grp.words.forEach((w) => {
        allWords.push(w);
        solMap[w] = grp.id;
      });
    });

    setAvailableWords(allWords);

    const initialGrouped = {};
    selectgroup.forEach((grp) => {
      initialGrouped[grp.id] = [];
    });
    setGroupedWords(initialGrouped);
    setSolutionMap(solMap);
  }, [gameData]);

  // --------------------------------------------------
  // Apertura e chiusura Menu
  // --------------------------------------------------
  const handleOpenMenu = (e, word) => {
    setAnchorEl(e.currentTarget);
    setSelectedWord(word);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedWord(null);
  };
  const handleAssignToGroup = (groupId) => {
    if (!selectedWord) return;
    setAvailableWords((prev) => prev.filter((w) => w !== selectedWord));
    setGroupedWords((prev) => ({
      ...prev,
      [groupId]: [...prev[groupId], selectedWord],
    }));
    handleCloseMenu();
  };

  // --------------------------------------------------
  // Rimuovi parola dal gruppo
  // --------------------------------------------------
  const handleRemoveWord = (groupId, word) => {
    setGroupedWords((prev) => ({
      ...prev,
      [groupId]: prev[groupId].filter((w) => w !== word),
    }));
    setAvailableWords((prev) => [...prev, word]);
  };

  // --------------------------------------------------
  // DRAG & DROP
  // --------------------------------------------------
  const handleDragStartChip = (e, fromGroupId, word) => {
    setDraggedWord(word);
    setSourceGroupId(fromGroupId);
    e.dataTransfer.setData('text/plain', word);
  };

  const handleDragEndChip = () => {
    setDraggedWord(null);
    setSourceGroupId(null);
  };

  const handleDrop = (e, targetGroupId = null) => {
    e.preventDefault();
    if (!draggedWord) return;

    // Se targetGroupId è null => rimetti la parola in "disponibili"
    if (targetGroupId === null) {
      if (sourceGroupId) {
        // Rimuovi dal gruppo
        setGroupedWords((prev) => ({
          ...prev,
          [sourceGroupId]: prev[sourceGroupId].filter((w) => w !== draggedWord),
        }));
      }
      setAvailableWords((prev) => [...prev, draggedWord]);
    } else {
      // Sposto la parola in un gruppo
      if (sourceGroupId === null) {
        // Era nelle disponibili
        setAvailableWords((prev) => prev.filter((w) => w !== draggedWord));
      } else {
        // Rimuovila dal gruppo source
        setGroupedWords((prev) => ({
          ...prev,
          [sourceGroupId]: prev[sourceGroupId].filter((w) => w !== draggedWord),
        }));
      }
      setGroupedWords((prev) => ({
        ...prev,
        [targetGroupId]: [...prev[targetGroupId], draggedWord],
      }));
    }

    setDraggedWord(null);
    setSourceGroupId(null);
  };

  // --------------------------------------------------
  // Conferma: calcolo punteggio
  // --------------------------------------------------
  const handleCheckAnswers = () => {
    setShowSummary(true);
    let total = 0;
    total += availableWords.length;
    Object.values(groupedWords).forEach((arr) => {
      total += arr.length;
    });

    let correctCount = 0;
    Object.keys(groupedWords).forEach((groupId) => {
      groupedWords[groupId].forEach((wd) => {
        if (solutionMap[wd] === groupId) {
          correctCount++;
        }
      });
    });

    setSnackbarMessage(`Corrette: ${correctCount}/${total}`);
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };

  const handleReplay = (resetAnswers = true) => {
    if (!resetAnswers || !gameData?.data?.selectgroup) {
      setShowSummary(false);
      return;
    }
    const selectgroup = gameData.data.selectgroup;
    const allWords = [];
    const solMap = {};
    selectgroup.forEach((grp) => {
      grp.words.forEach((w) => {
        allWords.push(w);
        solMap[w] = grp.id;
      });
    });
    setAvailableWords(allWords);

    const newGrouped = {};
    selectgroup.forEach((grp) => {
      newGrouped[grp.id] = [];
    });
    setGroupedWords(newGrouped);

    setShowSummary(false);
  };

  const handleReturnToGames = () => {
    navigate('/chat/games');
  };

  // --------------------------------------------------
  // Snackbar
  // --------------------------------------------------
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  // --------------------------------------------------
  // Condivisione link
  // --------------------------------------------------
  const handleShare = () => {
    const shareUrl = window.location.href;
    navigator.clipboard.writeText(shareUrl)
      .then(() => {
        setSnackbarMessage('Link copiato negli appunti!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      })
      .catch(() => {
        setSnackbarMessage('Impossibile copiare il link.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  // --------------------------------------------------
  // Rendering
  // --------------------------------------------------
  if (loading) {
    return (
      <>
        <Header
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
          backgroundColor="#DF4634"
          textColor="#FFFFFF"
          logoImage="/logo/logobianco.svg"
        />
        {mobileMenuOpen && (
          <MobileMenu
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            setMobileMenuOpen={setMobileMenuOpen}
          />
        )}
        {showFloatingMenu && (
          <FloatingMenu
            isLoggedIn={isLoggedIn}
            handleLogout={() => {
              localStorage.removeItem('access');
              localStorage.removeItem('refresh');
              setIsLoggedIn(false);
            }}
          />
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '2rem',
            backgroundColor: '#caf9fa',
            marginBottom: '2rem',
            position: 'relative',
          }}
        >
          <Box
            component="img"
            src="/selectgroup/gamemachineleft.png"
            alt="Game Machine Left"
            sx={{
              width: '150px',
              height: 'auto',
              position: 'absolute',
              left: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              display: { xs: 'none', sm: 'block' },
            }}
          />
          <Box
            component="img"
            src="/selectgroup/gamemachineright.png"
            alt="Game Machine Right"
            sx={{
              width: '150px',
              height: 'auto',
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              display: { xs: 'none', sm: 'block' },
            }}
          />

          <CircularProgress sx={{ color: '#DF4634' }} />
        </Box>
        <Footer />
      </>
    );
  }

  if (!gameData || !gameData.data?.selectgroup) {
    return (
      <>
        <Header
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
          backgroundColor="#DF4634"
          textColor="#FFFFFF"
          logoImage="/logo/logobianco.svg"
        />
        {mobileMenuOpen && (
          <MobileMenu
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            setMobileMenuOpen={setMobileMenuOpen}
          />
        )}
        {showFloatingMenu && (
          <FloatingMenu
            isLoggedIn={isLoggedIn}
            handleLogout={() => {
              localStorage.removeItem('access');
              localStorage.removeItem('refresh');
              setIsLoggedIn(false);
            }}
          />
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '2rem',
            backgroundColor: '#caf9fa',
            marginBottom: '2rem',
            position: 'relative',
          }}
        >
          <Box
            component="img"
            src="/selectgroup/gamemachineleft.png"
            alt="Game Machine Left"
            sx={{
              width: '150px',
              height: 'auto',
              position: 'absolute',
              left: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              display: { xs: 'none', sm: 'block' },
            }}
          />
          <Box
            component="img"
            src="/selectgroup/gamemachineright.png"
            alt="Game Machine Right"
            sx={{
              width: '150px',
              height: 'auto',
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              display: { xs: 'none', sm: 'block' },
            }}
          />

          <Box
            component="img"
            src="/selectgroup/gameplatform.png"
            alt="Game Platform"
            sx={{
              width: '200px',
              height: 'auto',
              marginTop: '2rem',
              display: { xs: 'none', sm: 'block' },
            }}
          />

          <Typography variant="h6" sx={{ marginBottom: '1rem', color: '#555' }}>
            SelectGroup non disponibile
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#DF4634',
              color: '#FFF',
              borderRadius: '1.875rem',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#E57373',
              },
            }}
            onClick={() => navigate('/chat/games')}
          >
            Torna ai Giochi
          </Button>
        </Box>
        <Footer />
      </>
    );
  }

  const { title } = gameData;
  const groups = gameData.data.selectgroup;

  return (
    <>
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        backgroundColor="#DF4634"
        textColor="#FFFFFF"
        logoImage="/logo/logobianco.svg"
      />
      {mobileMenuOpen && (
        <MobileMenu
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          setMobileMenuOpen={setMobileMenuOpen}
        />
      )}
      {showFloatingMenu && (
        <FloatingMenu
          isLoggedIn={isLoggedIn}
          handleLogout={() => {
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            setIsLoggedIn(false);
          }}
        />
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#caf9fa',
          padding: '2rem',
          marginBottom: '2rem',
          position: 'relative',
        }}
      >
        {/* Immagini decorative a sinistra e destra */}
        <Box
          component="img"
          src="/selectgroup/gamemachineleft.png"
          alt="Game Machine Left"
          sx={{
            width: '450px',
            height: 'auto',
            position: 'absolute',
            left: '-6rem',
            top: '50%',
            transform: 'translateY(-50%)',
            display: { xs: 'none', sm: 'block' },
          }}
        />
        <Box
          component="img"
          src="/selectgroup/gamemachineright.png"
          alt="Game Machine Right"
          sx={{
            width: '450px',
            height: 'auto',
            position: 'absolute',
            right: '-5.6rem',
            top: '50%',
            transform: 'translateY(-50%)',
            display: { xs: 'none', sm: 'block' },
          }}
        />

        {/* WRAPPER CHE LIMITA LA LARGHEZZA DEL GIOCO */}
        <Box
          sx={{
            width: '100%',
            maxWidth: '900px',
            margin: '0 auto',
            position: 'relative',
          }}
        >
          {/* TITOLO GIOCO */}
          <Box sx={{ textAlign: 'center', padding: '1rem' }}>
            <Typography variant="h4" sx={{ fontWeight: 700, color: '#333' }}>
              {title || 'Select Group'}
            </Typography>
          </Box>

          {/* CONTENUTO PRINCIPALE */}
          <Box sx={{ padding: '1rem' }}>
            {!showSummary ? (
              <>
                <Grid container spacing={2}>
                  {/* Colonna sinistra: parole disponibili */}
                  <Grid
                    item
                    xs={12}
                    md={4}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => handleDrop(e, null)}
                  >
                    <Paper
                      variant="outlined"
                      sx={{
                        borderRadius: '1rem',
                        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                        padding: '1rem',
                        minHeight: '300px',
                        marginBottom: '2rem',
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 600, marginBottom: '0.5rem' }}
                      >
                        Parole Disponibili
                      </Typography>
                      <Divider sx={{ mb: 1 }} />
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
                        {availableWords.map((w, i) => (
                          <Chip
                            key={`${w}-${i}`}
                            label={w}
                            color="primary"
                            onClick={(e) => handleOpenMenu(e, w)}
                            draggable
                            onDragStart={(e) => handleDragStartChip(e, null, w)}
                            onDragEnd={handleDragEndChip}
                            sx={{
                              fontWeight: 500,
                              cursor: 'pointer',
                              '&:hover': {
                                transform: 'scale(1.05)'
                              }
                            }}
                          />
                        ))}
                      </Box>
                    </Paper>
                  </Grid>

                  {/* Colonna destra: i group */}
                  <Grid item xs={12} md={8}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      {groups.map((grp) => (
                        <GroupColumn
                          key={grp.id}
                          group={grp}
                          placedWords={groupedWords[grp.id] || []}
                          onRemoveWord={handleRemoveWord}
                          showCorrectness={false}
                          solutionMap={solutionMap}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={handleDrop}
                          onDragStartChip={handleDragStartChip}
                          onDragEndChip={handleDragEndChip}
                        />
                      ))}
                    </Box>
                  </Grid>
                </Grid>

                {/* Se sono owner, aggiungo pulsante PUNTEGGI SALVATI */}
                <Box
                  sx={{ display: 'flex', gap: '1rem', justifyContent: 'center', marginTop: '2rem' }}
                >
                  {isOwner && (
                    <Button
                      variant="outlined"
                      sx={{
                        borderColor: '#1976d2',
                        color: '#1976d2',
                        borderRadius: '1.875rem',
                        textTransform: 'none',
                        fontWeight: 500,
                        padding: '0.6rem 1.2rem',
                        '&:hover': {
                          borderColor: '#115293',
                          backgroundColor: 'rgba(25,118,210,0.06)',
                        },
                      }}
                      onClick={() => navigate(`/games/${gameId}/scores`)}
                    >
                      Punteggi Salvati
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#DF4634',
                      borderRadius: '1.875rem',
                      textTransform: 'none',
                      fontWeight: 500,
                      padding: '0.6rem 1.2rem',
                      '&:hover': {
                        backgroundColor: '#E57373',
                      },
                    }}
                    onClick={handleCheckAnswers}
                  >
                    Conferma
                  </Button>
                </Box>

                {/* PULSANTE CONDIVIDI */}
                <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: '#1976d2',
                      color: '#1976d2',
                      borderRadius: '1.875rem',
                      textTransform: 'none',
                      fontWeight: 500,
                      padding: '0.6rem 1.2rem',
                      '&:hover': {
                        borderColor: '#115293',
                        backgroundColor: 'rgba(25,118,210,0.06)',
                      },
                    }}
                    onClick={handleShare}
                  >
                    Condividi
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Grid container spacing={2}>
                  {/* Parole non assegnate */}
                  <Grid
                    item
                    xs={12}
                    md={4}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => handleDrop(e, null)}
                  >
                    <Paper
                      variant="outlined"
                      sx={{
                        borderRadius: '1rem',
                        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                        padding: '1rem',
                        minHeight: '300px',
                        marginBottom: '2rem',
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 600, marginBottom: '0.5rem' }}
                      >
                        Parole Rimaste
                      </Typography>
                      <Divider sx={{ mb: 1 }} />
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
                        {availableWords.map((wd, idx) => (
                          <Chip
                            key={`remain-${wd}-${idx}`}
                            label={wd}
                            color="error"
                            draggable
                            onDragStart={(e) => handleDragStartChip(e, null, wd)}
                            onDragEnd={handleDragEndChip}
                            sx={{ fontWeight: 500 }}
                          />
                        ))}
                      </Box>
                    </Paper>
                  </Grid>

                  {/* Gruppi in correzione (showCorrectness = true) */}
                  <Grid item xs={12} md={8}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      {groups.map((grp) => (
                        <GroupColumn
                          key={grp.id}
                          group={grp}
                          placedWords={groupedWords[grp.id] || []}
                          onRemoveWord={handleRemoveWord}
                          showCorrectness={true}
                          solutionMap={solutionMap}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={handleDrop}
                          onDragStartChip={handleDragStartChip}
                          onDragEndChip={handleDragEndChip}
                        />
                      ))}
                    </Box>
                  </Grid>
                </Grid>

                {/* PULSANTI FINALI */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '1rem',
                    marginTop: '2rem',
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#DF4634',
                      borderRadius: '1.875rem',
                      textTransform: 'none',
                      fontWeight: 500,
                      padding: '0.6rem 1.2rem',
                      '&:hover': {
                        backgroundColor: '#E57373',
                      },
                    }}
                    onClick={() => handleReplay(true)}
                  >
                    Rigioca
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#1976d2',
                      borderRadius: '1.875rem',
                      textTransform: 'none',
                      fontWeight: 500,
                      padding: '0.6rem 1.2rem',
                      '&:hover': {
                        backgroundColor: '#1565C0',
                      },
                    }}
                    onClick={handleReturnToGames}
                  >
                    Torna ai Giochi
                  </Button>
                </Box>
              </>
            )}
          </Box>

          {/* Immagine gameplatform.png sotto (sparisce su xs) */}
          <Box
            component="img"
            src="/selectgroup/gameplatform.png"
            alt="Game Platform"
            sx={{
              width: '260px',
              height: 'auto',
              marginTop: '-2rem',
              display: { xs: 'none', sm: 'block' },
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
        </Box>
      </Box>

      {/* MENU A TENDINA */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        {groups.map((grp) => (
          <MenuItem key={grp.id} onClick={() => handleAssignToGroup(grp.id)}>
            {grp.topic}
          </MenuItem>
        ))}
      </Menu>

      {/* SNACKBAR */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Footer />
    </>
  );
};

export default SelectGroupPage;
