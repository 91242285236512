// File: src/components/GamesPage.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  useMediaQuery,
  Grid,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  Tooltip,
  CircularProgress,
  CardMedia,
  Paper,
  Button,
  useTheme,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { getAccessToken, removeTokens } from '../../api/authUtils';

const baseUrl = process.env.REACT_APP_BASE_URL;

// Mappa per le immagini dei vari tipi di giochi
const agentImages = {
  Quiz: '/people/quiz.png',
  fillthebox: '/people/fillthebox.png',
  selectgroup: '/people/selectgroup.png',
  matchpairs: '/people/matchpairs.png',
  rearrange: '/people/rearrange.png',
  crossword: '/people/crossword.png',
};

// Elenco dei "tipi di giochi"
const interactiveAgents = [
  {
    name: 'Quiz',
    description: 'Genera quiz per diversi livelli di apprendimento.',
    image: '/people/quiz.png',
    agentKey: 'quiz',
  },
  {
    name: 'Completa frase',
    description: 'Esercizi per completare frasi con opzioni multiple.',
    image: '/people/fillthebox.png',
    agentKey: 'fillthebox',
  },
  {
    name: 'Raggruppa',
    description: 'Crea liste di parole suddivise in gruppi tematici.',
    image: '/people/selectgroup.png',
    agentKey: 'selectgroup',
  },
  {
    name: 'Abbina coppie',
    description: 'Crea esercizi di abbinamento (match pairs).',
    image: '/people/matchpairs.png',
    agentKey: 'matchpairs',
  },
  {
    name: 'Riordina',
    description: 'Crea esercizi dove si devono riordinare le frasi.',
    image: '/people/rearrange.png',
    agentKey: 'rearrange',
  },
  {
    name: 'Cruciverba',
    description: 'Genera cruciverba personalizzati a partire da parole chiave o concetti.',
    image: '/people/crossword.png',
    agentKey: 'crossword',
  },
];

const GamesPage = ({ onCreateGame }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);

  // Stati per dialog di conferma eliminazione
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [gameToDelete, setGameToDelete] = useState(null);

  // Stati per la Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Stato per evidenziare la card selezionata
  const [selectedGameType, setSelectedGameType] = useState(null);

  // ---------------------------
  // Autenticazione & Refresh
  // ---------------------------
  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refresh');
    if (!refreshToken) {
      removeTokens();
      navigate('/login');
      return null;
    }
    try {
      const response = await fetch(`${baseUrl}/api/accounts/token/refresh/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refresh: refreshToken }),
      });
      if (!response.ok) throw new Error('Failed to refresh token');

      const data = await response.json();
      localStorage.setItem('access', data.access);
      if (data.refresh) localStorage.setItem('refresh', data.refresh);
      return data.access;
    } catch (error) {
      console.error('Error refreshing token:', error);
      removeTokens();
      navigate('/login');
      return null;
    }
  };

  const makeAuthenticatedRequest = async (url, options = {}) => {
    let accessToken = getAccessToken();
    if (!accessToken) {
      navigate('/login');
      return null;
    }
    const headers = { ...options.headers, Authorization: `Bearer ${accessToken}` };

    try {
      let response = await fetch(url, { ...options, headers });
      if (response.status === 401 || response.status === 403) {
        accessToken = await refreshAccessToken();
        if (!accessToken) return null;
        const retryHeaders = { ...options.headers, Authorization: `Bearer ${accessToken}` };
        response = await fetch(url, { ...options, headers: retryHeaders });
      }
      return response;
    } catch (error) {
      console.error('Error making authenticated request:', error);
      return null;
    }
  };

  // ---------------------------
  // Caricamento lista giochi
  // ---------------------------
  const fetchGames = async () => {
    setLoading(true);
    const response = await makeAuthenticatedRequest(`${baseUrl}/api/interactive-games/games/`, {
      method: 'GET',
    });

    if (!response) {
      setLoading(false);
      return;
    }
    if (response.status === 401) {
      navigate('/login');
      return;
    }
    if (response.status === 403) {
      navigate('/abbonamento');
      return;
    }
    if (!response.ok) {
      const errorData = await response.json();
      console.error('Error fetching games:', errorData);
      setSnackbarSeverity('error');
      setSnackbarMessage('Errore nel recupero dei giochi.');
      setSnackbarOpen(true);
      setLoading(false);
      return;
    }

    const data = await response.json();
    setGames(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchGames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // -----------------------------------------------------
  // RENDER per le box di selezione del tipo di gioco
  // -----------------------------------------------------
  const renderGameTypePaper = (agent) => (
    <Paper
      key={agent.agentKey}
      elevation={3}
      sx={{
        padding: '0.75rem',
        borderRadius: '1rem',
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        transition: 'all 0.3s ease',
        cursor: 'pointer',
        border:
          selectedGameType?.agentKey === agent.agentKey
            ? '2px solid #DF4634'
            : '2px solid transparent',
        '&:hover': {
          backgroundColor: 'rgba(223, 70, 52, 0.05)',
        },
      }}
      onClick={() => setSelectedGameType(agent)}
    >
      <Box
        component="img"
        src={agent.image}
        alt={agent.name}
        sx={{
          width: '3.3rem',
          height: '3.3rem',
          margin: '0 auto 0.5rem',
        }}
      />
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 500,
          marginBottom: '0.1rem',
          fontSize: '0.9rem',
          lineHeight: '1.2',
          color: '#333',
        }}
      >
        {agent.name}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: '#6B7280',
          flexGrow: 1,
          fontSize: '0.75rem',
          lineHeight: '1.2',
        }}
      >
        {agent.description}
      </Typography>

      {/* Pulsante per creare un nuovo gioco di questo tipo */}
      <Button
        variant="contained"
        sx={{
          mt: 1,
          backgroundColor: '#DF4634',
          color: '#FFFFFF',
          borderRadius: '1rem',
          textTransform: 'none',
          fontSize: '0.75rem',
          padding: '0.45rem 1rem',
          '&:hover': {
            backgroundColor: '#E57373',
          },
        }}
        onClick={(e) => {
          e.stopPropagation();
          onCreateGame(agent.agentKey);
        }}
      >
        Crea
      </Button>
    </Paper>
  );

  // ---------------------------
  // Navigazione al dettaglio
  // ---------------------------
  const handleGameClick = (gameId, gameTypeName) => {
    if (gameTypeName === 'Quiz') {
      navigate(`/games/quiz/${gameId}`);
    } else if (gameTypeName === 'fillthebox') {
      navigate(`/games/fillinthebox/${gameId}`);
    } else if (gameTypeName === 'selectgroup') {
      navigate(`/games/selectgroup/${gameId}`);
    } else if (gameTypeName === 'matchpairs') {
      navigate(`/games/matchpairs/${gameId}`);
    } else if (gameTypeName === 'rearrange') {
      navigate(`/games/rearrange/${gameId}`);
    } else if (gameTypeName === 'crossword') {
      navigate(`/games/crossword/${gameId}`);
    } else {
      alert(`Dettagli del gioco con ID: ${gameId}`);
    }
  };

  // ---------------------------
  // Eliminazione gioco
  // ---------------------------
  const handleOpenDeleteDialog = (game) => {
    setGameToDelete(game);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setGameToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (!gameToDelete) return;

    const { id, title } = gameToDelete;
    const response = await makeAuthenticatedRequest(
      `${baseUrl}/api/interactive-games/games/${id}/`,
      { method: 'DELETE' }
    );

    if (!response) {
      setSnackbarSeverity('error');
      setSnackbarMessage("Errore nell'eliminazione del gioco.");
      setSnackbarOpen(true);
      handleCloseDeleteDialog();
      return;
    }

    if (response.status === 204) {
      setGames((prevGames) => prevGames.filter((g) => g.id !== id));
      setSnackbarSeverity('success');
      setSnackbarMessage(`Gioco "${title}" eliminato con successo.`);
      setSnackbarOpen(true);
    } else {
      const errorData = await response.json();
      console.error('Error deleting game:', errorData);
      setSnackbarSeverity('error');
      setSnackbarMessage("Errore nell'eliminazione del gioco.");
      setSnackbarOpen(true);
    }

    handleCloseDeleteDialog();
  };

  // ---------------------------
  // Snackbar
  // ---------------------------
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  // ---------------------------
  // Render
  // ---------------------------
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // Usa minHeight per permettere alla pagina di crescere e scrollare:
        minHeight: '100vh',
        // Con pb: 4, aggiungiamo extra spazio in fondo per non “tagliare” le box
        pb: 4,
        overflowY: 'auto',
        backgroundColor: '#F3F6FB',
      }}
    >
      {/* PRIMO TITOLO */}
      <Box sx={{ padding: isMobile ? '1rem' : '2rem', textAlign: 'center' }}>
        <Typography variant="h5" sx={{ fontWeight: 700, color: '#333' }}>
          Template Giochi
        </Typography>
      </Box>

      {/* GRIGLIA "Tipi di giochi" */}
      <Box sx={{ px: isMobile ? '1rem' : '2rem', pb: isMobile ? '1rem' : '2rem' }}>
        <Grid container spacing={2} sx={{ mb: 4 }}>
          {interactiveAgents.map((agent) => (
            <Grid item xs={6} sm={4} md={2.4} key={agent.agentKey}>
              {renderGameTypePaper(agent)}
            </Grid>
          ))}
        </Grid>

        {/* SECONDO TITOLO */}
        <Typography
          variant="h5"
          sx={{ fontWeight: 700, mb: 2, color: '#333', textAlign: 'center' }}
        >
          I miei Giochi
        </Typography>

        {/* ELENCO DEI GIOCHI ESISTENTI */}
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100px',
            }}
          >
            <CircularProgress sx={{ color: '#DF4634' }} />
          </Box>
        ) : games.length === 0 ? (
          <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
            <Typography variant="h6" sx={{ color: '#555' }}>
              Nessun gioco creato.
            </Typography>
            <Typography variant="body2" sx={{ color: '#777', marginTop: '0.5rem' }}>
              Clicca su “Crea” su un tipo di gioco in alto per crearne uno!
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={2}>
            {games.map((game) => {
              const gameTypeName = game.game_type?.name || 'Sconosciuto';
              const imageSrc = agentImages[gameTypeName] || '/people/generic.png';

              return (
                <Grid item xs={6} sm={4} md={3} lg={2} key={game.id}>
                  <Card
                    sx={{
                      borderRadius: '0.75rem',
                      boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                      cursor: 'pointer',
                      transition: 'transform 0.2s, box-shadow 0.2s',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '220px',
                      position: 'relative',
                      '&:hover': {
                        transform: 'scale(1.03)',
                        boxShadow: '0px 4px 6px rgba(0,0,0,0.15)',
                      },
                    }}
                    onClick={() => handleGameClick(game.id, gameTypeName)}
                  >
                    <CardMedia
                      component="img"
                      image={imageSrc}
                      alt="Game Agent"
                      sx={{
                        height: '80px',
                        width: 'auto',
                        objectFit: 'contain',
                        margin: '0 auto',
                        padding: '0.5rem',
                      }}
                    />
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        padding: '0.5rem 0.75rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 600, color: '#333', textAlign: 'center' }}
                      >
                        {game.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#555',
                          textAlign: 'center',
                          marginTop: '0.25rem',
                          fontSize: '0.75rem',
                        }}
                      >
                        Tipo: {gameTypeName}
                      </Typography>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingX: '0.5rem',
                        paddingBottom: '0.3rem',
                      }}
                    >
                      {/* Pulsante Vai */}
                      <Button
                        variant="text"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleGameClick(game.id, gameTypeName);
                        }}
                        sx={{
                          textTransform: 'none',
                          color: '#DF4634',
                          fontSize: '0.75rem',
                          '&:hover': { backgroundColor: 'transparent' },
                        }}
                      >
                        Vai
                      </Button>

                      {/* Pulsante Elimina */}
                      <Tooltip title="Elimina Gioco" arrow>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenDeleteDialog(game);
                          }}
                          sx={{
                            color: '#DF4634',
                            '&:hover': {
                              color: '#B71C1C',
                              backgroundColor: 'transparent',
                            },
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>

      {/* DIALOG DI CONFERMA ELIMINAZIONE */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Elimina Gioco</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Sei sicuro di voler eliminare il gioco{' '}
            <strong>{gameToDelete?.title}</strong>?<br />
            Questa azione non è reversibile.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteDialog}
            sx={{ textTransform: 'none' }}
          >
            Annulla
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
            sx={{
              textTransform: 'none',
              backgroundColor: '#DF4634',
              '&:hover': {
                backgroundColor: '#B71C1C',
              },
            }}
          >
            Elimina
          </Button>
        </DialogActions>
      </Dialog>

      {/* SNACKBAR */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default GamesPage;
