// File: src/components/Benefits.js
import React from 'react';
import { motion } from 'framer-motion';
import './styles/Benefits.css';

const metrics = [
  { description: 'Creatività', brand: '' },
  { description: 'Personalizzazione', brand: '' },
  { description: 'Efficienza', brand: '' },
  { description: 'Supporto', brand: '' },
  { description: 'Innovazione', brand: '' },
  { description: 'Impatto', brand: '' },
];

const backContents = [
  'Genera idee uniche e stimolanti per lezioni di ogni ordine e grado.',
  'Adatta i contenuti a livello, interessi e bisogni di ogni singolo studente.',
  'Risparmia tempo nella preparazione del materiale didattico con proposte mirate.',
  'Ottieni assistenza costante per progettare e organizzare attività e laboratori.',
  'Applica metodologie didattiche all’avanguardia, ispirate a modelli di successo.',
  'Concentrati su ciò che conta davvero: far crescere e motivare i tuoi studenti.',
];

const testimonials = [
  {
    quote:
      'Minerva è un alleato prezioso che trasforma il tuo modo di insegnare con tecniche sempre nuove!',
    author: 'Minerva Education',
    role: 'Team',
    company: '',
  },
  {
    quote:
      'Attraverso Minerva, creo percorsi formativi specifici per le esigenze di ogni alunno.',
    author: 'Minerva Education',
    role: 'Team',
    company: '',
  },
  {
    quote:
      'Non insegni solo materie: trasmetti valori, ispirazioni e orizzonti da scoprire.',
    author: 'Minerva Education',
    role: 'Team',
    company: '',
  },
];

// Mappa le descrizioni alle icone corrispondenti
const descriptionIcons = {
  Creatività: '/home_icon/benefits/creativity.png',
  Personalizzazione: '/home_icon/benefits/personalization.png',
  Efficienza: '/home_icon/benefits/efficiency.png',
  Supporto: '/home_icon/benefits/support.png',
  Innovazione: '/home_icon/benefits/innovation.png',
  Impatto: '/home_icon/benefits/impact.png',
};

// FlipCard Component
const FlipCard = ({ frontContent, backContent }) => {
  return (
    <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front metric-box">
          <div className="metric-content">{frontContent}</div>
        </div>
        <div className="flip-card-back">
          <div className="back-content">{backContent}</div>
        </div>
      </div>
    </div>
  );
};

const BenefitsComponent = () => {
  // Varianti per le animazioni
  const leftColumnVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.8, ease: 'easeOut' },
    },
  };

  const rightColumnVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.8, ease: 'easeOut' },
    },
  };

  return (
    <div className="benefits-container">
      {/* Titolo Centrale */}
      <div className="benefits-title">
        <h2>Perché usare Minerva?</h2>
        <p>
          Scopri come Minerva AI Education può rivoluzionare il tuo metodo
          di insegnamento e la crescita dei tuoi studenti.
        </p>
      </div>

      {/* Prima fila */}
      <div className="row">
        {/* Colonna sinistra - Flip Cards */}
        <motion.div
          className="column left"
          variants={leftColumnVariants}
          initial="hidden"
          animate="visible"
        >
          {metrics.slice(0, 2).map((metric, index) => (
            <FlipCard
              key={index}
              frontContent={
                <>
                  <img
                    src={descriptionIcons[metric.description]}
                    alt={metric.description}
                    className="metric-icon"
                  />
                  <p className="metric-description">{metric.description}</p>
                </>
              }
              backContent={<p>{backContents[index]}</p>}
            />
          ))}
        </motion.div>
        {/* Colonna destra - Testimonial */}
        <motion.div
          className="column right"
          variants={rightColumnVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="testimonial-box">
            <p className="testimonial-quote">"{testimonials[0].quote}"</p>
            <p className="testimonial-author">
              {testimonials[0].author}, {testimonials[0].role}
            </p>
          </div>
        </motion.div>
      </div>

      {/* Seconda fila - Layout inverso */}
      <div className="row reverse">
        {/* Colonna sinistra - Testimonial */}
        <motion.div
          className="column left"
          variants={leftColumnVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="testimonial-box">
            <p className="testimonial-quote">"{testimonials[1].quote}"</p>
            <p className="testimonial-author">
              {testimonials[1].author}, {testimonials[1].role}
            </p>
          </div>
        </motion.div>
        {/* Colonna destra - Flip Cards */}
        <motion.div
          className="column right"
          variants={rightColumnVariants}
          initial="hidden"
          animate="visible"
        >
          {metrics.slice(2, 4).map((metric, index) => (
            <FlipCard
              key={index + 2}
              frontContent={
                <>
                  <img
                    src={descriptionIcons[metric.description]}
                    alt={metric.description}
                    className="metric-icon"
                  />
                  <p className="metric-description">{metric.description}</p>
                </>
              }
              backContent={<p>{backContents[index + 2]}</p>}
            />
          ))}
        </motion.div>
      </div>

      {/* Terza fila */}
      <div className="row">
        {/* Colonna sinistra - Flip Cards */}
        <motion.div
          className="column left"
          variants={leftColumnVariants}
          initial="hidden"
          animate="visible"
        >
          {metrics.slice(4, 6).map((metric, index) => (
            <FlipCard
              key={index + 4}
              frontContent={
                <>
                  <img
                    src={descriptionIcons[metric.description]}
                    alt={metric.description}
                    className="metric-icon"
                  />
                  <p className="metric-description">{metric.description}</p>
                </>
              }
              backContent={<p>{backContents[index + 4]}</p>}
            />
          ))}
        </motion.div>
        {/* Colonna destra - Testimonial */}
        <motion.div
          className="column right"
          variants={rightColumnVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="testimonial-box">
            <p className="testimonial-quote">"{testimonials[2].quote}"</p>
            <p className="testimonial-author">
              {testimonials[2].author}, {testimonials[2].role}
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default BenefitsComponent;
