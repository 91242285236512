// File: src/components/chat/Sidebar.jsx

import React from 'react';
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Link
} from '@mui/material';
import { motion } from 'framer-motion';

const Sidebar = ({
  chats,
  selectedChat,
  selectChat,
  handleNewChat,
  handleDeleteChat,
  handleLogout,
  navigate,
  selectedOption,

  // La funzione per mostrare la “GamesPage”
  onMyGamesClick,
}) => {
  // (Se serve) definisci gli agenti gioco
  const gameAgents = ['quiz', 'fillthebox', 'selectgroup', 'matchpairs', 'rearrange', 'crossword'];

  return (
    <Box
      component={motion.div}
      initial={{ x: -250 }}
      animate={{ x: 0 }}
      transition={{ type: 'spring', stiffness: 100 }}
      sx={{
        minWidth: '17%',
        maxWidth: '17%',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
        borderRadius: '1.875rem',
        padding: '1.25rem',
        margin: '1.25rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 2000,
      }}
    >
      {/* Header con logo e titolo */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: '1.25rem',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 400,
            whiteSpace: 'nowrap',
          }}
        >
          Minerva AI
        </Typography>

        <Box
          sx={{
            width: '2.3rem',
            height: '2.3rem',
            backgroundColor: '#DF4634',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
          }}
        >
          <Link href="/" sx={{ display: 'center', position: 'relative' }}>
            <Box
              component="img"
              src="/logo/logobianco.svg"
              alt="Logo"
              sx={{
                width: '2.6rem',
                height: '2.6rem',
                position: 'absolute',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          </Link>
        </Box>
      </Box>

      {/* Bottone "Nuova Chat" */}
      <Button
        variant="contained"
        onClick={() => handleNewChat(selectedOption)}
        sx={{
          backgroundColor: '#DF4634',
          borderRadius: '1.875rem',
          width: '70%',
          marginBottom: '1.25rem',
          textTransform: 'none',
          fontSize: '0.7rem',
          fontWeight: 400,
          '&:hover': {
            backgroundColor: '#DF4634',
          },
        }}
      >
        + Nuova chat
      </Button>

      {/* Titolo conversazioni */}
      <Typography
        sx={{
          color: '#6B7280',
          fontFamily: 'Inter',
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: 'normal',
          textAlign: 'left',
          width: '100%',
          marginBottom: '0.1rem',
        }}
      >
        Conversazioni
      </Typography>

      {/* Lista chat */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          width: '100%',
          maskImage: 'linear-gradient(to bottom, black 90%, transparent 95%)',
          WebkitMaskImage: 'linear-gradient(to bottom, black 90%, transparent 95%)',
        }}
      >
        <List>
          {chats?.map((chat, index) => {
            const isGameAgent = gameAgents.includes(chat.agent);
            const iconSrc = isGameAgent
              ? selectedChat?.id === chat.id
                ? '/chat_icon/gamehistoryred.png'
                : '/chat_icon/gamehistory.png'
              : selectedChat?.id === chat.id
                ? '/chat_icon/chatred.png'
                : '/chat_icon/chat.png';

            return (
              <motion.div
                key={chat.id}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.05 }}
              >
                <ListItem
                  button
                  onClick={() => selectChat(chat)}
                  sx={{
                    padding: '0.2rem 0rem',
                    borderRadius: '1.875rem',
                    marginBottom: '0.5rem',
                    position: 'relative',
                    '&:hover': {
                      backgroundColor: 'rgba(223, 70, 52, 0.1)',
                    },
                    '&:hover .delete-icon': {
                      visibility: 'visible',
                    },
                  }}
                >
                  <Box
                    component="img"
                    src={iconSrc}
                    alt="Chat Icon"
                    sx={{
                      width: '0.8rem',
                      height: '0.8rem',
                      marginRight: '0.2rem',
                      marginLeft: '0.3rem',
                    }}
                  />
                  <ListItemText
                    primary={chat.title || 'Nuova Chat'}
                    primaryTypographyProps={{
                      sx: {
                        color: selectedChat?.id === chat.id ? '#DF4634' : 'inherit',
                        fontWeight: selectedChat?.id === chat.id ? 'bold' : 'normal',
                        fontSize: '0.85rem',
                        maxWidth: '20ch',
                        wordBreak: 'break-word',
                      },
                    }}
                  />
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteChat(chat.id);
                    }}
                    className="delete-icon"
                    sx={{
                      position: 'absolute',
                      right: 0,
                      visibility: 'hidden',
                      zIndex: 1000,
                    }}
                  >
                    <img
                      src="/chat_icon/remove.png"
                      alt="Remove Chat"
                      style={{ width: '1rem', height: '1rem' }}
                    />
                  </IconButton>
                </ListItem>
              </motion.div>
            );
          })}
        </List>
      </Box>

      {/* BOTTONE "Giochi" */}
      <Button
        variant="outlined"
        sx={{
          borderRadius: '3.125rem',
          borderColor: '#DF4634',
          color: '#000',
          width: '100%',
          marginTop: '1rem',
          marginBottom: '0.625rem',
          textTransform: 'none',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          '&:hover': {
            borderColor: '#DF4634',
            backgroundColor: 'rgba(223, 70, 52, 0.1)',
          },
        }}
        onClick={() => onMyGamesClick(true)}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <img
            src="/chat_icon/games.png"
            alt="Games Icon"
            style={{ width: '1rem', height: '1rem', marginRight: '0.625rem' }}
          />
          Giochi
        </Box>
      </Button>

      {/* RIPRISTINO dei bottoni "Home", "Profilo" e "Risorse" come prima */}
      <Button
        variant="outlined"
        sx={{
          borderRadius: '3.125rem',
          borderColor: '#DF4634',
          color: '#000',
          width: '100%',
          marginBottom: '0.625rem',
          textTransform: 'none',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          '&:hover': {
            borderColor: '#DF4634',
            backgroundColor: 'rgba(223, 70, 52, 0.1)',
          },
        }}
        onClick={() => navigate('/')}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <img
            src="/chat_icon/home.png"
            alt="Home Icon"
            style={{ width: '1rem', height: '1rem', marginRight: '0.625rem' }}
          />
          Home
        </Box>
      </Button>

      <Button
        variant="outlined"
        sx={{
          borderRadius: '3.125rem',
          borderColor: '#DF4634',
          color: '#000',
          width: '100%',
          marginBottom: '0.625rem',
          textTransform: 'none',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          '&:hover': {
            borderColor: '#DF4634',
            backgroundColor: 'rgba(223, 70, 52, 0.1)',
          },
        }}
        onClick={() => navigate('/profile')}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <img
            src="/chat_icon/profile.png"
            alt="Profile Icon"
            style={{ width: '1rem', height: '1rem', marginRight: '0.625rem' }}
          />
          Profilo
        </Box>
      </Button>

      <Button
        variant="outlined"
        sx={{
          borderRadius: '3.125rem',
          borderColor: '#DF4634',
          color: '#000',
          width: '100%',
          textTransform: 'none',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          '&:hover': {
            borderColor: '#DF4634',
            backgroundColor: 'rgba(223, 70, 52, 0.1)',
          },
        }}
        onClick={() => navigate('/resources')}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <img
            src="/chat_icon/resources.png"
            alt="Exit Icon"
            style={{ width: '1rem', height: '1rem', marginRight: '0.625rem' }}
          />
          Risorse
        </Box>
      </Button>
    </Box>
  );
};

export default Sidebar;
