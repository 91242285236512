// File: src/components/games/CrosswordPage.jsx

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, CircularProgress, Snackbar, Alert, TextField } from '@mui/material';

// Usa lo stesso import di decodifica token che hai in HomePage
import { jwtDecode } from 'jwt-decode';

import Header from '../Header';
import Footer from '../Footer';
import MobileMenu from '../MobileMenu';
import FloatingMenu from '../FloatingMenu';
import apiClient from '../../api/apiClient';

const CrosswordPage = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();

  // --------------------------------------------------
  // Stati per la UI generale
  // --------------------------------------------------
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('access'));
  const [loading, setLoading] = useState(true);

  // --------------------------------------------------
  // Dati del gioco
  // --------------------------------------------------
  const [gameData, setGameData] = useState(null);

  // --------------------------------------------------
  // Stati e logica per il cruciverba
  // --------------------------------------------------
  const [solution, setSolution] = useState([]);
  const [userGrid, setUserGrid] = useState([]);
  const [cellStatus, setCellStatus] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [showSolution, setShowSolution] = useState(false);

  // Nome dello studente (se si vuole salvare il punteggio)
  const [studentName, setStudentName] = useState('');

  // --------------------------------------------------
  // Snackbar (messaggi di feedback)
  // --------------------------------------------------
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  // --------------------------------------------------
  // Chi è l’owner del gioco?
  // --------------------------------------------------
  const [isOwner, setIsOwner] = useState(false);

  // --------------------------------------------------
  // Gestione menu floating
  // --------------------------------------------------
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowFloatingMenu(true);
      } else {
        setShowFloatingMenu(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // --------------------------------------------------
  // Fetch del gioco dal backend
  // --------------------------------------------------
  const fetchGame = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get(`/api/interactive-games/games/${gameId}/`);
      setGameData(response.data);
    } catch (err) {
      console.error('Error fetching crossword:', err);
      setSnackbarMessage('Unable to load crossword.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // Al montaggio o se cambia gameId, ricarica il gioco
  useEffect(() => {
    fetchGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);

  // --------------------------------------------------
  // Controllo se l’utente loggato è l’owner
  // --------------------------------------------------
  useEffect(() => {
    const token = localStorage.getItem('access');
    if (token) {
      try {
        // Decodifichiamo il token come fai in HomePage
        const decodedToken = jwtDecode(token);
        // In DRF SimpleJWT, l'ID è di solito in decodedToken.user_id
        const currentUserId = decodedToken.user_id;

        // Ora verifichiamo se coincide con l'owner del gioco
        if (gameData && gameData.user && currentUserId) {
          if (parseInt(gameData.user) === parseInt(currentUserId)) {
            setIsOwner(true);
            console.log("SONO owner");
          } else {
            setIsOwner(false);
            console.log("NON SONO owner");
          }
        }
      } catch (error) {
        console.error('Errore nella decodifica del token:', error);
      }
    }
  }, [gameData]);

  // --------------------------------------------------
  // Prepara la griglia del cruciverba al caricamento gameData
  // --------------------------------------------------
  useEffect(() => {
    if (gameData?.data?.crossword?.grid) {
      const fullGrid = gameData.data.crossword.grid;
      setSolution(fullGrid);

      // Crea una griglia vuota per l'utente
      const emptyGrid = fullGrid.map((row) =>
        row.map((cell) => (cell === '-' ? '-' : '-'))
      );
      setUserGrid(emptyGrid);

      // Crea un array di status vuoto
      const statusGrid = fullGrid.map((row) => row.map(() => undefined));
      setCellStatus(statusGrid);

      // Reset di altre variabili
      setShowResults(false);
      setShowSolution(false);
      setScore(0);
    }
  }, [gameData]);

  // --------------------------------------------------
  // Funzione per gestire l’input utente nelle celle
  // --------------------------------------------------
  const handleCellChange = (rowIndex, colIndex, e) => {
    const val = e.target.value.toLowerCase();

    // Permettiamo solo singole lettere [a-z] o stringa vuota
    if (!val.match(/^[a-z]?$|^$/)) {
      return;
    }

    setUserGrid((prev) => {
      const newGrid = prev.map((r) => [...r]);
      newGrid[rowIndex][colIndex] = val === '' ? '-' : val;
      return newGrid;
    });
  };

  // --------------------------------------------------
  // Verifica le risposte
  // --------------------------------------------------
  const handleCheckAnswers = () => {
    if (
      !solution ||
      !Array.isArray(solution) ||
      !solution.length ||
      !userGrid ||
      !Array.isArray(userGrid) ||
      !userGrid.length
    ) {
      setSnackbarMessage('Data not ready. Please wait.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    if (solution.length !== userGrid.length) {
      setSnackbarMessage('Grid dimension mismatch.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    let correctCount = 0;
    const newStatus = userGrid.map((row, rIndex) => {
      if (!Array.isArray(solution[rIndex]) || solution[rIndex].length !== row.length) {
        return row.map(() => undefined);
      }
      return row.map((cellVal, cIndex) => {
        if (solution[rIndex][cIndex] === '-') {
          // Non-playable cell => skip
          return undefined;
        }
        if (cellVal === solution[rIndex][cIndex]) {
          correctCount++;
          return 'correct';
        }
        return 'wrong';
      });
    });

    setCellStatus(newStatus);
    setShowResults(true);
    setScore(correctCount);
    setSnackbarMessage(`Correct cells: ${correctCount}`);
    setSnackbarSeverity('info');
    setSnackbarOpen(true);
  };

  // --------------------------------------------------
  // Rigioca
  // --------------------------------------------------
  const handleReplay = () => {
    if (!solution || !solution.length) return;

    const emptyGrid = solution.map((row) =>
      row.map((cell) => (cell === '-' ? '-' : '-'))
    );
    setUserGrid(emptyGrid);

    const statusGrid = solution.map((row) => row.map(() => undefined));
    setCellStatus(statusGrid);

    setShowResults(false);
    setShowSolution(false);
    setScore(0);
  };

  // --------------------------------------------------
  // Mostra la soluzione
  // --------------------------------------------------
  const handleShowSolution = () => {
    setShowSolution(true);
  };

  // --------------------------------------------------
  // Salva punteggio
  // --------------------------------------------------
  const handleSaveScore = async () => {
    if (!studentName.trim()) {
      setSnackbarMessage('Please enter your name!');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }
    try {
      const payload = {
        student_name: studentName,
        score,
        details: { type: 'crossword' },
      };
      await apiClient.post(`/api/interactive-games/games/${gameId}/scores/`, payload);
      setSnackbarMessage('Score saved successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setStudentName('');
    } catch (err) {
      console.error('Error saving score:', err);
      setSnackbarMessage('Error saving score.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // --------------------------------------------------
  // Condivisione link
  // --------------------------------------------------
  const handleShare = () => {
    const shareUrl = window.location.href;
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        setSnackbarMessage('Link copied to clipboard!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      })
      .catch(() => {
        setSnackbarMessage('Unable to copy link.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  // --------------------------------------------------
  // Torna ai giochi
  // --------------------------------------------------
  const handleReturnToGames = () => {
    navigate('/chat/games');
  };

  // --------------------------------------------------
  // Chiude lo Snackbar
  // --------------------------------------------------
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // -------------------------------------------
  // 1) Schermata di Caricamento
  // -------------------------------------------
  if (loading) {
    return (
      <>
        <Header
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
        />
        {mobileMenuOpen && (
          <MobileMenu
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            setMobileMenuOpen={setMobileMenuOpen}
          />
        )}
        {showFloatingMenu && (
          <FloatingMenu
            isLoggedIn={isLoggedIn}
            handleLogout={() => {
              localStorage.removeItem('access');
              localStorage.removeItem('refresh');
              setIsLoggedIn(false);
            }}
          />
        )}

        <Box
          sx={{
            minHeight: '100vh',
            backgroundColor: '#125b43',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2rem',
            position: 'relative',
          }}
        >
          <Box
            component="img"
            src="/crossword/left.png"
            alt="Left Decoration"
            sx={{
              width: '250px',
              height: 'auto',
              position: 'absolute',
              left: '20px',
              top: '50%',
              transform: 'translateY(-50%)',
              display: { xs: 'none', md: 'block' },
            }}
          />
          <Box
            component="img"
            src="/crossword/right.png"
            alt="Right Decoration"
            sx={{
              width: '250px',
              height: 'auto',
              position: 'absolute',
              right: '20px',
              top: '50%',
              transform: 'translateY(-50%)',
              display: { xs: 'none', md: 'block' },
            }}
          />

          <CircularProgress sx={{ color: '#DF4634', zIndex: 1 }} />
        </Box>

        <Footer />
      </>
    );
  }

  // -------------------------------------------
  // 2) Se il cruciverba non è disponibile
  // -------------------------------------------
  if (!gameData || !gameData.data || !gameData.data.crossword) {
    return (
      <>
        <Header
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
        />
        <Box
          sx={{
            minHeight: '100vh',
            backgroundColor: '#125b43',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '2rem',
            color: '#fff',
            position: 'relative',
          }}
        >
          <Box
            component="img"
            src="/crossword/left.png"
            alt="Left Decoration"
            sx={{
              width: '250px',
              height: 'auto',
              position: 'absolute',
              left: '20px',
              top: '50%',
              transform: 'translateY(-50%)',
              display: { xs: 'none', md: 'block' },
            }}
          />
          <Box
            component="img"
            src="/crossword/right.png"
            alt="Right Decoration"
            sx={{
              width: '250px',
              height: 'auto',
              position: 'absolute',
              right: '20px',
              top: '50%',
              transform: 'translateY(-50%)',
              display: { xs: 'none', md: 'block' },
            }}
          />

          <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
            No crossword available
          </Typography>
          <Button variant="contained" onClick={handleReturnToGames}>
            Return to Games
          </Button>
        </Box>
        <Footer />
      </>
    );
  }

  // -------------------------------------------
  // OK, abbiamo i dati del cruciverba
  // -------------------------------------------
  const { title } = gameData;
  const crossword = gameData.data.crossword;
  const { words, legend } = crossword;

  // Controllo dimensioni (solution, userGrid)
  if (
    !solution ||
    !Array.isArray(solution) ||
    !solution.length ||
    !userGrid ||
    !Array.isArray(userGrid) ||
    !userGrid.length
  ) {
    return (
      <>
        <Header
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
        />
        {mobileMenuOpen && (
          <MobileMenu
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            setMobileMenuOpen={setMobileMenuOpen}
          />
        )}
        {showFloatingMenu && (
          <FloatingMenu
            isLoggedIn={isLoggedIn}
            handleLogout={() => {
              localStorage.removeItem('access');
              localStorage.removeItem('refresh');
              setIsLoggedIn(false);
            }}
          />
        )}

        <Box
          sx={{
            minHeight: '100vh',
            backgroundColor: '#125b43',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '2rem',
            color: '#fff',
            position: 'relative',
          }}
        >
          <Box
            component="img"
            src="/crossword/left.png"
            alt="Left Decoration"
            sx={{
              width: '250px',
              height: 'auto',
              position: 'absolute',
              left: '20px',
              top: '50%',
              transform: 'translateY(-50%)',
              display: { xs: 'none', md: 'block' },
            }}
          />
          <Box
            component="img"
            src="/crossword/right.png"
            alt="Right Decoration"
            sx={{
              width: '250px',
              height: 'auto',
              position: 'absolute',
              right: '20px',
              top: '50%',
              transform: 'translateY(-50%)',
              display: { xs: 'none', md: 'block' },
            }}
          />

          <Typography variant="h6">Dati Cruciverba non pronti.</Typography>
        </Box>
        <Footer />
      </>
    );
  }

  // Costruisco l'oggetto cellNumbers per posizionare i numeri nelle celle
  const cellNumbers = {};
  words.forEach((w) => {
    // row / col nel JSON partono da 1, mentre la griglia in React è 0-based
    const rowIndex = w.row - 1;
    const colIndex = w.col - 1;
    cellNumbers[`${rowIndex}-${colIndex}`] = w.number;
  });

  // ==========================================
  // Render finale del cruciverba
  // ==========================================
  return (
    <>
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />
      {mobileMenuOpen && (
        <MobileMenu
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          setMobileMenuOpen={setMobileMenuOpen}
        />
      )}
      {showFloatingMenu && (
        <FloatingMenu
          isLoggedIn={isLoggedIn}
          handleLogout={() => {
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            setIsLoggedIn(false);
          }}
        />
      )}

      <Box
        sx={{
          minHeight: '100vh',
          backgroundColor: '#125b43',
          padding: '2rem',
          position: 'relative',
        }}
      >
        {/* Immagine left.png a sinistra */}
        <Box
          component="img"
          src="/crossword/left.png"
          alt="Left Decoration"
          sx={{
            width: '250px',
            height: 'auto',
            position: 'absolute',
            left: '20px',
            top: '50%',
            transform: 'translateY(-50%)',
            display: { xs: 'none', md: 'block' },
          }}
        />

        {/* Immagine right.png a destra */}
        <Box
          component="img"
          src="/crossword/right.png"
          alt="Right Decoration"
          sx={{
            width: '250px',
            height: 'auto',
            position: 'absolute',
            right: '20px',
            top: '50%',
            transform: 'translateY(-50%)',
            display: { xs: 'none', md: 'block' },
          }}
        />

        {/* Contenitore principale */}
        <Box
          sx={{
            maxWidth: '900px',
            margin: '0 auto',
            backgroundColor: '#fff',
            borderRadius: '1rem',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
            padding: '2rem',
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Typography
            variant="h5"
            sx={{ marginBottom: '1rem', textAlign: 'center', fontWeight: 700 }}
          >
            {title || 'Crossword'}
          </Typography>

          {/* Griglia del cruciverba */}
          <Box component="table" sx={{ borderCollapse: 'collapse', margin: '0 auto' }}>
            <tbody>
              {userGrid.map((row, rIndex) => {
                if (!Array.isArray(row)) return null;
                return (
                  <tr key={rIndex}>
                    {row.map((cellValue, cIndex) => {
                      if (!Array.isArray(solution[rIndex])) {
                        return null;
                      }
                      // Se la cella in solution è '-', è una cella rossa non giocabile
                      if (solution[rIndex][cIndex] === '-') {
                        return (
                          <td
                            key={cIndex}
                            style={{
                              border: '1px solid #999',
                              width: '30px',
                              height: '30px',
                              backgroundColor: '#df4634',
                              position: 'relative',
                            }}
                          />
                        );
                      }

                      // Se showResults è true, coloriamo la cella corretta/errata
                      let bgColor = '#fff';
                      if (showResults && cellStatus[rIndex][cIndex] === 'correct') {
                        bgColor = '#C8E6C9'; // verde chiaro
                      } else if (showResults && cellStatus[rIndex][cIndex] === 'wrong') {
                        bgColor = '#FFCDD2'; // rosso chiaro
                      }

                      const displayValue = cellValue === '-' ? '' : cellValue;

                      // Verifico se c’è un numero da mostrare
                      const cellNumber = cellNumbers[`${rIndex}-${cIndex}`];

                      return (
                        <td
                          key={cIndex}
                          style={{
                            border: '1px solid #333',
                            width: '30px',
                            height: '30px',
                            textAlign: 'center',
                            verticalAlign: 'middle',
                            backgroundColor: bgColor,
                            position: 'relative',
                          }}
                        >
                          {/* Numero in alto a sinistra */}
                          {cellNumber && (
                            <div
                              style={{
                                position: 'absolute',
                                top: '2px',
                                left: '2px',
                                fontSize: '0.6rem',
                                fontWeight: 'bold',
                                color: '#DF4634',
                              }}
                            >
                              {cellNumber}
                            </div>
                          )}

                          {/* Lettera che l'utente ha inserito */}
                          {cellValue !== '-' ? cellValue : ''}

                          {/* Input di testo */}
                          <input
                            type="text"
                            maxLength={1}
                            value={displayValue}
                            onChange={(e) => handleCellChange(rIndex, cIndex, e)}
                            style={{
                              width: '100%',
                              height: '100%',
                              border: 'none',
                              textAlign: 'center',
                              fontWeight: 'bold',
                              fontSize: '1rem',
                              backgroundColor: bgColor,
                              outline: 'none',
                            }}
                            disabled={showResults}
                          />
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Box>

          {/* 
             Se NON abbiamo inviato risposte: 
             Mostra "Invia", "Condividi", "Torna ai giochi"
             E se isOwner === true, anche "Punteggi Salvati"
          */}
          {!showResults ? (
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                justifyContent: 'center',
                marginTop: '2rem'
              }}
            >
              <Button variant="contained" onClick={handleCheckAnswers}>
                Invia
              </Button>

              {isOwner && (
                <Button
                  variant="outlined"
                  onClick={() => navigate(`/games/${gameId}/scores`)}
                >
                  Punteggi Salvati
                </Button>
              )}

              <Button variant="outlined" onClick={handleShare}>
                Condividi
              </Button>
              <Button variant="outlined" onClick={handleReturnToGames}>
                Torna ai giochi
              </Button>
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
              <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
                Celle corrette: {score}
              </Typography>
              <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                <Button variant="contained" color="error" onClick={handleReplay}>
                  Rigioca
                </Button>
                <Button variant="contained" onClick={handleReturnToGames}>
                  Torna ai giochi
                </Button>
              </Box>

              {/* Mostra la soluzione */}
              {!showSolution && (
                <Box sx={{ marginTop: '1rem' }}>
                  <Button variant="outlined" color="secondary" onClick={handleShowSolution}>
                    Soluzione
                  </Button>
                </Box>
              )}

              {/* Salva Score */}
              <Box sx={{ marginTop: '2rem' }}>
                <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
                  Metti il tuo nome per salvare:
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                  <TextField
                    value={studentName}
                    onChange={(e) => setStudentName(e.target.value)}
                    label="Student Name"
                    variant="outlined"
                  />
                  <Button variant="contained" color="success" onClick={handleSaveScore}>
                    Salva Score
                  </Button>
                </Box>
              </Box>
            </Box>
          )}

          {/* Legenda (se presente) */}
          {legend && (
            <Box sx={{ marginTop: '2rem' }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 700, marginBottom: '0.5rem' }}>
                Leggenda:
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  whiteSpace: 'pre-wrap',
                  backgroundColor: '#f7f7f7',
                  padding: '0.5rem',
                  borderRadius: '0.25rem',
                  border: '1px solid #ccc'
                }}
              >
                {legend}
              </Typography>
            </Box>
          )}

          {/* Mostra la griglia soluzione, se richiesto */}
          {showSolution && (
            <Box sx={{ marginTop: '2rem' }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 700, marginBottom: '0.5rem' }}>
                Complete Solution:
              </Typography>
              <Box component="table" sx={{ borderCollapse: 'collapse' }}>
                <tbody>
                  {solution.map((row, rIndex) => (
                    <tr key={rIndex}>
                      {row.map((cell, cIndex) => {
                        if (cell === '-') {
                          return (
                            <td
                              key={cIndex}
                              style={{
                                border: '1px solid #999',
                                width: '30px',
                                height: '30px',
                                backgroundColor: '#df4634',
                                position: 'relative',
                              }}
                            />
                          );
                        }
                        return (
                          <td
                            key={cIndex}
                            style={{
                              border: '1px solid #333',
                              width: '30px',
                              height: '30px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: '#fff',
                              position: 'relative',
                            }}
                          >
                            <strong style={{ fontSize: '1rem' }}>
                              {cell.toUpperCase()}
                            </strong>
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </Box>

              {words && (
                <Box sx={{ marginTop: '2rem' }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 700, marginBottom: '0.5rem' }}>
                    Word List:
                  </Typography>
                  {words.map((w, i) => (
                    <Typography key={i} variant="body2" sx={{ marginBottom: '0.25rem' }}>
                      <strong>{w.number}. {w.word.toUpperCase()}</strong> — {w.clue}
                    </Typography>
                  ))}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Footer />
    </>
  );
};

export default CrosswordPage;
