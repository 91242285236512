// File: src/components/games/FillTheBoxPage.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Grid,
  Chip,
  Tooltip,
  TextField,
} from '@mui/material';

import Header from '../Header';
import Footer from '../Footer';
import MobileMenu from '../MobileMenu';
import FloatingMenu from '../FloatingMenu';
import apiClient from '../../api/apiClient';

// Lo stesso import che usi in HomePage (se si chiama jwtDecode e non jwt_decode)
import { jwtDecode } from 'jwt-decode';

const shuffleArray = (array) => {
  return array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
};

const FillTheBoxCard = ({
  exerciseData,
  userAnswer,
  onSelect,
  showCorrectness = false,
}) => {
  const handleSelect = (optionId) => {
    onSelect(exerciseData.id, optionId);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        marginBottom: '1.5rem',
        borderRadius: '1rem',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
        backgroundColor: '#ffffff',
        padding: '1.5rem',
      }}
    >
      <CardContent>
        <Typography variant="h6" sx={{ marginBottom: '1rem', fontWeight: 600 }}>
          {exerciseData.sentence}
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.75rem' }}>
          {exerciseData.options.map((option) => {
            let chipColor = 'default';
            let variant = 'outlined';

            if (showCorrectness) {
              if (option.is_correct) {
                chipColor = 'success';
              } else if (userAnswer === option.id) {
                chipColor = 'error';
              }
            } else {
              if (userAnswer === option.id) {
                chipColor = 'primary';
                variant = 'filled';
              }
            }

            return (
              <Tooltip key={option.id} title={option.text} arrow>
                <Chip
                  label={option.text}
                  clickable
                  onClick={() => handleSelect(option.id)}
                  color={chipColor}
                  variant={variant}
                  sx={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0px 4px 8px rgba(0,0,0,0.15)',
                    },
                    ...(userAnswer === option.id && !showCorrectness && {
                      backgroundColor: '#DF4634',
                      color: '#FFFFFF',
                      '&:hover': {
                        backgroundColor: '#DF4634',
                      },
                    }),
                    ...(showCorrectness && option.is_correct && {
                      backgroundColor: '#4CAF50',
                      color: '#FFFFFF',
                    }),
                    ...(showCorrectness &&
                      !option.is_correct &&
                      userAnswer === option.id && {
                        backgroundColor: '#B71C1C',
                        color: '#FFFFFF',
                      }),
                  }}
                />
              </Tooltip>
            );
          })}
        </Box>
      </CardContent>
    </Card>
  );
};

const FillTheBoxSummary = ({ exercises, userAnswers, onReplay, onReturn }) => {
  let correctCount = 0;
  const exercisesWithResults = exercises.map((ex) => {
    const userAnswer = userAnswers[ex.id];
    const correctOption = ex.options.find((opt) => opt.is_correct);
    const isCorrect = userAnswer === correctOption?.id;
    if (isCorrect) correctCount++;
    return { ...ex, userAnswer, correctOption, isCorrect };
  });

  return (
    <Box sx={{ marginTop: '2rem' }}>
      <Typography
        variant="h5"
        sx={{ marginBottom: '1rem', textAlign: 'center', fontWeight: 700 }}
      >
        Riepilogo Fill The Box
      </Typography>

      <Typography
        variant="h6"
        sx={{ textAlign: 'center', marginBottom: '1.5rem', color: '#333' }}
      >
        Score: {correctCount} / {exercises.length}
      </Typography>

      <Grid container spacing={2}>
        {exercisesWithResults.map((ex, index) => (
          <Grid item xs={12} key={ex.id}>
            <Card
              variant="outlined"
              sx={{
                borderRadius: '1rem',
                boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                backgroundColor: '#ffffff',
                padding: '1.5rem',
              }}
            >
              <CardContent>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {index + 1}. {ex.sentence}
                </Typography>
                <Box sx={{ marginTop: '1rem' }}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: ex.isCorrect ? '#4CAF50' : '#B71C1C',
                      fontWeight: 500,
                    }}
                  >
                    Risposta:{' '}
                    {ex.userAnswer
                      ? ex.options.find((opt) => opt.id === ex.userAnswer)?.text
                      : 'Non Risposto'}
                  </Typography>
                  {!ex.isCorrect && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#4CAF50',
                        marginTop: '0.25rem',
                      }}
                    >
                      Corretta: {ex.correctOption?.text}
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box
        sx={{
          textAlign: 'center',
          marginTop: '2rem',
          display: 'flex',
          justifyContent: 'center',
          gap: '1rem',
        }}
      >
        <Button
          variant="contained"
          color="error"
          sx={{
            borderRadius: '1.875rem',
            textTransform: 'none',
            fontWeight: 500,
            backgroundColor: '#DF4634',
            '&:hover': {
              backgroundColor: '#C0392B',
            },
          }}
          onClick={() => onReplay(true)}
        >
          Rigioca
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            borderRadius: '1.875rem',
            textTransform: 'none',
            fontWeight: 500,
            backgroundColor: '#1976d2',
            '&:hover': {
              backgroundColor: '#1565C0',
            },
          }}
          onClick={onReturn}
        >
          Torna ai Giochi
        </Button>
      </Box>
    </Box>
  );
};

const FillTheBoxPage = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();

  // --------------------------------------
  // UI & Authentication States
  // --------------------------------------
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('access'));

  const [loading, setLoading] = useState(true);
  const [gameData, setGameData] = useState(null);

  // Verifica se l’utente è owner
  const [isOwner, setIsOwner] = useState(false);

  // --------------------------------------
  // Fill the Box logic
  // --------------------------------------
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [showSummary, setShowSummary] = useState(false);

  // Nome studente
  const [studentName, setStudentName] = useState('');

  // --------------------------------------
  // Snackbar
  // --------------------------------------
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowFloatingMenu(true);
      } else {
        setShowFloatingMenu(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // --------------------------------------
  // Fetch game data
  // --------------------------------------
  const fetchGame = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get(`/api/interactive-games/games/${gameId}/`);
      if (response.data?.data?.fill_the_box) {
        // Mischia le opzioni di ogni esercizio
        const fillTheBoxShuffled = response.data.data.fill_the_box.map((exercise) => ({
          ...exercise,
          options: shuffleArray(exercise.options),
        }));
        response.data.data.fill_the_box = fillTheBoxShuffled;
      }
      setGameData(response.data);
    } catch (err) {
      console.error('Errore nel caricamento del gioco Fill The Box:', err);
      setSnackbarMessage('Impossibile caricare il gioco.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);

  // --------------------------------------
  // Verifica se l'utente loggato è l’owner
  // --------------------------------------
  useEffect(() => {
    const token = localStorage.getItem('access');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentUserId = decodedToken.user_id;

        if (gameData && gameData.user && currentUserId) {
          if (parseInt(gameData.user) === parseInt(currentUserId)) {
            setIsOwner(true);
            console.log('SONO owner');
          } else {
            setIsOwner(false);
            console.log('NON SONO owner');
          }
        }
      } catch (error) {
        console.error('Errore nella decodifica del token:', error);
      }
    }
  }, [gameData]);

  // Selezione risposta
  const handleSelectAnswer = (exerciseId, optionId) => {
    setUserAnswers((prev) => ({
      ...prev,
      [exerciseId]: optionId,
    }));
  };

  // Navigazione
  const handleNextExercise = () => {
    if (!gameData?.data?.fill_the_box) return;
    if (currentExerciseIndex < gameData.data.fill_the_box.length - 1) {
      setCurrentExerciseIndex((prev) => prev + 1);
    }
  };
  const handlePrevExercise = () => {
    if (currentExerciseIndex > 0) {
      setCurrentExerciseIndex((prev) => prev - 1);
    }
  };

  // Verifica Risposte
  const handleCheckAnswers = () => {
    if (!gameData?.data?.fill_the_box) return;
    setShowSummary(true);

    let correctCount = 0;
    gameData.data.fill_the_box.forEach((exercise) => {
      const userAnswer = userAnswers[exercise.id];
      const correctOpt = exercise.options.find((opt) => opt.is_correct);
      if (userAnswer && correctOpt && userAnswer === correctOpt.id) {
        correctCount++;
      }
    });

    setSnackbarMessage(
      `Risposte corrette: ${correctCount}/${gameData.data.fill_the_box.length}`
    );
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };

  // Replay
  const handleReplay = (resetAnswers = true) => {
    if (resetAnswers) {
      setUserAnswers({});
    }
    setShowSummary(false);
    setCurrentExerciseIndex(0);
  };

  // Torna ai Giochi
  const handleReturnToGames = () => {
    navigate('/chat/games');
  };

  // Chiudi Snackbar
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  // Calcolo punteggio
  const computeScoreData = () => {
    let correctCount = 0;
    const details = [];
    if (!gameData?.data?.fill_the_box) return { score: 0, details: [] };

    gameData.data.fill_the_box.forEach((exercise) => {
      const userAnswer = userAnswers[exercise.id];
      const correctOpt = exercise.options.find((opt) => opt.is_correct);
      const isCorrect = userAnswer === correctOpt?.id;
      if (isCorrect) correctCount++;
      details.push({
        exerciseId: exercise.id,
        userAnswer,
        correctAnswer: correctOpt?.id,
        correct: isCorrect,
      });
    });
    return { score: correctCount, details };
  };

  // Salvataggio punteggio
  const handleSaveScore = async () => {
    if (!studentName.trim()) {
      setSnackbarMessage('Inserisci il tuo nome!');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    const { score, details } = computeScoreData();
    const payload = {
      student_name: studentName,
      score,
      details,
    };

    try {
      await apiClient.post(`/api/interactive-games/games/${gameId}/scores/`, payload);
      setSnackbarMessage('Punteggio salvato con successo!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setStudentName('');
    } catch (error) {
      console.error('Errore nel salvataggio dello score:', error);
      setSnackbarMessage('Errore nel salvataggio del punteggio.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Condividi
  const handleShare = () => {
    const shareUrl = window.location.href;
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        setSnackbarMessage('Link copiato negli appunti!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      })
      .catch(() => {
        setSnackbarMessage('Impossibile copiare il link.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  // --------------------------------------------------
  //  RENDER DELLA PAGINA
  // --------------------------------------------------
  if (loading) {
    return (
      <>
        <Header
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
          backgroundColor="#DF4634"
          textColor="#FFFFFF"
          logoImage="/logo/logobianco.svg"
        />
        {mobileMenuOpen && (
          <MobileMenu
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            setMobileMenuOpen={setMobileMenuOpen}
          />
        )}
        {showFloatingMenu && (
          <FloatingMenu
            isLoggedIn={isLoggedIn}
            handleLogout={() => {
              localStorage.removeItem('access');
              localStorage.removeItem('refresh');
              setIsLoggedIn(false);
            }}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '2rem',
            backgroundColor: '#f8e94d',
            marginBottom: '2rem',
            position: 'relative',
          }}
        >
          <Box
            component="img"
            src="/fillthebox/cloudleft.png"
            alt="Cloud Left"
            sx={{
              width: '150px',
              height: 'auto',
              position: 'absolute',
              top: '10px',
              left: '10px',
              display: { xs: 'none', sm: 'block' },
            }}
          />
          <Box
            component="img"
            src="/fillthebox/cloudright.png"
            alt="Cloud Right"
            sx={{
              width: '150px',
              height: 'auto',
              position: 'absolute',
              top: '10px',
              right: '10px',
              display: { xs: 'none', sm: 'block' },
            }}
          />
          <Box
            component="img"
            src="/fillthebox/three.png"
            alt="Three"
            sx={{
              width: '160px',
              height: 'auto',
              position: 'absolute',
              left: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              display: { xs: 'none', sm: 'block' },
            }}
          />
          <Box
            component="img"
            src="/fillthebox/castel.png"
            alt="Castel"
            sx={{
              width: '160px',
              height: 'auto',
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              display: { xs: 'none', sm: 'block' },
            }}
          />
          <CircularProgress sx={{ color: '#DF4634' }} />
        </Box>
        <Footer />
      </>
    );
  }

  // Se il gioco non è disponibile
  if (!gameData?.data?.fill_the_box) {
    return (
      <>
        <Header
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
          backgroundColor="#DF4634"
          textColor="#FFFFFF"
          logoImage="/logo/logobianco.svg"
        />
        {mobileMenuOpen && (
          <MobileMenu
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            setMobileMenuOpen={setMobileMenuOpen}
          />
        )}
        {showFloatingMenu && (
          <FloatingMenu
            isLoggedIn={isLoggedIn}
            handleLogout={() => {
              localStorage.removeItem('access');
              localStorage.removeItem('refresh');
              setIsLoggedIn(false);
            }}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '2rem',
            backgroundColor: '#f8e94d',
            marginBottom: '2rem',
            position: 'relative',
          }}
        >
          <Box
            component="img"
            src="/fillthebox/cloudleft.png"
            alt="Cloud Left"
            sx={{
              width: '150px',
              height: 'auto',
              position: 'absolute',
              top: '10px',
              left: '10px',
              display: { xs: 'none', sm: 'block' },
            }}
          />
          <Box
            component="img"
            src="/fillthebox/cloudright.png"
            alt="Cloud Right"
            sx={{
              width: '150px',
              height: 'auto',
              position: 'absolute',
              top: '10px',
              right: '10px',
              display: { xs: 'none', sm: 'block' },
            }}
          />
          <Box
            component="img"
            src="/fillthebox/three.png"
            alt="Three"
            sx={{
              width: '160px',
              height: 'auto',
              position: 'absolute',
              left: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              display: { xs: 'none', sm: 'block' },
            }}
          />
          <Box
            component="img"
            src="/fillthebox/castel.png"
            alt="Castel"
            sx={{
              width: '160px',
              height: 'auto',
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              display: { xs: 'none', sm: 'block' },
            }}
          />
          <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
            Fill The Box non disponibile
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#DF4634',
              color: '#FFF',
              borderRadius: '1.875rem',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#E57373',
              },
            }}
            onClick={handleReturnToGames}
          >
            Torna ai Giochi
          </Button>
        </Box>
        <Footer />
      </>
    );
  }

  // GIOCO DISPONIBILE
  const { title } = gameData;
  const fillExercises = gameData.data.fill_the_box || [];
  const totalExercises = fillExercises.length;
  const currentExerciseData = fillExercises[currentExerciseIndex];

  return (
    <>
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        backgroundColor="#DF4634"
        textColor="#FFFFFF"
        logoImage="/logo/logobianco.svg"
      />
      {mobileMenuOpen && (
        <MobileMenu
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          setMobileMenuOpen={setMobileMenuOpen}
        />
      )}
      {showFloatingMenu && (
        <FloatingMenu
          isLoggedIn={isLoggedIn}
          handleLogout={() => {
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            setIsLoggedIn(false);
          }}
        />
      )}

      {/* CONTENITORE PRINCIPALE */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#f8e94d',
          padding: '2rem',
          marginBottom: '2rem',
          position: 'relative',
        }}
      >
        <Box
          component="img"
          src="/fillthebox/cloudleft.png"
          alt="Cloud Left"
          sx={{
            width: '150px',
            height: 'auto',
            position: 'absolute',
            top: '10px',
            left: '10px',
            display: { xs: 'none', sm: 'block' },
          }}
        />
        <Box
          component="img"
          src="/fillthebox/cloudright.png"
          alt="Cloud Right"
          sx={{
            width: '150px',
            height: 'auto',
            position: 'absolute',
            top: '10px',
            right: '10px',
            display: { xs: 'none', sm: 'block' },
          }}
        />
        <Box
          component="img"
          src="/fillthebox/three.png"
          alt="Three"
          sx={{
            width: '160px',
            height: 'auto',
            position: 'absolute',
            left: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            display: { xs: 'none', sm: 'block' },
          }}
        />
        <Box
          component="img"
          src="/fillthebox/castel.png"
          alt="Castel"
          sx={{
            width: '160px',
            height: 'auto',
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            display: { xs: 'none', sm: 'block' },
          }}
        />

        {/* WRAPPER CHE LIMITA LA LARGHEZZA DEL GIOCO */}
        <Box
          sx={{
            width: '100%',
            maxWidth: '900px',
            margin: '0 auto',
            position: 'relative',
            backgroundColor: '#fff',
            padding: '2rem',
            borderRadius: '1rem',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          }}
        >
          {!showSummary ? (
            <>
              <Typography
                variant="h5"
                sx={{
                  marginBottom: '2rem',
                  textAlign: 'center',
                  fontWeight: 700,
                  color: '#333',
                }}
              >
                {title || 'Fill The Box'}
              </Typography>

              <FillTheBoxCard
                exerciseData={currentExerciseData}
                userAnswer={userAnswers[currentExerciseData.id]}
                onSelect={handleSelectAnswer}
              />

              {/* NAVIGAZIONE */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '2rem',
                }}
              >
                {currentExerciseIndex > 0 ? (
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: 'none',
                      borderColor: '#DF4634',
                      color: '#DF4634',
                      '&:hover': {
                        backgroundColor: 'rgba(223,70,52,0.06)',
                        borderColor: '#DF4634',
                      },
                    }}
                    onClick={handlePrevExercise}
                  >
                    Indietro
                  </Button>
                ) : (
                  <Box />
                )}

                {currentExerciseIndex < totalExercises - 1 ? (
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: 'none',
                      backgroundColor: '#DF4634',
                      '&:hover': {
                        backgroundColor: '#E57373',
                      },
                    }}
                    onClick={handleNextExercise}
                    disabled={!userAnswers[currentExerciseData.id]}
                  >
                    Avanti
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: 'none',
                      backgroundColor: '#DF4634',
                      '&:hover': {
                        backgroundColor: '#E57373',
                      },
                    }}
                    onClick={handleCheckAnswers}
                    disabled={!userAnswers[currentExerciseData.id]}
                  >
                    Verifica Risposte
                  </Button>
                )}
              </Box>

              {/* Pulsanti aggiuntivi */}
              <Box
                sx={{
                  marginTop: '2rem',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '1rem',
                }}
              >
                {/* Se è owner, mostriamo il pulsante "Punteggi Salvati" */}
                {isOwner && (
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: 'none',
                      borderColor: '#1976d2',
                      color: '#1976d2',
                      borderRadius: '1.875rem',
                      fontWeight: 500,
                      '&:hover': {
                        borderColor: '#115293',
                        backgroundColor: 'rgba(25,118,210,0.06)',
                      },
                    }}
                    onClick={() => navigate(`/games/${gameId}/scores`)}
                  >
                    Punteggi Salvati
                  </Button>
                )}

                <Button
                  variant="outlined"
                  sx={{
                    textTransform: 'none',
                    borderColor: '#1976d2',
                    color: '#1976d2',
                    borderRadius: '1.875rem',
                    fontWeight: 500,
                    '&:hover': {
                      borderColor: '#115293',
                      backgroundColor: 'rgba(25,118,210,0.06)',
                    },
                  }}
                  onClick={handleShare}
                >
                  Condividi
                </Button>

                <Button
                  variant="outlined"
                  sx={{
                    textTransform: 'none',
                    borderColor: '#DF4634',
                    color: '#DF4634',
                    borderRadius: '1.875rem',
                    fontWeight: 500,
                    '&:hover': {
                      borderColor: '#C0392B',
                      backgroundColor: 'rgba(223,70,52,0.06)',
                    },
                  }}
                  onClick={handleReturnToGames}
                >
                  Torna ai Giochi
                </Button>
              </Box>
            </>
          ) : (
            <>
              <FillTheBoxSummary
                exercises={fillExercises}
                userAnswers={userAnswers}
                onReplay={handleReplay}
                onReturn={handleReturnToGames}
              />

              {/* FORM SALVATAGGIO PUNTEGGIO */}
              <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
                <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
                  Inserisci il tuo nome per salvare il punteggio:
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '1rem',
                  }}
                >
                  <TextField
                    value={studentName}
                    onChange={(e) => setStudentName(e.target.value)}
                    label="Nome Studente"
                    variant="outlined"
                  />
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ textTransform: 'none' }}
                    onClick={handleSaveScore}
                  >
                    Salva Score
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>

      {/* SNACKBAR */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Footer />
    </>
  );
};

export default FillTheBoxPage;
