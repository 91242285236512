// File: src/components/chat/ChatMessages.jsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  useMediaQuery,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Chip,
  Tooltip,
  Snackbar,
  Alert,
  keyframes,
  TextField
} from '@mui/material';

import ReactMarkdown from 'react-markdown'; 
// ❌ Niente import statico di 'remark-gfm', lo carichiamo dinamicamente se possibile

// Strumenti per generare Word/PDF
import { Document, Packer, Paragraph, TextRun, HeadingLevel } from 'docx';
import { saveAs } from 'file-saver';
import { marked } from 'marked';
import he from 'he';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';

import ActionButtons from './ActionButtons';
import MediaTypeDialog from './MediaTypeDialog';
import MediaDisplay from './MediaDisplay';
import apiClient from '../../api/apiClient';
import gameTypeIds from '../../config/gameTypeIds'; // mappa agent -> UUID

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// -----------------------------------------
// Animazione pallini "typing"
const typing = keyframes`
  0% { opacity: 0.2; }
  20% { opacity: 1; }
  100% { opacity: 0.2; }
`;

/**
 * Funzione che testa il supporto a:
 * 1) Lookbehind (?<=)
 * 2) Unicode property escapes (\p{...})
 * Se lancia un errore => il browser NON supporta queste feature
 */
function supportsModernRegexFeatures() {
  try {
    // Test lookbehind
    new RegExp('(?<=^|\\s)test', 'u');
    // Test property escapes (quelle usate da remark-gfm)
    new RegExp('\\p{P}+', 'u');

    return true; // Se nessuna eccezione => compatibile
  } catch {
    return false;
  }
}

// Spinner di caricamento (quando loading)
const LoadingIndicator = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
    <CircularProgress />
  </Box>
);

/* ===========================================================
   MINI-COMPONENTI PER I GIOCHI (QUIZ, FILLTHEBOX, SELECTGROUP,
   MATCHPAIRS, REARRANGE, CROSSWORD)
   =========================================================== */

// ---------------- FILLTHEBOX MINI ----------------
const FillTheBoxMini = ({ fillData }) => {
  // fillData = { fill_the_box: [ { id, sentence, options: [...] }, ... ] }
  const [selectedAnswers, setSelectedAnswers] = useState({});

  const handleSelect = (exerciseId, optionId) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [exerciseId]: optionId,
    }));
  };

  if (!fillData?.fill_the_box?.length) {
    return <Typography>Formato FillTheBox non valido.</Typography>;
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      {fillData.fill_the_box.map((exercise) => (
        <Card
          key={exercise.id}
          variant="outlined"
          sx={{
            marginBottom: '1.5rem',
            borderRadius: '1rem',
            boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
            backgroundColor: '#ffffff',
            padding: '1rem',
          }}
        >
          <CardContent>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: '0.75rem' }}>
              {exercise.sentence}
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
              {exercise.options.map((option) => {
                const isSelected = selectedAnswers[exercise.id] === option.id;
                return (
                  <Tooltip key={option.id} title={option.text} arrow>
                    <Chip
                      label={option.text}
                      clickable
                      onClick={() => handleSelect(exercise.id, option.id)}
                      sx={{
                        fontWeight: 500,
                        cursor: 'pointer',
                        transition: 'all 0.2s ease-in-out',
                        ...(isSelected
                          ? {
                              backgroundColor: '#DF4634',
                              color: '#ffffff',
                              '&:hover': {
                                backgroundColor: '#E57373',
                              },
                            }
                          : {
                              '&:hover': {
                                transform: 'scale(1.05)',
                                boxShadow: '0px 4px 8px rgba(0,0,0,0.15)',
                              },
                            }),
                      }}
                    />
                  </Tooltip>
                );
              })}
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

// ---------------- QUIZ MINI ----------------
const QuizMini = ({ quizData }) => {
  // quizData = {
  //   quiz: [
  //     { id, type, question, options: [...] },
  //     ...
  //   ]
  // }
  const [answers, setAnswers] = useState({});

  const handleSelectMultipleChoice = (questionId, optionId) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: optionId,
    }));
  };

  const handleChangeOpenEnded = (questionId, value) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: value,
    }));
  };

  if (!quizData?.quiz?.length) {
    return <Typography>Formato Quiz non valido.</Typography>;
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      {quizData.quiz.map((q) => {
        if (q.type === 'multiple_choice') {
          const selectedOption = answers[q.id];
          return (
            <Card
              key={q.id}
              variant="outlined"
              sx={{
                marginBottom: '1.5rem',
                borderRadius: '1rem',
                boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                backgroundColor: '#ffffff',
                padding: '1rem',
              }}
            >
              <CardContent>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: '0.75rem' }}>
                  {q.question}
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', justifyContent: 'center' }}>
                  {q.options.map((option) => {
                    const isSelected = selectedOption === option.id;
                    return (
                      <Tooltip key={option.id} title={option.text} arrow>
                        <Chip
                          label={option.text}
                          clickable
                          onClick={() => handleSelectMultipleChoice(q.id, option.id)}
                          sx={{
                            fontWeight: 500,
                            cursor: 'pointer',
                            transition: 'all 0.2s ease-in-out',
                            ...(isSelected
                              ? {
                                  backgroundColor: '#DF4634',
                                  color: '#ffffff',
                                  '&:hover': {
                                    backgroundColor: '#E57373',
                                  },
                                }
                              : {
                                  '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0px 4px 8px rgba(0,0,0,0.15)',
                                  },
                                }),
                          }}
                        />
                      </Tooltip>
                    );
                  })}
                </Box>
              </CardContent>
            </Card>
          );
        } else if (q.type === 'open_ended') {
          const openEndedAnswer = answers[q.id] || '';
          return (
            <Card
              key={q.id}
              variant="outlined"
              sx={{
                marginBottom: '1.5rem',
                borderRadius: '1rem',
                boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                backgroundColor: '#ffffff',
                padding: '1rem',
              }}
            >
              <CardContent>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: '0.75rem' }}>
                  {q.question}
                </Typography>
                <TextField
                  value={openEndedAnswer}
                  onChange={(e) => handleChangeOpenEnded(q.id, e.target.value)}
                  placeholder="Scrivi la tua risposta qui..."
                  multiline
                  rows={3}
                  fullWidth
                  sx={{ marginTop: '0.5rem' }}
                />
              </CardContent>
            </Card>
          );
        } else {
          return (
            <Typography key={q.id} sx={{ marginBottom: '1rem' }}>
              Tipo di domanda non riconosciuto.
            </Typography>
          );
        }
      })}
    </Box>
  );
};

// ---------------- SELECTGROUP MINI ----------------
const SelectGroupMini = ({ data }) => {
  if (!data?.selectgroup?.length) {
    return <Typography>Formato SelectGroup non valido.</Typography>;
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: '0.75rem' }}>
        Trascina o assegna ogni parola al gruppo corretto (versione semplificata):
      </Typography>
      {data.selectgroup.map((group) => (
        <Card
          key={group.id}
          variant="outlined"
          sx={{
            marginBottom: '1.5rem',
            borderRadius: '1rem',
            boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
            backgroundColor: '#ffffff',
            padding: '1rem',
          }}
        >
          <CardContent>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: '0.5rem' }}>
              {group.topic}
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
              {group.words.map((wd, i) => (
                <Chip
                  key={`${wd}-${i}`}
                  label={wd}
                  sx={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0px 4px 8px rgba(0,0,0,0.15)',
                    },
                  }}
                />
              ))}
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

// ---------------- MATCHPAIRS MINI ----------------
const MatchPairsMini = ({ data }) => {
  if (!data?.matchpairs?.length) {
    return <Typography>Formato MatchPairs non valido.</Typography>;
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: '0.75rem' }}>
        Abbina ogni concetto di sinistra con quello corrispondente a destra (versione semplificata):
      </Typography>
      {data.matchpairs.map((item) => (
        <Card
          key={item.id}
          variant="outlined"
          sx={{
            marginBottom: '1.5rem',
            borderRadius: '1rem',
            boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
            backgroundColor: '#ffffff',
            padding: '1rem',
          }}
        >
          <CardContent>
            <Typography variant="subtitle2" sx={{ fontWeight: 600, marginBottom: '0.5rem' }}>
              {item.topic}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
              {item.pairs.map((pair, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    border: '1px solid #e0e0e0',
                    padding: '0.5rem',
                    borderRadius: '0.5rem'
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {pair.left}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#999' }}>
                    ⟶
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {pair.right}
                  </Typography>
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

// ---------------- REARRANGE MINI ----------------
const RearrangeMini = ({ data }) => {
  if (!data?.rearrange?.length) {
    return <Typography>Formato Rearrange non valido.</Typography>;
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: '0.75rem' }}>
        Trascina o riordina le parole per formare la frase corretta (versione semplificata):
      </Typography>
      {data.rearrange.map((item) => (
        <Card
          key={item.id}
          variant="outlined"
          sx={{
            marginBottom: '1.5rem',
            borderRadius: '1rem',
            boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
            backgroundColor: '#ffffff',
            padding: '1rem',
          }}
        >
          <CardContent>
            <Typography variant="subtitle2" sx={{ fontWeight: 600, marginBottom: '0.5rem' }}>
              {item.topic}
            </Typography>
            {/* Parole mescolate */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', marginBottom: '0.5rem' }}>
              {item.words_shuffled.map((word, idx) => (
                <Chip
                  key={idx}
                  label={word}
                  sx={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0px 4px 8px rgba(0,0,0,0.15)',
                    },
                  }}
                />
              ))}
            </Box>
            {/* Frase finale corretta */}
            <Typography variant="body1" sx={{ color: '#999' }}>
              Frase corretta: {item.final_sentence}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

// ---------------- CROSSWORD MINI ----------------
const CrosswordMini = ({ data }) => {
  if (!data?.crossword?.grid || !data?.crossword?.words) {
    return <Typography>Formato Crossword non valido.</Typography>;
  }

  const { grid, words, legend } = data.crossword;
  const cellNumbers = {};

  words.forEach((w) => {
    const rowIndex = w.row - 1;
    const colIndex = w.col - 1;
    cellNumbers[`${rowIndex}-${colIndex}`] = w.number;
  });

  return (
    <Box sx={{ marginTop: '1rem' }}>
      <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
        {`Cruciverba ${grid[0].length} x ${grid.length}`}
      </Typography>

      <Box component="table" sx={{ borderCollapse: 'collapse', marginBottom: '1rem' }}>
        <tbody>
          {grid.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, colIndex) => {
                const cellNumber = cellNumbers[`${rowIndex}-${colIndex}`];
                return (
                  <td
                    key={colIndex}
                    style={{
                      border: '1px solid #333',
                      width: '30px',
                      height: '30px',
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      backgroundColor: cell === '-' ? '#666666' : '#f9f9f9',
                      color: '#000',
                      fontWeight: 'bold',
                      position: 'relative',
                    }}
                  >
                    {cell !== '-' ? cell : ''}
                    {cellNumber && (
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 2,
                          fontSize: '0.7rem',
                          fontWeight: 'bold',
                          color: '#DF4634',
                        }}
                      >
                        {cellNumber}
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Box>

      <Typography variant="subtitle2" sx={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>
        Definizioni:
      </Typography>
      <Typography
        variant="body2"
        sx={{
          whiteSpace: 'pre-wrap',
          backgroundColor: '#f7f7f7',
          padding: '0.5rem',
          borderRadius: '0.25rem',
          border: '1px solid #ccc',
          marginBottom: '1rem'
        }}
      >
        {legend}
      </Typography>
    </Box>
  );
};

/* ===========================================================
   FUNZIONI DI RICONOSCIMENTO E PARSER DEI MESSAGGI
   =========================================================== */
const isQuizMessage = (message) => {
  if (message.sender !== 'assistant') return false;
  try {
    const parsed = JSON.parse(message.text);
    return parsed.quiz && Array.isArray(parsed.quiz);
  } catch {
    return false;
  }
};

const isFillTheBoxMessage = (message) => {
  if (message.sender !== 'assistant') return false;
  try {
    const parsed = JSON.parse(message.text);
    return parsed.fill_the_box && Array.isArray(parsed.fill_the_box);
  } catch {
    return false;
  }
};

const isSelectGroupMessage = (message) => {
  if (message.sender !== 'assistant') return false;
  try {
    const parsed = JSON.parse(message.text);
    return parsed.selectgroup && Array.isArray(parsed.selectgroup);
  } catch {
    return false;
  }
};

const isMatchPairsMessage = (message) => {
  if (message.sender !== 'assistant') return false;
  try {
    const parsed = JSON.parse(message.text);
    return parsed.matchpairs && Array.isArray(parsed.matchpairs);
  } catch {
    return false;
  }
};

const isRearrangeMessage = (message) => {
  if (message.sender !== 'assistant') return false;
  try {
    const parsed = JSON.parse(message.text);
    return parsed.rearrange && Array.isArray(parsed.rearrange);
  } catch {
    return false;
  }
};

const isCrosswordMessage = (message) => {
  if (message.sender !== 'assistant') return false;
  try {
    const parsed = JSON.parse(message.text);
    return (
      parsed.crossword &&
      parsed.crossword.grid &&
      Array.isArray(parsed.crossword.grid) &&
      parsed.crossword.words
    );
  } catch {
    return false;
  }
};

// Parser
const parseQuiz = (message) => JSON.parse(message.text);
const parseFillTheBox = (message) => JSON.parse(message.text);
const parseSelectGroup = (message) => JSON.parse(message.text);
const parseMatchPairs = (message) => JSON.parse(message.text);
const parseRearrange = (message) => JSON.parse(message.text);
const parseCrossword = (message) => JSON.parse(message.text);

/* ===========================================================
   COMPONENTE PRINCIPALE: ChatMessages
   =========================================================== */
const ChatMessages = ({
  messages,
  loading,
  containerRef,
  chatTitle,
  selectedOption,
  handleMediaSearch,
  showVideo,
  agentExamples,
  onExampleClick,
}) => {
  // Controllo se siamo su schermo piccolo
  const isMobile = useMediaQuery('(max-width:600px)');

  // Verifichiamo se supporta le regex moderne
  const oldSafari = !supportsModernRegexFeatures();

  // Stato per caricare remark-gfm dinamicamente se supportato
  const [gfmPlugin, setGfmPlugin] = useState(null);

  useEffect(() => {
    // Se il browser supporta le feature, importo remark-gfm
    if (!oldSafari) {
      import('remark-gfm')
        .then((mod) => {
          setGfmPlugin(() => mod.default || mod);
        })
        .catch((err) => {
          console.error('Impossibile caricare remark-gfm dinamicamente:', err);
          setGfmPlugin(null);
        });
    }
  }, [oldSafari]);

  // Stato e funzioni per mediaDialog, snackbar, salvataggio gioco...
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [selectedMediaType, setSelectedMediaType] = useState('image');
  const [currentMessage, setCurrentMessage] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [customTitle, setCustomTitle] = useState('');

  const showSuccessSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  const openMediaDialog = (message) => {
    setCurrentMessage(message);
    setMediaDialogOpen(true);
  };
  const handleMediaSearchConfirm = (mediaType) => {
    setMediaDialogOpen(false);
    setSelectedMediaType(mediaType);
    handleMediaSearch(currentMessage, mediaType);
  };

  const handleOpenSaveDialog = () => {
    setCustomTitle('');
    setSaveDialogOpen(true);
  };
  const handleCloseSaveDialog = () => {
    setSaveDialogOpen(false);
  };
  const handleConfirmSave = () => {
    onSaveGame(currentMessage?.text, customTitle);
    setSaveDialogOpen(false);
  };

  const onSaveGame = async (messageText, userChosenTitle) => {
    try {
      const data = JSON.parse(messageText);
      const gameTypeId = gameTypeIds[selectedOption];
      if (!gameTypeId) {
        setSnackbarMessage('Tipo di gioco non supportato o non configurato.');
        setSnackbarOpen(true);
        return;
      }

      const finalTitle = userChosenTitle || data.title || 'Nuovo Gioco';
      const payload = {
        game_type_id: gameTypeId,
        title: finalTitle,
        data: data,
      };

      await apiClient.post('/api/interactive-games/games/', payload);
      showSuccessSnackbar('Gioco salvato con successo!');
    } catch (err) {
      console.error('Errore nel salvataggio del gioco:', err);
      if (err.response && err.response.data) {
        setSnackbarMessage(`Errore nel salvataggio: ${JSON.stringify(err.response.data)}`);
      } else {
        setSnackbarMessage('Errore generico nel salvataggio del gioco.');
      }
      setSnackbarOpen(true);
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showSuccessSnackbar('Testo copiato negli appunti!');
      })
      .catch((err) => {
        console.error('Errore nella copia: ', err);
        setSnackbarMessage('Errore nella copia del testo.');
        setSnackbarOpen(true);
      });
  };

  const sanitizeFileName = (name) => name.replace(/[^a-z0-9_\-]/gi, '_');
  const handleDownloadPDF = (markdownText) => {
    const safeTitle = sanitizeFileName(chatTitle || 'chat');
    const htmlContent = marked(markdownText);
    const pdfContent = htmlToPdfmake(htmlContent);
    const docDefinition = {
      content: pdfContent,
      defaultStyle: {
        font: 'Roboto',
      },
    };

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (isIOS) {
      pdfMake.createPdf(docDefinition).open();
    } else {
      pdfMake.createPdf(docDefinition).download(`${safeTitle}.pdf`);
    }
  };

  const handleDownloadWord = (markdownText) => {
    const safeTitle = sanitizeFileName(chatTitle || 'chat');

    const convertMarkdownToDocx = (markdown) => {
      const docElements = [];
      const lexer = new marked.Lexer();
      const tokens = lexer.lex(markdown);

      tokens.forEach((token) => {
        switch (token.type) {
          case 'heading':
            docElements.push(
              new Paragraph({
                text: he.decode(token.text),
                heading: HeadingLevel[`HEADING_${token.depth}`],
                spacing: { after: 200 - token.depth * 20 },
              })
            );
            break;
          case 'paragraph': {
            const runs = [];
            const inlineTokens = lexer.inlineTokens(token.text);
            inlineTokens.forEach((inlineToken) => {
              if (!inlineToken.type) return;
              const decodedText = he.decode(inlineToken.text || '');
              switch (inlineToken.type) {
                case 'text':
                  runs.push(new TextRun({ text: decodedText }));
                  break;
                case 'strong':
                  runs.push(new TextRun({ text: decodedText, bold: true }));
                  break;
                case 'em':
                  runs.push(new TextRun({ text: decodedText, italics: true }));
                  break;
                case 'codespan':
                  runs.push(new TextRun({ text: decodedText, font: 'Courier New' }));
                  break;
                default:
                  runs.push(new TextRun({ text: he.decode(inlineToken.raw || '') }));
                  break;
              }
            });
            docElements.push(
              new Paragraph({
                children: runs,
                spacing: { after: 120 },
              })
            );
            break;
          }
          case 'list': {
            token.items.forEach((item) => {
              const itemRuns = [];
              const itemInlineTokens = lexer.inlineTokens(item.text);

              itemInlineTokens.forEach((inlineToken) => {
                if (!inlineToken.type) return;
                const decodedText = he.decode(inlineToken.text || '');
                switch (inlineToken.type) {
                  case 'text':
                    itemRuns.push(new TextRun({ text: decodedText }));
                    break;
                  case 'strong':
                    itemRuns.push(new TextRun({ text: decodedText, bold: true }));
                    break;
                  case 'em':
                    itemRuns.push(new TextRun({ text: decodedText, italics: true }));
                    break;
                  case 'codespan':
                    itemRuns.push(new TextRun({ text: decodedText, font: 'Courier New' }));
                    break;
                  default:
                    itemRuns.push(new TextRun({ text: he.decode(inlineToken.raw || '') }));
                    break;
                }
              });

              docElements.push(
                new Paragraph({
                  children: itemRuns,
                  bullet: { level: item.depth || 0 },
                  spacing: { after: 120 },
                })
              );
            });
            break;
          }
          case 'space':
            docElements.push(new Paragraph({ text: '', spacing: { after: 120 } }));
            break;
          default:
            break;
        }
      });

      return docElements;
    };

    const doc = new Document({
      styles: {
        paragraphStyles: [
          {
            id: 'default',
            name: 'Default Style',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              font: 'Calibri',
              size: 22,
            },
          },
        ],
      },
      sections: [
        {
          properties: {},
          children: convertMarkdownToDocx(markdownText),
        },
      ],
    });

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (isIOS) {
      Packer.toBase64String(doc)
        .then((base64String) => {
          const url = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' + base64String;
          const link = document.createElement('a');
          link.href = url;
          link.download = `${safeTitle}.docx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.error('Errore nella generazione del documento: ', err);
        });
    } else {
      Packer.toBlob(doc)
        .then((blob) => {
          saveAs(blob, `${safeTitle}.docx`);
        })
        .catch((err) => {
          console.error('Errore nella generazione del documento: ', err);
        });
    }
  };

  // 2 esempi random
  const [randomExamples, setRandomExamples] = useState([]);

  useEffect(() => {
    if (agentExamples?.length && randomExamples.length === 0) {
      const shuffled = [...agentExamples].sort(() => 0.5 - Math.random());
      setRandomExamples(shuffled.slice(0, 2));
    }
  }, [agentExamples, randomExamples.length]);

  // Render
  return (
    <Box
      ref={containerRef}
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        padding: isMobile ? '1rem' : '2rem',
        overflowWrap: 'break-word',
        position: 'relative',
        marginTop: isMobile ? '0' : '-1.5rem',
      }}
    >
      {/* Dialog di selezione media */}
      <MediaTypeDialog
        open={mediaDialogOpen}
        onClose={() => setMediaDialogOpen(false)}
        selectedMediaType={selectedMediaType}
        onConfirm={handleMediaSearchConfirm}
      />

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Dialog "Salva Gioco" */}
      {saveDialogOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 2000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#fff',
              padding: '2rem',
              borderRadius: '1rem',
              minWidth: '300px',
            }}
          >
            <Typography sx={{ marginBottom: '1rem' }}>
              Scegli il titolo da assegnare al gioco:
            </Typography>
            <input
              type="text"
              value={customTitle}
              onChange={(e) => setCustomTitle(e.target.value)}
              style={{ width: '100%', marginBottom: '1rem' }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
              <button onClick={handleCloseSaveDialog}>Annulla</button>
              <button onClick={handleConfirmSave}>Salva</button>
            </Box>
          </Box>
        </Box>
      )}

      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          {/* Se non ci sono messaggi */}
          {messages.length === 0 && (
            <Typography sx={{ marginTop: '2rem', textAlign: 'center', color: '#666' }}>
              Non ci sono messaggi in questa chat.
            </Typography>
          )}

          {messages.map((message, index) => {
            // Se c'è un allegato immagine => estraiamo
            let imageUrl = '';
            if (message.sender === 'assistant' && message.attachments?.length) {
              const imageAttachment = message.attachments.find((att) => {
                const ext = att.file_name.split('.').pop().toLowerCase();
                return ['jpg', 'jpeg', 'png', 'gif'].includes(ext);
              });
              if (imageAttachment) {
                imageUrl = imageAttachment.file_url || '';
                if (!imageUrl && imageAttachment.file) {
                  imageUrl = URL.createObjectURL(imageAttachment.file);
                }
              }
            }

            return (
              <Box
                key={index}
                sx={{
                  marginBottom: isMobile ? '1rem' : '2.5rem',
                  position: 'relative',
                  marginTop: index === 0 ? '2rem' : '0',
                }}
              >
                {message.sender === 'assistant' && message.isTyping ? (
                  // Bolla "sta digitando..."
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                      textAlign: 'left',
                    }}
                  >
                    <Box
                      sx={{
                        width: isMobile ? '2rem' : '2.8125rem',
                        height: isMobile ? '2rem' : '2.8125rem',
                        margin: '0 0.625rem 0 0',
                        backgroundColor: '#DF4634',
                        borderRadius: '1.875rem',
                      }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0.625rem 0.9375rem',
                        borderRadius: '1.875rem',
                        boxShadow: '0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
                        backgroundColor: '#ffffff',
                        maxWidth: '83.5%',
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 400,
                        fontSize: '0.875rem',
                        lineHeight: '1rem',
                        padding: isMobile ? '1rem' : '1.5rem',
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                          sx={{
                            backgroundColor: '#DF4634',
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            margin: '0 2px',
                            animation: `${typing} 1s infinite`,
                            animationDelay: '0s',
                          }}
                        />
                        <Box
                          sx={{
                            backgroundColor: '#DF4634',
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            margin: '0 2px',
                            animation: `${typing} 1s infinite`,
                            animationDelay: '0.2s',
                          }}
                        />
                        <Box
                          sx={{
                            backgroundColor: '#DF4634',
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            margin: '0 2px',
                            animation: `${typing} 1s infinite`,
                            animationDelay: '0.4s',
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  // Messaggio "normale"
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: message.sender === 'user' ? 'row-reverse' : 'row',
                        textAlign: 'left',
                      }}
                    >
                      {/* Avatar */}
                      <Box
                        component="img"
                        src={
                          message.sender === 'user'
                            ? '/menu_icon/profile.png'
                            : '/chat_icon/minerva_robot.png'
                        }
                        sx={{
                          width: isMobile ? '2rem' : '2.8125rem',
                          height: isMobile ? '2rem' : '2.8125rem',
                          margin:
                            message.sender === 'user'
                              ? isMobile
                                ? '0 0 0 0.5rem'
                                : '0 0 0 0.625rem'
                              : isMobile
                                ? '0 0.5rem 0 0'
                                : '0 0.625rem 0 0',
                          backgroundColor: message.sender === 'user' ? '#000000' : '#DF4634',
                          borderRadius: '1.875rem',
                        }}
                      />

                      {/* Bolla messaggio */}
                      <Box
                        sx={{
                          position: 'relative',
                          paddingX: isMobile ? '2rem' : '2rem',
                          paddingY: isMobile ? '2rem' : '2rem',
                          borderRadius: '1.875rem',
                          boxShadow: '0px 0.25rem 0.25rem rgba(0,0,0,0.25)',
                          whiteSpace: 'pre-wrap',
                          backgroundColor: '#ffffff',
                          color: '#000000',
                          display: 'inline-block',
                          maxWidth: message.sender === 'user' ? '70%' : '83.5%',
                          fontFamily: 'Inter, sans-serif',
                          fontWeight: 400,
                          fontSize: isMobile ? '0.85rem' : '0.875rem',
                          lineHeight: '1rem',
                        }}
                      >
                        {/* Se il messaggio è uno dei "giochi" */}
                        {isFillTheBoxMessage(message) ? (
                          <FillTheBoxMini fillData={parseFillTheBox(message)} />
                        ) : isQuizMessage(message) ? (
                          <QuizMini quizData={parseQuiz(message)} />
                        ) : isSelectGroupMessage(message) ? (
                          <SelectGroupMini data={parseSelectGroup(message)} />
                        ) : isMatchPairsMessage(message) ? (
                          <MatchPairsMini data={parseMatchPairs(message)} />
                        ) : isRearrangeMessage(message) ? (
                          <RearrangeMini data={parseRearrange(message)} />
                        ) : isCrosswordMessage(message) ? (
                          <CrosswordMini data={parseCrossword(message)} />
                        ) : (
                          // Altrimenti => Markdown
                          <ReactMarkdown
                            // Se gfmPlugin non è null => lo applichiamo
                            remarkPlugins={gfmPlugin ? [gfmPlugin] : []}
                            components={{
                              table: ({ node, children, ...props }) => (
                                <table
                                  style={{
                                    borderCollapse: 'collapse',
                                    width: '100%',
                                    marginTop: '1rem',
                                    marginBottom: '1rem',
                                  }}
                                  {...props}
                                >
                                  {children}
                                </table>
                              ),
                              thead: ({ node, children, ...props }) => (
                                <thead style={{ backgroundColor: '#f2f2f2' }} {...props}>
                                  {children}
                                </thead>
                              ),
                              th: ({ node, children, ...props }) => (
                                <th
                                  style={{
                                    border: '1px solid #ddd',
                                    textAlign: 'left',
                                    padding: '8px',
                                  }}
                                  {...props}
                                >
                                  {children}
                                </th>
                              ),
                              tr: ({ node, children, ...props }) => (
                                <tr style={{ borderBottom: '1px solid #ddd' }} {...props}>
                                  {children}
                                </tr>
                              ),
                              td: ({ node, children, ...props }) => (
                                <td
                                  style={{
                                    border: '1px solid #ddd',
                                    padding: '8px',
                                  }}
                                  {...props}
                                >
                                  {children}
                                </td>
                              ),
                            }}
                          >
                            {message.text}
                          </ReactMarkdown>
                        )}

                        {/* Se isInitial + showVideo + agent=attività => video */}
                        {message.isInitial && showVideo && selectedOption === 'attività' && (
                          <Box sx={{ marginTop: '1rem' }}>
                            <video
                              src={`/video/${selectedOption}.mp4`}
                              controls
                              style={{ width: '100%', borderRadius: '0.5rem' }}
                            />
                          </Box>
                        )}

                        {/* Se isInitial => esempi random */}
                        {message.isInitial && randomExamples.length > 0 && (
                          <Box
                            sx={{
                              marginTop: '1rem',
                              display: 'flex',
                              flexDirection: 'row',
                              flexWrap: 'wrap',
                              gap: '1rem',
                              justifyContent: 'center',
                            }}
                          >
                            {randomExamples.map((example, idx) => (
                              <Card
                                key={idx}
                                variant="outlined"
                                sx={{
                                  backgroundColor: '#fff',
                                  border: '1px solid #e0e0e0',
                                  borderRadius: '0.5rem',
                                  boxShadow: 'none',
                                  padding: '0.5rem 1rem',
                                  cursor: 'pointer',
                                  transition: 'background-color 0.3s',
                                  minWidth: '150px',
                                  flex: '1 1 150px',
                                  maxWidth: '280px',
                                  '&:hover': {
                                    backgroundColor: '#f5f5f5',
                                  },
                                }}
                                onClick={() => onExampleClick(example)}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: '0.875rem',
                                    color: '#333',
                                    textAlign: 'center',
                                  }}
                                >
                                  {example}
                                </Typography>
                              </Card>
                            ))}
                          </Box>
                        )}

                        {/* Attachments diversi da immagini */}
                        {message.attachments && message.attachments.length > 0 && (
                          <Box sx={{ marginTop: '0.5rem', width: '100%' }}>
                            {message.attachments.map((attachment, idx) => {
                              const fileExtension = attachment.file_name
                                .split('.')
                                .pop()
                                .toLowerCase();

                              if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
                                return null; // già gestito come immagine
                              } else {
                                return (
                                  <Box
                                    key={idx}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginBottom: '0.5rem',
                                      backgroundColor: '#f5f5f5',
                                      padding: '0.5rem 1rem',
                                      borderRadius: '0.5rem',
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{ marginLeft: '0.5rem', flexGrow: 1 }}
                                    >
                                      {attachment.file_name}
                                    </Typography>
                                  </Box>
                                );
                              }
                            })}

                            {/* Se imageUrl è presente, la mostriamo qui */}
                            {imageUrl && (
                              <Box
                                sx={{
                                  marginBottom: '0.5rem',
                                  textAlign: 'left',
                                  position: 'relative',
                                  width: isMobile ? '95%' : '20rem',
                                  height: 'auto',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={imageUrl}
                                  alt="chat_image"
                                  style={{
                                    width: isMobile ? '95%' : '20rem',
                                    height: 'auto',
                                    borderRadius: '0.5rem',
                                  }}
                                />
                              </Box>
                            )}
                          </Box>
                        )}
                      </Box>
                    </Box>

                    {/* ActionButtons (copia, doc, pdf, cerca media, salva gioco) 
                        solo se assistente e non isTyping/isInitial */}
                    {message.sender === 'assistant' && !message.isTyping && !message.isInitial && (
                      <ActionButtons
                        messageText={message.text}
                        onCopy={handleCopy}
                        onDownloadWord={handleDownloadWord}
                        onDownloadPDF={handleDownloadPDF}
                        onSearchMaterial={() => openMediaDialog(message)}
                        isMobile={isMobile}
                        disableSearch={false}
                        imageUrl={imageUrl}
                        selectedOption={selectedOption}
                        onSaveGame={() => {
                          setCurrentMessage(message);
                          handleOpenSaveDialog();
                        }}
                      />
                    )}

                    {/* Se c'è media restituito da handleMediaSearch */}
                    {message.media && (
                      <MediaDisplay media={message.media} selectedMediaType={selectedMediaType} />
                    )}
                  </>
                )}
              </Box>
            );
          })}
        </>
      )}
    </Box>
  );
};

export default ChatMessages;
