// File: src/components/games/ScoresPage.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Paper,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider
} from '@mui/material';

import Header from '../Header';
import Footer from '../Footer';
import MobileMenu from '../MobileMenu';
import FloatingMenu from '../FloatingMenu';
import apiClient from '../../api/apiClient';

const ScoresPage = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();

  // Stato per i punteggi
  const [scores, setScores] = useState([]);
  const [loading, setLoading] = useState(true);

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  // Gestione menu e login
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('access'));

  // Dialog Dettagli Score
  const [selectedScore, setSelectedScore] = useState(null); // Al click su un punteggio

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowFloatingMenu(true);
      } else {
        setShowFloatingMenu(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Funzione per caricare i punteggi
  const fetchScores = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get(`/api/interactive-games/games/${gameId}/scores/`);
      setScores(response.data); // Array di score
    } catch (err) {
      console.error('Errore nel recupero dei punteggi:', err);
      setSnackbarMessage('Non sei autorizzato o il gioco non esiste.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchScores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  // Apri dialog dettagli
  const handleOpenDetails = (score) => {
    setSelectedScore(score);
  };

  // Chiudi dialog dettagli
  const handleCloseDetails = () => {
    setSelectedScore(null);
  };

  return (
    <>
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        backgroundColor="#DF4634"
        textColor="#FFFFFF"
        logoImage="/logo/logobianco.svg"
      />
      {mobileMenuOpen && (
        <MobileMenu
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          setMobileMenuOpen={setMobileMenuOpen}
        />
      )}
      {showFloatingMenu && (
        <FloatingMenu
          isLoggedIn={isLoggedIn}
          handleLogout={() => {
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            setIsLoggedIn(false);
          }}
        />
      )}

      <Box sx={{ minHeight: '100vh', backgroundColor: '#F3F6FB', padding: '2rem' }}>
        {loading ? (
          <Box sx={{ textAlign: 'center', marginTop: '5rem' }}>
            <CircularProgress sx={{ color: '#DF4634' }} />
          </Box>
        ) : (
          <Box sx={{ maxWidth: '600px', margin: '0 auto' }}>
            <Paper
              sx={{
                padding: '1.5rem',
                borderRadius: '1rem',
                boxShadow: '0px 2px 4px rgba(0,0,0,0.1)'
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  marginBottom: '1rem',
                  fontWeight: 700,
                  textAlign: 'center'
                }}
              >
                Classifica Punteggi
              </Typography>
              {scores.length === 0 ? (
                <Typography variant="body1" sx={{ textAlign: 'center' }}>
                  Nessuno ha ancora inviato punteggi.
                </Typography>
              ) : (
                <List>
                  {scores.map((s) => (
                    <ListItem
                      key={s.id}
                      sx={{
                        borderBottom: '1px solid #eee',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: '#f6f6f6'
                        }
                      }}
                      onClick={() => handleOpenDetails(s)}
                    >
                      <ListItemText
                        primary={`${s.student_name} — Score: ${s.score}`}
                        secondary={`Inviato: ${new Date(s.submitted_at).toLocaleString()}`}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            </Paper>

            <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#DF4634',
                  borderRadius: '1.875rem',
                  textTransform: 'none',
                  fontWeight: 500,
                  padding: '0.6rem 1.2rem',
                  '&:hover': {
                    backgroundColor: '#E57373'
                  }
                }}
                onClick={() => navigate('/games')}
              >
                Torna ai Giochi
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      {/* DIALOG DETTAGLI SCORE */}
      <Dialog
        open={Boolean(selectedScore)}
        onClose={handleCloseDetails}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Dettagli Punteggio</DialogTitle>

        {selectedScore && (
          <DialogContent>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              Studente: {selectedScore.student_name}
            </Typography>
            <Typography variant="subtitle2" sx={{ color: 'text.secondary', mb: 2 }}>
              Score: {selectedScore.score} – Inviato: {new Date(selectedScore.submitted_at).toLocaleString()}
            </Typography>

            <Divider sx={{ mb: 2 }} />

            {/* 
              Mostriamo i dettagli dell'array 'details' 
              Ad esempio, se 'details' è un array di oggetti del tipo:
                { questionId, userAnswer, correctAnswer, correct: boolean }
              Oppure: { exerciseId, userSentence, correctSentence, correct: boolean }
            */}
            {Array.isArray(selectedScore.details) && selectedScore.details.length > 0 ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                {selectedScore.details.map((d, idx) => (
                  <Paper
                    key={idx}
                    sx={{
                      padding: '1rem',
                      borderRadius: '0.5rem',
                      backgroundColor: d.correct ? '#E8F5E9' : '#FFEBEE'
                    }}
                  >
                    {/* Esempio di come potresti mostrare i campi */}
                    {d.questionId && (
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        Domanda #{d.questionId}
                      </Typography>
                    )}
                    {d.exerciseId && (
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        Esercizio ID: {d.exerciseId}
                      </Typography>
                    )}
                    {d.rearrangeId && (
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        Rearrange ID: {d.rearrangeId}
                      </Typography>
                    )}
                    {d.userSentence && (
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        Tua Frase: {d.userSentence}
                      </Typography>
                    )}
                    {d.correctSentence && (
                      <Typography variant="body2" sx={{ mt: 0.5 }}>
                        Frase Corretta: {d.correctSentence}
                      </Typography>
                    )}
                    {d.userAnswer !== undefined && (
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        Risposta data: {d.userAnswer || 'Nessuna'}
                      </Typography>
                    )}
                    {d.correctAnswer !== null && d.correctAnswer !== undefined && (
                      <Typography variant="body2" sx={{ mt: 0.5 }}>
                        Risposta corretta: {d.correctAnswer}
                      </Typography>
                    )}
                    <Typography
                      variant="body2"
                      sx={{
                        mt: 0.5,
                        fontWeight: 'bold',
                        color: d.correct ? 'green' : 'red'
                      }}
                    >
                      Esito: {d.correct ? 'Corretto' : 'Sbagliato'}
                    </Typography>
                  </Paper>
                ))}
              </Box>
            ) : (
              <Typography variant="body2" color="text.secondary">
                Nessun dettaglio disponibile per questo punteggio.
              </Typography>
            )}
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={handleCloseDetails}>Chiudi</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Footer />
    </>
  );
};

export default ScoresPage;
