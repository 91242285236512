// File: src/App.js

import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './components/home/Home';
import Login from './components/Login';
import WelcomePage from './components/WelcomePage';
import Signup from './components/Signup';
import Chat from './components/chat/Chat';
import Profile from './components/profile/Profile';
import Subscription from './components/Subscription';
import ResetPassword from './components/ResetPassword';
import CheckoutSuccess from './components/CheckoutSuccess';
import CheckoutCancel from './components/CheckoutCancel';
import ChangePassword from './components/ChangePassword';
import DocumentViewer from './components/DocumentViewer';
import Resources from './components/Resources';
import DocumentDetail from './components/DocumentDetail';
import ActivationSuccess from './components/ActivationSuccess';
import AlreadyActivated from './components/AlreadyActivated';
import TutorialPage from './components/tutorial/Tutorial';

// !! Se prima importavi GamesPage qui, ora puoi rimuoverlo o commentarlo
// import GamesPage from './components/chat/GamesPage'; 

import QuizPage from './components/games/QuizPage';
import FillTheBoxPage from './components/games/FillTheBoxPage';
import SelectGroupPage from './components/games/SelectGroupPage'; 
import MatchPairsPage from './components/games/MatchPairsPage'; 
import RearrangePage from './components/games/RearrangePage'; 
import ScoresPage from './components/games/ScoresPage'; 
import CrosswordPage from './components/games/CrosswordPage'; 

import './App.css';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    // Il tuo codice per i cookie Iubenda o simili
    const excludedRoutes = ['/chat'];
    const isExcludedRoute = excludedRoutes.includes(location.pathname);
    const scriptId = 'iubenda-script';

    if (!isExcludedRoute) {
      if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = '//cdn.iubenda.com/cs/iubenda_cs.js';
        script.charset = 'UTF-8';
        script.async = true;
        document.body.appendChild(script);
      }
    } else {
      const existingScript = document.getElementById(scriptId);
      if (existingScript) {
        existingScript.parentNode.removeChild(existingScript);
      }
      const iubendaElements = document.querySelectorAll('[class*="iubenda"]');
      iubendaElements.forEach((element) => element.remove());
    }
  }, [location.pathname]);

  useEffect(() => {
    // Correzione viewport su mobile
    const handleResize = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="App">
      <Routes>
        {/* ROUTE PUBBLICHE/NON AUTENTICATE */}
        <Route path="/" element={<Home />} />
        <Route path="/welcomepage" element={<WelcomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />

        {/* ROUTE CHAT -> Con l'asterisco '/*' per gestire sottopercorsi */}
        <Route path="/chat/*" element={<Chat />} />

        {/* ROUTE PER ALTRI COMPONENTI */}
        <Route path="/profile" element={<Profile />} />
        <Route path="/abbonamento" element={<Subscription />} />
        <Route path="/checkout-success" element={<CheckoutSuccess />} />
        <Route path="/checkout-cancel" element={<CheckoutCancel />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/documents/:documentType" element={<DocumentViewer />} />
        <Route path="/documents/detail/:documentName" element={<DocumentDetail />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/activation-success" element={<ActivationSuccess />} />
        <Route path="/already-activated" element={<AlreadyActivated />} />
        <Route path="/tutorial" element={<TutorialPage />} />

        {/* !! Se prima gestivi /games come route separata, ora la rimuoviamo 
            perché vogliamo /chat/games come route interna a <Chat> 
        */}
        {/* <Route path="/games" element={<GamesPage />} /> */}

        {/* ROUTE GESTIONE DEI GIOCHI SPECIFICI */}
        <Route path="/games/quiz/:quizId" element={<QuizPage />} />
        <Route path="/games/fillinthebox/:gameId" element={<FillTheBoxPage />} />
        <Route path="/games/selectgroup/:gameId" element={<SelectGroupPage />} />
        <Route path="/games/matchpairs/:gameId" element={<MatchPairsPage />} />
        <Route path="/games/rearrange/:gameId" element={<RearrangePage />} />
        <Route path="/games/crossword/:gameId" element={<CrosswordPage />} />
        <Route path="/games/:gameId/scores" element={<ScoresPage />} />
      </Routes>
    </div>
  );
};

export default App;
