import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import { Box, useMediaQuery, Button } from '@mui/material';

import Sidebar from './Sidebar';
import MobileSidebar from './MobileSidebar';
import ChatMessages from './ChatMessages';
import MessageInput from './MessageInput';
import AgentSwitch from './agentSwitch';
import MobileHeader from './MobileHeader';
import AgentStart from './AgentStart';
import ReachedLimit from './ReachedLimit';
import AgentStartMobile from './AgentStartMobile';
import GamesPage from './GamesPage'; // Importa la GamesPage

import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
  removeTokens,
} from '../../api/authUtils';

import useMediaSearch from '../../api/chat/useMediaSearch';
import { agentExamples } from './agentExamples';

// URL base per il backend
const baseUrl = process.env.REACT_APP_BASE_URL;

// Messaggi iniziali per ogni agente
const initialMessages = {
  quiz: "Ciao! Sono l'Esperto di Quiz! Posso creare quiz interattivi e didattici su misura per la tua classe. Indica la classe, il livello, l'argomento e l'obiettivo specifico per iniziare a costruire insieme il quiz perfetto. ✏️",
  laboratori: "Ciao! Sono l'Esperto di Laboratori. Ideo laboratori pratici e coinvolgenti per i tuoi studenti. Specifica la classe, l'argomento e i materiali che hai a disposizione, così potrò offrirti la proposta ideale. 🔍",
  attività: "Salve! Sono l'Esperto di Attività Didattiche. Insieme possiamo creare attività innovative e su misura per i tuoi studenti. Indica la classe, l'argomento e gli obiettivi che vuoi raggiungere per ricevere suggerimenti personalizzati. 💡",
  metodologie: "Buongiorno! Sono l'Esperto di Metodologie Didattiche. Scopriamo insieme nuove metodologie educative adatte alle tue esigenze. Dimmi il contesto della tua classe e l'argomento su cui vuoi lavorare per suggerimenti mirati. 📘",
  montessori: "Benvenuto! Sono Maria Montessori. Posso guidarti nell'applicazione dei principi montessoriani. Specifica l'età dei tuoi alunni, i tuoi obiettivi e le loro necessità per consigli su misura. 🌼",
  malaguzzi: "Ciao! Sono Loris Malaguzzi. Ti aiuterò a sviluppare attività che seguono questo approccio educativo. Fornisci informazioni sugli studenti e le attività che vuoi realizzare per suggerimenti precisi e pertinenti. 🌱",
  scrittura: "Ciao! Sono l'Esperto di Scrittura di documenti scolastici. Posso aiutarti a redigere o migliorare verbali, UDA, schede didattiche e altri documenti amministrativi per la tua scuola. Dimmi di cosa hai bisogno e iniziamo a lavorare insieme! 🖋️",
  minerva: "Ciao! Sono Minerva, un’AI specializzata nel mondo educativo italiano. Conosco a fondo il sistema scolastico, le Indicazioni Nazionali e le normative del Ministero dell’Istruzione. Puoi chiedermi tutto, da informazioni sulle regole scolastiche a consigli sulle metodologie o sulla visione educativa italiana. Sono qui per aiutarti!",
  immaginai: "Ciao! Sono ImmaginAI. Posso generare immagini basate sulle tue descrizioni. Inviami un messaggio con ciò che desideri vedere, e creerò un'immagine per te! 🖼️",
  fillthebox: "Ciao! Sono l'Esperto di Esercizi a Completamento. Posso creare attività in cui gli studenti completano frasi in modo interattivo. Indica l'argomento e il livello della tua classe per iniziare. ✍️",
  selectgroup: "Ciao! Ti aiuterò a creare esercizi in cui gli studenti dividono elementi in gruppi tematici. Specifica l'argomento e i dettagli per partire! 🗂️",
  matchpairs: "Ciao! Creerò esercizi di abbinamento per rendere l’apprendimento più interattivo e coinvolgente. Indica l’argomento e la tua classe per iniziare. 🔗",
  rearrange: "Ciao! Sono l'Esperto di Riordinamento di frasi. Dimmi l'argomento o il livello di difficoltà che desideri e creerò esercizi per i tuoi studenti. ✂️",
  crossword: "Ciao! Sono l'Esperto di Cruciverba. Posso aiutarti a creare cruciverba originali basati su parole chiave o concetti specifici per la tua classe. Dimmi l’argomento, il livello e il numero di parole che desideri includere, e prepariamoci a divertirci con l’apprendimento! ✍️",
};

const Chat = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isGamesPage = location.pathname.endsWith('/games');  // true se siamo su /chat/games

  // ------------------ State ------------------
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [selectedOption, setSelectedOption] = useState('attività');

  const [files, setFiles] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [didatticaOpen, setDidatticaOpen] = useState(false);
  const [agentStartOpen, setAgentStartOpen] = useState(false);
  const [limitError, setLimitError] = useState(null);
  const [shouldScroll, setShouldScroll] = useState(false);

  // Mobile sidebar
  const isMobile = useMediaQuery('(max-width:600px)');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Altri
  const { searchMedia } = useMediaSearch();
  const [showVideo, setShowVideo] = useState(false);
  const [hasChattedWithAgent, setHasChattedWithAgent] = useState(false);

  // Riferimenti
  const messageInputRef = useRef(null);
  const containerRef = useRef(null);

  // ------------------------------------------------------
  // Error handler
  // ------------------------------------------------------
  const handleErrorResponse = async (response) => {
    if (!response) return true;
    if (response.status === 401) {
      navigate('/login');
      return true;
    }
    if (response.status === 403 || response.status === 429) {
      const errorData = await response.json();
      if (errorData?.detail?.includes('limite')) {
        setLimitError(errorData.detail);
        return true;
      } else {
        navigate('/abbonamento', {
          state: { planExpired: true },
        });
        
        return true;
      }
    }
    return false;
  };

  // ------------------------------------------------------
  // Refresh token
  // ------------------------------------------------------
  const refreshAccessToken = async () => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
      removeTokens();
      navigate('/login');
      return null;
    }
    try {
      const response = await fetch(`${baseUrl}/api/accounts/token/refresh/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refresh: refreshToken }),
      });
      if (!response.ok) {
        throw new Error('Failed to refresh token');
      }
      const data = await response.json();
      setAccessToken(data.access);
      if (data.refresh) {
        setRefreshToken(data.refresh);
      }
      return data.access;
    } catch (error) {
      console.error('Error refreshing token:', error);
      removeTokens();
      navigate('/login');
      return null;
    }
  };

  // ------------------------------------------------------
  // makeAuthenticatedRequest
  // ------------------------------------------------------
  const makeAuthenticatedRequest = async (url, options = {}) => {
    let accessToken = getAccessToken();
    if (!accessToken) {
      navigate('/login');
      return null;
    }
    const headers = { ...options.headers, Authorization: `Bearer ${accessToken}` };
    try {
      let response = await fetch(url, { ...options, headers });
      if (response.status === 401 || response.status === 403) {
        accessToken = await refreshAccessToken();
        if (!accessToken) return null;
        const retryHeaders = { ...options.headers, Authorization: `Bearer ${accessToken}` };
        response = await fetch(url, { ...options, headers: retryHeaders });
      }
      return response;
    } catch (error) {
      console.error('Error making authenticated request:', error);
      return null;
    }
  };

  // ------------------------------------------------------
  // fetchChats
  // ------------------------------------------------------
  const fetchChats = async () => {
    try {
      const response = await makeAuthenticatedRequest(`${baseUrl}/api/chat/chats/`, {
        method: 'GET',
      });
      if (!response) return;

      const errorHandled = await handleErrorResponse(response);
      if (errorHandled) {
        // handleErrorResponse ha già fatto navigate('/abbonamento') o simili
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error fetching chats:', errorData);
        return;
      }

      
      const data = await response.json();
      const sorted = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setChats(sorted);

      // Se non hai alcuna chat
      if (sorted.length === 0) {
        // Crea una nuova chat ma SOLO se non siamo su /chat/games
        // (per non spostarti automaticamente da /chat/games)
        if (!isGamesPage) {
          handleNewChat(selectedOption, false, false); // fromAgentStart=false, isUserClick=false
        }
      } else {
        // Se NON siamo su /chat/games e non hai selectedChat => seleziono la prima
        if (!isGamesPage && !selectedChat) {
          selectChat(sorted[0], false); // isUserClick=false
        }
      }
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  };

  // ------------------------------------------------------
  // fetchMessages
  // ------------------------------------------------------
  const fetchMessages = async (chatId, agent) => {
    setLoading(true);
    try {
      const response = await makeAuthenticatedRequest(
        `${baseUrl}/api/chat/chats/${chatId}/messages/${agent}/?include=messages`,
        { method: 'GET' }
      );
      if (!response) {
        setLoading(false);
        return;
      }
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error fetching messages:', errorData);
        setLoading(false);
        return;
      }

      const data = await response.json();
      let fetched = data.messages;

      // Messaggio iniziale se manca
      const initialText = initialMessages[agent] || 'Benvenuto!';
      const hasInitial = fetched.some(
        (m) => m.text === initialText && m.sender === 'assistant'
      );
      if (fetched.length === 0 || !hasInitial) {
        const initialMessage = {
          id: `initial-${chatId}`,
          chat_id: chatId,
          text: initialText,
          sender: 'assistant',
          isInitial: true,
        };
        fetched = [initialMessage, ...fetched];
      }

      setMessages(fetched);
      setShouldScroll(true);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setLoading(false);
    }
  };

  // ------------------------------------------------------
  // selectChat
  // ------------------------------------------------------
  /**
   * @param {object} chat - L'oggetto chat selezionato
   * @param {boolean} isUserClick - true se è stato l'utente a cliccare manualmente
   */
  const selectChat = (chat, isUserClick = false) => {
    setSelectedChat(chat);
    setSelectedOption(chat.agent);

    // Se l'utente ha cliccato manualmente mentre siamo su /chat/games => torna a /chat
    if (isUserClick && isGamesPage) {
      navigate('/chat');
    }
  };

  // ------------------------------------------------------
  // handleNewChat
  // ------------------------------------------------------
  /**
   * @param {string} agentOption - L'agente scelto
   * @param {boolean} fromAgentStart - true se la creazione viene dal popup AgentStart
   * @param {boolean} isUserClick - true se è l'utente che ha cliccato manualmente
   */
  const handleNewChat = async (agentOption = selectedOption, fromAgentStart = false, isUserClick = true) => {
    setSelectedOption(agentOption);

    // Se non arrivo da AgentStart e seleziono di nuovo lo stesso agent => apri pop-up
    if (!fromAgentStart && agentOption === selectedOption) {
      setAgentStartOpen(true);
      return;
    }

    const accessToken = getAccessToken();
    if (!accessToken) return;

    try {
      const response = await makeAuthenticatedRequest(`${baseUrl}/api/chat/chats/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ agent: agentOption }),
      });
      const errorHandled = await handleErrorResponse(response);
      if (errorHandled) return;

      if (!response.ok) {
        const err = await response.json();
        console.error('Error creating new chat:', err);
        return;
      }

      const data = await response.json();
      setChats((prev) => [data, ...prev]);

      // Seleziona la chat appena creata (isUserClick)
      selectChat(data, isUserClick);

      // Se l'utente ha cliccato manualmente e siamo su /chat/games => naviga /chat
      if (isUserClick && isGamesPage) {
        navigate('/chat');
      }
    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };

  // ------------------------------------------------------
  // handleDeleteChat
  // ------------------------------------------------------
  const handleDeleteChat = async (chatId) => {
    const accessToken = getAccessToken();
    if (!accessToken) return;

    try {
      const response = await makeAuthenticatedRequest(
        `${baseUrl}/api/chat/chats/${chatId}/delete/`,
        { method: 'DELETE' }
      );
      if (!response) return;
      if (!response.ok) {
        console.error('Error deleting chat:', await response.json());
        return;
      }
      const updated = chats.filter((c) => c.id !== chatId);
      setChats(updated);

      // Se rimangono altre chat, seleziono la prima (se non siamo su /games)
      if (updated.length > 0) {
        if (!isGamesPage) {
          selectChat(updated[0], false); // selezione automatica
        } else {
          // se siamo su /games, non seleziono nulla
          setSelectedChat(null);
          setMessages([]);
        }
      } else {
        // Nessuna chat -> reset
        setSelectedChat(null);
        setMessages([]);
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  // ------------------------------------------------------
  // handleGenerateImage (immaginai)
  // ------------------------------------------------------
  const handleGenerateImage = async () => {
    if (!newMessage.trim()) return;
    if (!selectedChat) return;

    const accessToken = getAccessToken();
    if (!accessToken) {
      navigate('/login');
      return;
    }

    const userMessage = {
      id: Date.now(),
      chat_id: selectedChat.id,
      text: newMessage,
      sender: 'user',
    };

    setMessages((prev) => [
      ...prev,
      userMessage,
      {
        id: Date.now() + 1,
        chat_id: selectedChat.id,
        text: '',
        sender: 'assistant',
        isTyping: true,
      },
    ]);
    setNewMessage('');

    try {
      const response = await makeAuthenticatedRequest(`${baseUrl}/api/chat/generate-image/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          message: newMessage,
          chat_id: selectedChat.id,
        }),
      });
      const errorHandled = await handleErrorResponse(response);
      if (errorHandled) {
        setMessages((prev) => prev.slice(0, -1));
        return;
      }
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error generating image:', errorData);
        setMessages((prev) => prev.slice(0, -1));
        return;
      }
      const data = await response.json();
      const assistantMessage = data.assistant_message;

      setMessages((prev) => {
        const lastMsg = prev[prev.length - 1];
        if (lastMsg && lastMsg.sender === 'assistant') {
          return [
            ...prev.slice(0, -1),
            { ...assistantMessage, sender: 'assistant', isTyping: false },
          ];
        }
        return prev;
      });

      await fetchChats();
    } catch (error) {
      console.error('Error generating image:', error);
      setMessages((prev) => prev.slice(0, -1));
    }
  };

  // ------------------------------------------------------
  // handleSendMessage
  // ------------------------------------------------------
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() && files.length === 0) return;
    if (!selectedChat) return;

    // Se agent immaginai => genera immagine
    if (selectedOption === 'immaginai') {
      await handleGenerateImage();
      return;
    }

    const accessToken = getAccessToken();
    if (!accessToken) return;

    const userMessage = {
      id: Date.now(),
      chat_id: selectedChat.id,
      text: newMessage,
      sender: 'user',
      attachments: files.map((file) => ({
        file_name: file.name,
      })),
    };

    setMessages((prev) => [
      ...prev,
      userMessage,
      {
        id: Date.now() + 1,
        chat_id: selectedChat.id,
        text: '',
        sender: 'assistant',
        isTyping: true,
      },
    ]);
    setNewMessage('');
    setFiles([]);

    try {
      const formData = new FormData();
      formData.append('text', newMessage);
      files.forEach((file) => {
        formData.append('files', file);
      });

      const response = await makeAuthenticatedRequest(
        `${baseUrl}/api/chat/chats/${selectedChat.id}/messages/${selectedOption}/`,
        {
          method: 'POST',
          body: formData,
        }
      );
      const errorHandled = await handleErrorResponse(response);
      if (errorHandled) {
        setMessages((prev) => prev.slice(0, -1));
        return;
      }
      if (!response.ok) {
        const errData = await response.json();
        console.error('Error sending message:', errData);
        setMessages((prev) => prev.slice(0, -1));
        return;
      }

      const data = await response.json();
      let assistantMessage;
      if (data.assistant_message) {
        assistantMessage = data.assistant_message;
      } else if (data.message) {
        assistantMessage = {
          id: Date.now() + 1,
          chat_id: selectedChat.id,
          text: data.message,
          sender: 'assistant',
          attachments: [],
        };
      } else {
        console.error('API response does not contain a valid message:', data);
        setMessages((prev) => prev.slice(0, -1));
        return;
      }

      // Sostituisci l'ultimo messaggio "isTyping" con la risposta effettiva
      setMessages((prev) => {
        const lastMsg = prev[prev.length - 1];
        if (lastMsg && lastMsg.sender === 'assistant') {
          return [
            ...prev.slice(0, -1),
            { ...lastMsg, ...assistantMessage, isTyping: false },
          ];
        }
        return prev;
      });

      await fetchChats();
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages((prev) => prev.slice(0, -1));
    }
  };

  // ------------------------------------------------------
  // handleMediaSearch
  // ------------------------------------------------------
  const handleMediaSearch = async (message, mediaType) => {
    try {
      setMessages((prev) =>
        prev.map((m) => (m.id === message.id ? { ...m, mediaLoading: true } : m))
      );
      const mediaData = await searchMedia(message.text, mediaType);
      const mediaArray = mediaData[`${mediaType}s`] || mediaData;

      setMessages((prev) =>
        prev.map((m) =>
          m.id === message.id ? { ...m, media: mediaArray, mediaLoading: false } : m
        )
      );
    } catch (error) {
      console.error('Errore media search:', error);
      setMessages((prev) =>
        prev.map((m) => (m.id === message.id ? { ...m, mediaLoading: false } : m))
      );
    }
  };

  // ------------------------------------------------------
  // handleOptionChange
  // ------------------------------------------------------
  const handleOptionChange = async (option) => {
    setDidatticaOpen(false);
    await handleNewChat(option, false, true); // isUserClick = true
  };

  // ------------------------------------------------------
  // handleLogout
  // ------------------------------------------------------
  const handleLogout = () => {
    removeTokens();
    navigate('/login');
  };

  // ------------------------------------------------------
  // handleExampleClick
  // ------------------------------------------------------
  const handleExampleClick = (exampleText) => {
    setNewMessage(exampleText);
    if (messageInputRef.current) {
      messageInputRef.current.focus();
    }
  };

  // ------------------------------------------------------
  // handleCloseReachedLimit
  // ------------------------------------------------------
  const handleCloseReachedLimit = () => {
    setLimitError(null);
  };

  // ------------------------------------------------------
  // onMyGamesClick
  // ------------------------------------------------------
  const onMyGamesClick = () => {
    // Deseleziono la chat
    setSelectedChat(null);
    setSelectedOption('');
    // Vado su /chat/games
    navigate('games');
  };

  // ------------------------------------------------------
  // handleCreateGameChat
  // ------------------------------------------------------
  const handleCreateGameChat = async (agentOption) => {
    console.log('Creo nuova chat con agente:', agentOption);
    // azione manuale => isUserClick=true
    await handleNewChat(agentOption, true, true);
  };

  // ------------------------------------------------------
  // Effects
  // ------------------------------------------------------
  useEffect(() => {
    const accessToken = getAccessToken();
    if (!accessToken) {
      navigate('/login');
      return;
    }
    fetchChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Se l'utente ha già chattato con quell'agente?
  useEffect(() => {
    if (selectedOption && chats.length > 0) {
      const sameAgentChats = chats.filter(
        (c) => c.agent === selectedOption && c.id !== selectedChat?.id
      );
      setHasChattedWithAgent(sameAgentChats.length > 0);
    } else {
      setHasChattedWithAgent(false);
    }
  }, [selectedOption, selectedChat, chats]);

  // Controllo se far partire il video introduttivo
  useEffect(() => {
    if (messages.length === 1 && messages[0].isInitial && !hasChattedWithAgent) {
      setShowVideo(true);
    } else {
      setShowVideo(false);
    }
  }, [messages, hasChattedWithAgent]);

  // Scroll in basso alla fine dei messaggi
  useEffect(() => {
    if (shouldScroll && containerRef.current) {
      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
      }, 100);
      setShouldScroll(false);
    } else {
      if (containerRef.current) {
        containerRef.current.scrollTop += 150;
      }
    }
  }, [messages, selectedChat, shouldScroll]);

  // Resize fix per mobile
  useEffect(() => {
    const handleResize = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Quando cambia selectedChat, ricarico i messaggi
  useEffect(() => {
    if (selectedChat) {
      fetchMessages(selectedChat.id, selectedOption);
    }
  }, [selectedChat, selectedOption]);

  // ------------------------------------------------------
  // Render
  // ------------------------------------------------------
  if (limitError) {
    return <ReachedLimit errorMessage={limitError} onClose={handleCloseReachedLimit} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        height: 'calc(var(--vh, 1vh) * 100)',
        backgroundColor: '#F3F6FB',
        fontFamily: 'Inter, sans-serif',
        position: 'relative',
      }}
    >
      {/* MOBILE */}
      {isMobile ? (
        <>
          <MobileHeader
            didatticaOpen={didatticaOpen}
            setDidatticaOpen={setDidatticaOpen}
            selectedOption={selectedOption}
            handleOptionChange={handleOptionChange}
            toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
            handleNewChat={handleNewChat}
          />
          {sidebarOpen && (
            <MobileSidebar
              chats={chats}
              selectedChat={selectedChat}
              // Quando l'utente clicca su una chat esistente => isUserClick = true
              selectChat={(chat) => selectChat(chat, true)}
              handleNewChat={(agent) => handleNewChat(agent, false, true)}
              handleDeleteChat={handleDeleteChat}
              handleLogout={handleLogout}
              navigate={navigate}
              selectedOption={selectedOption}
              onClose={() => setSidebarOpen(false)}
              onMyGamesClick={onMyGamesClick}
            />
          )}
        </>
      ) : (
        // DESKTOP
        <>
          <Sidebar
            chats={chats}
            selectedChat={selectedChat}
            // Idem: passiamo isUserClick=true se l'utente clicca manualmente
            selectChat={(chat) => selectChat(chat, true)}
            handleNewChat={(agent) => handleNewChat(agent, false, true)}
            handleDeleteChat={handleDeleteChat}
            handleLogout={handleLogout}
            navigate={navigate}
            selectedOption={selectedOption}
            onMyGamesClick={onMyGamesClick}
          />

          {/* Se NON siamo su /chat/games => AgentSwitch o pulsante Montessori/Malaguzzi */}
          {!isGamesPage && (selectedOption === 'montessori' || selectedOption === 'malaguzzi') ? (
            <Box
              sx={{
                position: 'fixed',
                left: '20%',
                top: '2.5rem',
                display: 'flex',
                alignItems: 'center',
                zIndex: 2000,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#DF4634',
                  color: '#FFFFFF',
                  borderRadius: '1.875rem',
                  width: '7rem',
                  height: '2.2rem',
                  textTransform: 'none',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  '&:hover': {
                    backgroundColor: '#E57373',
                  },
                }}
              >
                {selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1)}
              </Button>
            </Box>
          ) : (
            !isGamesPage && (
              <AgentSwitch
                didatticaOpen={didatticaOpen}
                setDidatticaOpen={setDidatticaOpen}
                selectedOption={selectedOption}
                handleOptionChange={handleOptionChange}
              />
            )
          )}
        </>
      )}

      {/* MAIN CONTENT */}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: isMobile ? '1rem' : '3.125rem',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Routes>
          {/* /chat */}
          <Route
            path=""
            element={
              <>
                <ChatMessages
                  key={`messages-${selectedChat?.id}-${selectedOption}`}
                  messages={messages}
                  loading={loading}
                  containerRef={containerRef}
                  chatTitle={selectedChat?.title || 'Chat'}
                  selectedOption={selectedOption}
                  handleMediaSearch={handleMediaSearch}
                  showVideo={showVideo}
                  agentExamples={agentExamples[selectedOption]}
                  onExampleClick={handleExampleClick}
                />
                <MessageInput
                  newMessage={newMessage}
                  setNewMessage={setNewMessage}
                  handleSendMessage={handleSendMessage}
                  files={files}
                  setFiles={setFiles}
                  inputRef={messageInputRef}
                />
              </>
            }
          />

          {/* /chat/games */}
          <Route
            path="games"
            element={
              <GamesPage
                setShowGamesPage={() => {}}
                onCreateGame={handleCreateGameChat}
              />
            }
          />
        </Routes>

        {/* Overlay se didatticaOpen */}
        {didatticaOpen && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              zIndex: 1500,
              pointerEvents: 'none',
            }}
          />
        )}

        {/* AgentStart */}
        {agentStartOpen &&
          (isMobile ? (
            <AgentStartMobile
              open={agentStartOpen}
              onClose={() => setAgentStartOpen(false)}
              handleNewChat={(agentKey) => {
                setAgentStartOpen(false);
                handleNewChat(agentKey, true, true);
              }}
            />
          ) : (
            <AgentStart
              open={agentStartOpen}
              onClose={() => setAgentStartOpen(false)}
              handleNewChat={(agentKey) => {
                setAgentStartOpen(false);
                handleNewChat(agentKey, true, true);
              }}
            />
          ))}
      </Box>
    </Box>
  );
};

export default Chat;
