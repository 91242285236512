// File: src/components/games/RearrangePage.jsx

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Grid,
  TextField,
  Menu,
  MenuItem,
} from '@mui/material';

import Header from '../Header';
import Footer from '../Footer';
import MobileMenu from '../MobileMenu';
import FloatingMenu from '../FloatingMenu';
import apiClient from '../../api/apiClient';

// Usa lo stesso import di decodifica token che hai in HomePage
import { jwtDecode } from 'jwt-decode';

/* --------------------------------------------------------------------------
   FUNZIONE DI SHUFFLE UTILE
   -------------------------------------------------------------------------- */
const shuffleArray = (array) => {
  return array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
};

/* --------------------------------------------------------------------------
   FUNZIONE PER NORMALIZZARE UNA PAROLA (o piccola frase) e IGNORARE PUNTEGGIATURA
   -------------------------------------------------------------------------- */
const normalizeWord = (str) => {
  return str
    .toLowerCase()
    .replace(/[.,?!;:'"]/g, '')
    .trim();
};

/* --------------------------------------------------------------------------
   FUNZIONE PER NORMALIZZARE UN'INTERA FRASE (per confronto finale)
   -------------------------------------------------------------------------- */
const normalizeSentence = (str) => {
  return normalizeWord(str).replace(/\s+/g, ' ');
};

/* --------------------------------------------------------------------------
   COMPONENTE DRAG & DROP + TAP & SELECT: RearrangeDragDropCard
   -------------------------------------------------------------------------- */
const RearrangeDragDropCard = ({
  exercise,
  arrangementData,
  onArrangementChange,
  onWordClick, // funzione chiamata su click/tap di una parola
}) => {
  if (!arrangementData) {
    return (
      <Typography sx={{ marginTop: '1rem', fontStyle: 'italic' }}>
        Inizializzazione...
      </Typography>
    );
  }

  const { pool = [], slots = [] } = arrangementData;

  // ---------------------------
  // Gestione DRAG & DROP
  // ---------------------------
  const handleDragStart = (e, payload) => {
    e.dataTransfer.setData('application/json', JSON.stringify(payload));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetType, targetIndex = null) => {
    e.preventDefault();
    try {
      const payload = JSON.parse(e.dataTransfer.getData('application/json'));
      // { from: 'pool'|'slots', index: number }

      // Se droppiamo su noi stessi, annulla
      if (payload.from === targetType && payload.index === targetIndex) return;

      let newPool = [...pool];
      let newSlots = [...slots];

      // Estraggo la parola dall’origine
      let draggedWord = null;
      if (payload.from === 'pool') {
        draggedWord = newPool[payload.index];
        newPool.splice(payload.index, 1);
      } else if (payload.from === 'slots') {
        draggedWord = newSlots[payload.index];
        newSlots[payload.index] = null;
      }

      // Posiziono la parola
      if (targetType === 'pool') {
        newPool.push(draggedWord);
      } else if (targetType === 'slots') {
        // Se nello slot c’era già una parola, la metto nel pool
        if (newSlots[targetIndex]) {
          newPool.push(newSlots[targetIndex]);
        }
        newSlots[targetIndex] = draggedWord;
      }

      onArrangementChange(exercise.id, {
        pool: newPool,
        slots: newSlots,
      });
    } catch (err) {
      console.error('Errore parse drag payload:', err);
    }
  };

  // ---------------------------
  // Render Grafico (pool e slots)
  // ---------------------------
  return (
    <Card
      variant="outlined"
      sx={{
        marginBottom: '1.5rem',
        borderRadius: '1rem',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
        backgroundColor: '#ffffff',
        padding: '1.5rem',
      }}
    >
      <CardContent>
        {exercise.topic && (
          <Typography variant="h6" sx={{ marginBottom: '1rem', fontWeight: 600 }}>
            {exercise.topic}
          </Typography>
        )}

        {/* POOL DI PAROLE */}
        <Box
          sx={{
            marginBottom: '1.5rem',
            backgroundColor: '#f9f9f9',
            padding: '1rem',
            borderRadius: '0.5rem',
            minHeight: '60px',
          }}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, 'pool')}
        >
          <Typography variant="body2" sx={{ fontWeight: 500, marginBottom: '0.5rem' }}>
            Trascina da/verso qui (o clicca/tappa su una parola):
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
            {pool.map((word, index) => (
              <Box
                key={`pool-${index}-${word}`}
                draggable
                onDragStart={(e) => handleDragStart(e, { from: 'pool', index })}
                onDragOver={handleDragOver}
                onClick={(e) =>
                  onWordClick(e, {
                    from: 'pool',
                    index,
                    word,
                  })
                }
                sx={{
                  padding: '0.4rem 0.8rem',
                  backgroundColor: '#fff',
                  borderRadius: '1rem',
                  boxShadow: '0px 2px 4px rgba(0,0,0,0.15)',
                  cursor: 'grab',
                  userSelect: 'none',
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {word}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        {/* SLOTS */}
        <Box>
          <Typography variant="body2" sx={{ fontWeight: 500, marginBottom: '0.5rem' }}>
            Trascina le parole qui sotto, in ordine corretto:
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
            {slots.map((wordInSlot, slotIndex) => (
              <Box
                key={`slot-${slotIndex}`}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, 'slots', slotIndex)}
                sx={{
                  width: '6rem',
                  minHeight: '2rem',
                  backgroundColor: '#f2f2f2',
                  border: '2px dashed #ddd',
                  borderRadius: '0.5rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  padding: '0.5rem',
                }}
              >
                {wordInSlot && (
                  <Box
                    draggable
                    onDragStart={(e) =>
                      handleDragStart(e, { from: 'slots', index: slotIndex })
                    }
                    onClick={(e) =>
                      onWordClick(e, {
                        from: 'slots',
                        index: slotIndex,
                        word: wordInSlot,
                      })
                    }
                    sx={{
                      backgroundColor: '#fff',
                      borderRadius: '1rem',
                      padding: '0.4rem 0.8rem',
                      boxShadow: '0px 2px 4px rgba(0,0,0,0.15)',
                      cursor: 'grab',
                      userSelect: 'none',
                    }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {wordInSlot}
                    </Typography>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

/* --------------------------------------------------------------------------
   COMPONENTE: Riepilogo (RearrangeSummary)
   -------------------------------------------------------------------------- */
const RearrangeSummary = ({ exercises, userArrangements, onReplay, onReturn }) => {
  let correctCount = 0;

  const exercisesWithResults = exercises.map((ex, index) => {
    const arrangement = userArrangements[ex.id];
    if (!arrangement) {
      return { ...ex, userSentence: '', isCorrect: false };
    }

    const { slots = [] } = arrangement;
    const userSentence = slots.filter(Boolean).join(' ').trim();
    const correctSentence = ex.final_sentence.trim();

    const userNormalized = normalizeSentence(userSentence);
    const correctNormalized = normalizeSentence(correctSentence);

    const isCorrect = userNormalized === correctNormalized;
    if (isCorrect) correctCount++;

    return { ...ex, userSentence, isCorrect };
  });

  return (
    <Box sx={{ marginTop: '2rem' }}>
      <Typography variant="h5" sx={{ marginBottom: '1rem', textAlign: 'center', fontWeight: 700 }}>
        Riepilogo Rearrange
      </Typography>

      <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: '1.5rem', color: '#333' }}>
        Score: {correctCount} / {exercises.length}
      </Typography>

      <Grid container spacing={2}>
        {exercisesWithResults.map((ex, index) => (
          <Grid item xs={12} key={ex.id}>
            <Card
              variant="outlined"
              sx={{
                borderRadius: '1rem',
                boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                backgroundColor: '#ffffff',
                padding: '1.5rem',
              }}
            >
              <CardContent>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {index + 1}. {ex.topic || 'Rearrange'}
                </Typography>
                <Box sx={{ marginTop: '1rem' }}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: ex.isCorrect ? '#4CAF50' : '#B71C1C',
                      fontWeight: 500,
                    }}
                  >
                    Tua Frase: {ex.userSentence}
                  </Typography>
                  {!ex.isCorrect && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#4CAF50',
                        marginTop: '0.25rem',
                      }}
                    >
                      Corretta: {ex.final_sentence}
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Pulsanti */}
      <Box
        sx={{
          textAlign: 'center',
          marginTop: '2rem',
          display: 'flex',
          justifyContent: 'center',
          gap: '1rem',
        }}
      >
        <Button
          variant="contained"
          color="error"
          sx={{
            borderRadius: '1.875rem',
            textTransform: 'none',
            fontWeight: 500,
            backgroundColor: '#DF4634',
            '&:hover': {
              backgroundColor: '#C0392B',
            },
          }}
          onClick={() => onReplay(true)}
        >
          Rigioca
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            borderRadius: '1.875rem',
            textTransform: 'none',
            fontWeight: 500,
            backgroundColor: '#1976d2',
            '&:hover': {
              backgroundColor: '#1565C0',
            },
          }}
          onClick={onReturn}
        >
          Torna ai Giochi
        </Button>
      </Box>
    </Box>
  );
};

/* --------------------------------------------------------------------------
   PAGINA COMPLETA: RearrangePage
   -------------------------------------------------------------------------- */
const RearrangePage = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();

  // --------------------------------------------------
  // Stati per la UI generale
  // --------------------------------------------------
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('access'));

  // --------------------------------------------------
  // Dati gioco & owner
  // --------------------------------------------------
  const [loading, setLoading] = useState(true);
  const [gameData, setGameData] = useState(null);
  const [isOwner, setIsOwner] = useState(false);

  // --------------------------------------------------
  // Logica esercizi
  // --------------------------------------------------
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
  const [showSummary, setShowSummary] = useState(false);

  // userArrangements: { [exerciseId]: { pool: [...], slots: [...] } }
  const [userArrangements, setUserArrangements] = useState({});

  // --------------------------------------------------
  // Nome studente (per salvare score)
  // --------------------------------------------------
  const [studentName, setStudentName] = useState('');

  // --------------------------------------------------
  // Snackbar
  // --------------------------------------------------
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  // --------------------------------------------------
  // Menu “tap & select”
  // --------------------------------------------------
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedWordData, setSelectedWordData] = useState(null);
  // selectedWordData -> { from: 'pool'|'slots', index: number, word: string }

  // --------------------------------------------------
  // Effetto per mostrare FloatingMenu dopo uno scroll
  // --------------------------------------------------
  useEffect(() => {
    const handleScroll = () => {
      setShowFloatingMenu(window.scrollY > 100);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // --------------------------------------------------
  // Caricamento dati gioco
  // --------------------------------------------------
  useEffect(() => {
    const fetchGame = async () => {
      setLoading(true);
      try {
        const response = await apiClient.get(`/api/interactive-games/games/${gameId}/`);
        if (response.data?.data?.rearrange) {
          // Mescola inizialmente le parole
          const rearrangeShuffled = response.data.data.rearrange.map((ex) => ({
            ...ex,
            words_shuffled: shuffleArray(ex.words_shuffled),
          }));
          response.data.data.rearrange = rearrangeShuffled;
        }
        setGameData(response.data);
      } catch (err) {
        console.error('Errore caricamento Rearrange:', err);
        setSnackbarMessage('Impossibile caricare il gioco.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchGame();
  }, [gameId]);

  // --------------------------------------------------
  // Verifica se l’utente è owner
  // --------------------------------------------------
  useEffect(() => {
    const token = localStorage.getItem('access');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentUserId = decodedToken.user_id;

        if (gameData && gameData.user && currentUserId) {
          if (parseInt(gameData.user) === parseInt(currentUserId)) {
            setIsOwner(true);
            console.log("SONO owner (Rearrange)");
          } else {
            setIsOwner(false);
            console.log("NON SONO owner (Rearrange)");
          }
        }
      } catch (error) {
        console.error('Errore nella decodifica del token:', error);
      }
    }
  }, [gameData]);

  // --------------------------------------------------
  // Inizializza l'arrangiamento per ogni esercizio
  // --------------------------------------------------
  useEffect(() => {
    if (gameData?.data?.rearrange?.length) {
      const initialData = {};
      gameData.data.rearrange.forEach((ex) => {
        initialData[ex.id] = createInitialArrangement(ex);
      });
      setUserArrangements(initialData);
    }
  }, [gameData]);

  // -----------------------------------------------
  // Funzione per creare arrangement iniziale
  // -----------------------------------------------
  const createInitialArrangement = (ex) => {
    let pool = [...ex.words_shuffled];
    const slots = Array(pool.length).fill(null);

    const correctWords = ex.final_sentence
      .replace(/[.,?!;:'"]/g, '')
      .split(/\s+/);

    // Di default, posizioniamo 2 parole
    const howMany = Math.min(2, correctWords.length);
    const usedIndices = [];

    for (let i = 0; i < howMany; i++) {
      let randomIndex;
      do {
        randomIndex = Math.floor(Math.random() * correctWords.length);
      } while (
        usedIndices.includes(randomIndex) &&
        usedIndices.length < correctWords.length
      );
      usedIndices.push(randomIndex);

      const correctWord = correctWords[randomIndex];

      // Troviamo la corrispondente nel pool
      let foundIndex = pool.findIndex(
        (p) => normalizeWord(p) === normalizeWord(correctWord)
      );
      if (foundIndex >= 0) {
        slots[randomIndex] = pool[foundIndex];
        pool.splice(foundIndex, 1);
      }
    }

    return { pool, slots };
  };

  // -----------------------------------------------
  // Aggiorna arrangement di un singolo esercizio
  // -----------------------------------------------
  const handleArrangementChange = (exerciseId, newData) => {
    setUserArrangements((prev) => ({
      ...prev,
      [exerciseId]: newData,
    }));
  };

  // -----------------------------------------------
  // TAP & SELECT: Apertura e chiusura menu
  // -----------------------------------------------
  const handleWordClick = (event, wordData) => {
    setSelectedWordData(wordData);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedWordData(null);
  };

  // Sposta la parola selezionata in uno slot o nel pool
  const handleAssignWord = (targetType, targetIndex = null) => {
    if (!selectedWordData) return;

    const exerciseId = currentExercise?.id;
    const arrangement = userArrangements[exerciseId] || {};
    let newPool = [...(arrangement.pool || [])];
    let newSlots = [...(arrangement.slots || [])];

    let draggedWord = selectedWordData.word;

    // Rimuovo la parola dalla sua posizione
    if (selectedWordData.from === 'pool') {
      newPool.splice(selectedWordData.index, 1);
    } else if (selectedWordData.from === 'slots') {
      newSlots[selectedWordData.index] = null;
    }

    // Aggiorno destinazione
    if (targetType === 'pool') {
      newPool.push(draggedWord);
    } else if (targetType === 'slots') {
      if (newSlots[targetIndex]) {
        newPool.push(newSlots[targetIndex]);
      }
      newSlots[targetIndex] = draggedWord;
    }

    handleArrangementChange(exerciseId, { pool: newPool, slots: newSlots });
    handleCloseMenu();
  };

  // -----------------------------------------------
  // Navigazione
  // -----------------------------------------------
  const handleNextExercise = () => {
    if (!gameData?.data?.rearrange) return;
    if (currentExerciseIndex < gameData.data.rearrange.length - 1) {
      setCurrentExerciseIndex(currentExerciseIndex + 1);
    }
  };
  const handlePrevExercise = () => {
    if (currentExerciseIndex > 0) {
      setCurrentExerciseIndex(currentExerciseIndex - 1);
    }
  };

  // -----------------------------------------------
  // Verifica, replay, ecc.
  // -----------------------------------------------
  const handleCheckAnswers = () => {
    if (!gameData?.data?.rearrange) return;
    setShowSummary(true);
  };

  const handleReplay = (resetAnswers = true) => {
    if (resetAnswers && gameData?.data?.rearrange) {
      const resetObj = {};
      gameData.data.rearrange.forEach((ex) => {
        resetObj[ex.id] = createInitialArrangement(ex);
      });
      setUserArrangements(resetObj);
    }
    setShowSummary(false);
    setCurrentExerciseIndex(0);
  };

  const handleReturnToGames = () => {
    navigate('/chat/games');
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  // -----------------------------------------------
  // Salvataggio punteggio
  // -----------------------------------------------
  const handleSaveScore = async () => {
    if (!studentName.trim()) {
      setSnackbarMessage('Inserisci il tuo nome!');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    let correctCount = 0;
    const details = [];

    const rearr = gameData?.data?.rearrange || [];
    rearr.forEach((ex) => {
      const arrangement = userArrangements[ex.id] || {};
      const { slots = [] } = arrangement;
      const userSentence = slots.filter(Boolean).join(' ').trim();
      const correctSentence = ex.final_sentence.trim();

      const userNormalized = normalizeSentence(userSentence);
      const correctNormalized = normalizeSentence(correctSentence);
      const isCorrect = userNormalized === correctNormalized;

      if (isCorrect) correctCount++;
      details.push({
        rearrangeId: ex.id,
        userSentence,
        correctSentence,
        correct: isCorrect,
      });
    });

    const payload = {
      student_name: studentName,
      score: correctCount,
      details,
    };

    try {
      await apiClient.post(`/api/interactive-games/games/${gameId}/scores/`, payload);
      setSnackbarMessage('Punteggio salvato con successo!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setStudentName('');
    } catch (err) {
      console.error('Errore nel salvataggio del punteggio:', err);
      setSnackbarMessage('Errore nel salvataggio del punteggio.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // -----------------------------------------------
  // Condividi link
  // -----------------------------------------------
  const handleShare = () => {
    const shareUrl = window.location.href;
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        setSnackbarMessage('Link copiato negli appunti!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      })
      .catch(() => {
        setSnackbarMessage('Impossibile copiare il link.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  // -----------------------------------------------
  // RENDER
  // -----------------------------------------------
  if (loading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}
      >
        <CircularProgress sx={{ color: '#DF4634' }} />
      </Box>
    );
  }

  if (!gameData?.data?.rearrange || !gameData.data.rearrange.length) {
    return (
      <Box
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}
      >
        <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
          Rearrange non disponibile
        </Typography>
        <Button variant="contained" onClick={handleReturnToGames}>
          Torna ai Giochi
        </Button>
      </Box>
    );
  }

  const rearrangeExercises = gameData.data.rearrange;
  const totalExercises = rearrangeExercises.length;
  const currentExercise = rearrangeExercises[currentExerciseIndex];
  const currentArrangement = userArrangements[currentExercise.id] || {
    pool: [],
    slots: [],
  };
  const { title } = gameData || {};

  return (
    <>
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        backgroundColor="#DF4634"
        textColor="#FFFFFF"
        logoImage="/logo/logobianco.svg"
      />

      {mobileMenuOpen && (
        <MobileMenu
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          setMobileMenuOpen={setMobileMenuOpen}
        />
      )}

      {showFloatingMenu && (
        <FloatingMenu
          isLoggedIn={isLoggedIn}
          handleLogout={() => {
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            setIsLoggedIn(false);
          }}
        />
      )}

      <Box sx={{ minHeight: '100vh', padding: '2rem', backgroundColor: '#F3F6FB' }}>
        <Box
          sx={{
            maxWidth: '800px',
            margin: '0 auto',
            backgroundColor: '#fff',
            padding: '2rem',
            borderRadius: '1rem',
          }}
        >
          {!showSummary ? (
            <>
              <Typography
                variant="h5"
                sx={{ marginBottom: '2rem', textAlign: 'center', fontWeight: 700 }}
              >
                {title || 'Rearrange'}
              </Typography>

              {/* Esercizio Corrente */}
              <RearrangeDragDropCard
                exercise={currentExercise}
                arrangementData={currentArrangement}
                onArrangementChange={handleArrangementChange}
                onWordClick={handleWordClick}
              />

              {/* NAVIGAZIONE */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                {currentExerciseIndex > 0 ? (
                  <Button variant="outlined" onClick={handlePrevExercise}>
                    Indietro
                  </Button>
                ) : (
                  <Box />
                )}

                {currentExerciseIndex < totalExercises - 1 ? (
                  <Button variant="contained" onClick={handleNextExercise}>
                    Avanti
                  </Button>
                ) : (
                  <Button variant="contained" onClick={handleCheckAnswers}>
                    Verifica Risposte
                  </Button>
                )}
              </Box>

              {/* Pulsanti Extra: Condividi + Punteggi Salvati (se owner) */}
              <Box sx={{ textAlign: 'center', marginTop: '2rem', display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                {isOwner && (
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: '#1976d2',
                      color: '#1976d2',
                      borderRadius: '1.875rem',
                      textTransform: 'none',
                      fontWeight: 500,
                      padding: '0.6rem 1.2rem',
                      '&:hover': {
                        borderColor: '#115293',
                        backgroundColor: 'rgba(25, 118, 210, 0.06)',
                      },
                    }}
                    onClick={() => navigate(`/games/${gameId}/scores`)}
                  >
                    Punteggi Salvati
                  </Button>
                )}

                <Button variant="outlined" onClick={handleShare}>
                  Condividi
                </Button>
              </Box>
            </>
          ) : (
            <>
              <RearrangeSummary
                exercises={rearrangeExercises}
                userArrangements={userArrangements}
                onReplay={handleReplay}
                onReturn={handleReturnToGames}
              />

              {/* Salvataggio Score */}
              <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
                <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
                  Inserisci il tuo nome per salvare il punteggio:
                </Typography>
                <TextField
                  value={studentName}
                  onChange={(e) => setStudentName(e.target.value)}
                  label="Nome Studente"
                  variant="outlined"
                  sx={{ marginRight: '1rem' }}
                />
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleSaveScore}
                  sx={{ textTransform: 'none' }}
                >
                  Salva Score
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>

      {/* MENU TAP & SELECT */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        {/* Se l’utente ha cliccato una parola in “pool” => mostrano tutti gli slot */}
        {selectedWordData?.from === 'pool' && (
          <>
            {currentArrangement?.slots?.map((item, slotIndex) => (
              <MenuItem
                key={`menu-slot-${slotIndex}`}
                onClick={() => handleAssignWord('slots', slotIndex)}
              >
                {`Posiziona in Slot #${slotIndex + 1}`}
              </MenuItem>
            ))}
          </>
        )}

        {/* Se l’utente ha cliccato una parola in uno “slot” => spostarla in un altro slot o nel pool */}
        {selectedWordData?.from === 'slots' && (
          <>
            <MenuItem onClick={() => handleAssignWord('pool')}>
              Rimetti nel Pool
            </MenuItem>
            <Box sx={{ borderBottom: '1px solid #ccc', my: 1 }} />
            {currentArrangement?.slots?.map((item, slotIndex) => (
              <MenuItem
                key={`menu-slot-${slotIndex}`}
                onClick={() => handleAssignWord('slots', slotIndex)}
              >
                {`Sposta in Slot #${slotIndex + 1}`}
              </MenuItem>
            ))}
          </>
        )}
      </Menu>

      {/* SNACKBAR */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Footer />
    </>
  );
};

export default RearrangePage;
