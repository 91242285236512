// src/config/gameTypeIds.js

const gameTypeIds = {
    quiz: "ff11e5f1-79b8-4f78-96a1-5f830a269313",
    fillthebox: "56c39fbd-e260-47c6-b964-22901a45eb89",
    selectgroup: "01ff08e6-421b-473d-b5d0-aedc7a093dd9",
    matchpairs: "3ec32afa-f396-48b8-bf2a-2b643c235a1d",
    rearrange: "542fd71b-1dbb-438f-9363-f0b776b4a5b3",
    crossword: "a861e924-5976-4a0b-b3a3-fc6b36e41047",
  };
  
  export default gameTypeIds;


  const localGameTypeIds = {
    quiz: "28a7bba9-c484-46d9-b799-de857e003283",
    fillthebox: "ee7de1c8-40c2-40f2-9dc8-60c6b5c4e24e",
    selectgroup: "d4ce675a-808c-4778-9669-93111a20ba6f",
    matchpairs: "aebd36c9-9f9d-4e57-a441-831da9f7977e",
    rearrange: "44c141dd-ebd8-4b74-b762-2ac57bcd5d9d",
  };